var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "flex-row-center f-wrap" },
        _vm._l(_vm.copywritings, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "copywriting-item",
              class: { active: index === _vm.activeStep },
              on: {
                click: function ($event) {
                  return _vm.selectStep(index)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("editManuscripts.script")) +
                  _vm._s(index + 1) +
                  " "
              ),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "mt10 mb10" }, [
        _vm._v(_vm._s(_vm.$t("editManuscripts.title"))),
      ]),
      _vm.copywritings[_vm.activeStep]
        ? _c("el-input", {
            attrs: {
              maxlength: "50",
              placeholder: _vm.$t("editManuscripts.enterTitle"),
            },
            model: {
              value: _vm.copywritings[_vm.activeStep].title,
              callback: function ($$v) {
                _vm.$set(_vm.copywritings[_vm.activeStep], "title", $$v)
              },
              expression: "copywritings[activeStep].title",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "mt10" }, [
        _vm._v(_vm._s(_vm.$t("editManuscripts.scriptSegmentation"))),
      ]),
      _vm.copywritings[_vm.activeStep]
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.selectLoading,
                  expression: "selectLoading",
                },
              ],
              staticClass: "preview-list",
            },
            _vm._l(
              _vm.copywritings[_vm.activeStep].analysisTextList,
              function (it, position) {
                return _c(
                  "div",
                  { key: position, staticClass: "flex-row-center mt10" },
                  [
                    _c("span", { staticClass: "item-num" }, [
                      _vm._v(" " + _vm._s(position + 1) + " "),
                    ]),
                    _c("el-input", {
                      staticClass: "f-grow",
                      attrs: { maxlength: "200" },
                      model: {
                        value: it.text,
                        callback: function ($$v) {
                          _vm.$set(it, "text", $$v)
                        },
                        expression: "it.text",
                      },
                    }),
                    _vm.typeList.length
                      ? _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("editManuscripts.grouping"),
                            },
                            model: {
                              value: it.materialTypeId,
                              callback: function ($$v) {
                                _vm.$set(it, "materialTypeId", $$v)
                              },
                              expression: "it.materialTypeId",
                            },
                          },
                          _vm._l(_vm.typeList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              },
                              [
                                _c("div", { staticClass: "flexOption" }, [
                                  _c("div", { staticClass: "optionLeft" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  item.id != 0
                                    ? _c(
                                        "div",
                                        { staticClass: "optionRight" },
                                        [
                                          _vm._v(
                                            _vm._s(item.materialCount) +
                                              " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "editManuscripts.material"
                                                )
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm.copywritings[_vm.activeStep].analysisTextList.length > 1
                      ? _c("i", {
                          staticClass: "el-icon-delete",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(_vm.activeStep, position)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "mt10" }, [
        _vm._v(_vm._s(_vm.$t("editManuscripts.addLanguage"))),
      ]),
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            placeholder: _vm.$t("issueItem.translateLanguage"),
            multiple: "",
            filterable: "",
            clearable: "",
            "default-first-option": true,
          },
          model: {
            value: _vm.languageCode,
            callback: function ($$v) {
              _vm.languageCode = $$v
            },
            expression: "languageCode",
          },
        },
        _vm._l(_vm.languageList, function (item) {
          return _c("el-option", {
            key: item.bdCode,
            attrs: { label: item.language, value: item.bdCode },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "flex-center mt10" },
        [
          _c(
            "el-button",
            { staticClass: "button-white", on: { click: _vm.last } },
            [_vm._v(_vm._s(_vm.$t("register.previous")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "gradient-button",
              attrs: { loading: _vm.loading },
              on: { click: _vm.next },
            },
            [_vm._v(_vm._s(_vm.$t("editManuscripts.nextStep")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }