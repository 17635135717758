import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate, isMoreThan50PercentChinese } from "@/utils/common";
import cache from '@/plugins/cache'
import { saveAs } from 'file-saver';
import Cookies from "js-cookie";
import { encodeBase64 } from "@/utils/jsencrypt";

let downloadLoadingInstance;
let networkErrorFlag
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 对应国际化资源文件后缀
// axios.defaults.headers['Content-Language'] = 'zh_CN'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000,
  withCredentials: true
})

// request拦截器
service.interceptors.request.use(config => {
  networkErrorFlag = false;
  if (!navigator.onLine && !networkErrorFlag) {
    Message({ message: '请检查网络连接', type: 'error', duration: 5 * 1000 })
    return Promise.reject('请检查网络连接');
  }
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 动态设置超时时间
  // 需要动态设置的 URL 列表
  const timeoutUrls = ['/admin/material', '/admin/product', 'admin/aiClip/analysisVideo', 'admin/aiWorkshop/tkTransport'];
  if (timeoutUrls.includes(config.url)) {
    config.timeout = 180000; // 重新设置超时时间为 180 秒
  } else {
    config.timeout = 60000; // 默认超时时间 60 秒
  }
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['VerifyToken'] = encodeBase64((new Date()).getTime());
  // let language = Cookies.get('language') || 'zh_CN'
  let language = Cookies.get('language') ? Cookies.get('language') : navigator.language === 'zh-CN' ? 'zh_CN' : 'en_US'
  config.headers['content-language'] = language
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    console.log(sessionObj)
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 2000;                         // 间隔时间(ms)，小于此时间视为重复提交

      // 定义需要排除的 URL 列表
      const excludeUrls = ['/admin/ai/rewrite'];

      if (!excludeUrls.includes(requestObj.url) && s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 401 || code === 403) {
    let loginType = Cookies.get("loginType");

    console.log("loginType", loginType)
    if (loginType == "yizhan_shop") {
      let role = Cookies.get("yizhanRole");
      let hash = location.hash.replace('#', '');
      let path = "/yizhan?role=" + role + "&redirect=" + hash;
      router.go(path);
      Cookies.set("loginType", "");
      // 跳转到一站商城登录
      // window.parent.postMessage({
      //   type: "OPEN",
      //   data: {
      //   url: "/login",
      //   },
      //   }, "*")

    }
    else if (!isRelogin.show) {
      isRelogin.show = true;
      // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
      //   isRelogin.show = false;
      store.dispatch('LogOut').then(() => {
        router.go('/index');
      })
      // }).catch(() => {
      //   isRelogin.show = false;
      // });
    }
    // return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    return Promise.reject()
  } else if (code === 500) {
    // 排除登录相关接口
    // const excludedUrls = ['/auth/login', '/auth/emailLogin', '/auth/smsLogin', '/auth/register',
    //   '/auth/logout', '/auth/forgetPassword', '/resource/sms/code', '/resource/email/code', '/admin/profile',
    //   '/admin/videoTask/createAndGenerateVideoTask', '/admin/videoTask/generateVideoTask']; // 这里可以添加需要排除的接口路径
    // if (excludedUrls.some(url => res.config.url.includes(url))) {
    //   // 如果是排除的接口，不显示统一的错误提示
    //   Message({ message: msg, type: 'error', duration: 5 * 1000 })
    //   return Promise.reject(new Error(msg));
    // }
    if (isMoreThan50PercentChinese(msg)) {
      Message({ message: msg, type: 'error', duration: 5 * 1000 })
    } else {
      Message({ message: '哎呀，服务器遇到了点小麻烦，请稍后再试！', type: 'error', duration: 5 * 1000 })
    }
    return Promise.reject(new Error(msg))
  } else if (code === 601) {
    Message({ message: msg, type: 'warning', duration: 5 * 1000 })
    return Promise.reject('error')
  } else if (code !== 200) {
    Notification.error({ title: msg })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    console.log('err' + error)
    let { message } = error;
    if (!navigator.onLine && !networkErrorFlag) {
      message = "请检查网络连接";
      networkErrorFlag = true;
    } else if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    if (navigator.onLine) {
      networkErrorFlag = false
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)

// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message({ message: errMsg, type: 'error', duration: 5 * 1000 })
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message({ message: '下载文件出现错误，请联系管理员', type: 'error', duration: 5 * 1000 })
    downloadLoadingInstance.close();
  })
}

export default service
