var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "materials-container flex-column",
      class: { "selection-mode": _vm.isSelectionMode != 0 },
    },
    [
      _c("div", { staticClass: "right-panel f-grow" }, [
        _c(
          "div",
          { staticClass: "tabs-container" },
          [
            !_vm.skipInit ||
            _vm.isSelectionMode == 1 ||
            _vm.isSelectionMode == 7
              ? _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.tabClick },
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _vm.isSelectionMode == 0
                      ? _c("el-tab-pane", {
                          attrs: { label: _vm.$t("material.all"), name: "all" },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 ||
                    _vm.isSelectionMode == 1 ||
                    _vm.isSelectionMode == 5 ||
                    _vm.isSelectionMode == 7
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.video"),
                            name: "video",
                          },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 ||
                    _vm.isSelectionMode == 1 ||
                    _vm.isSelectionMode == 3 ||
                    _vm.isSelectionMode == 7
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.picture"),
                            name: "image",
                          },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 || _vm.isSelectionMode == 2
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.audio"),
                            name: "audio",
                          },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 || _vm.isSelectionMode == 4
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.copywriting"),
                            name: "text",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.quota.fileSize
              ? _c(
                  "div",
                  { staticClass: "flex-row-center quota" },
                  [
                    _c("span", { staticClass: "mr5" }, [
                      _vm._v(_vm._s(_vm.$t("quota.quota")) + "："),
                    ]),
                    _c("Slider", {
                      attrs: {
                        value: _vm.formatQuota(_vm.quota.useFileSize) || 0,
                        maxValue: _vm.formatQuota(_vm.quota.fileSize) || "10G",
                      },
                    }),
                    _c("div", { staticClass: "ml5" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.quota.useFileSize || 0) +
                          "/" +
                          _vm._s(_vm.quota.fileSize || "10G") +
                          " "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "flex-row-center f-between w-100" }, [
            _c(
              "div",
              { staticClass: "f-shrink" },
              [
                _vm.isSelectionMode == 0 &&
                _vm.activeTab == "text" &&
                _vm.checkCommon()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "gradient-button",
                        attrs: { icon: "el-icon-upload2" },
                        on: { click: _vm.addText },
                      },
                      [_vm._v(_vm._s(_vm.$t("copywriting.new")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode == 0 &&
                (_vm.activeTab == "all" ||
                  _vm.activeTab == "video" ||
                  _vm.activeTab == "image" ||
                  _vm.activeTab == "audio") &&
                _vm.checkCommon()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "gradient-button",
                        attrs: { icon: "el-icon-upload2" },
                        on: { click: _vm.addMaterial },
                      },
                      [_vm._v(_vm._s(_vm.$t("file.upload")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode == 0 &&
                _vm.currentLevel < 2 &&
                _vm.checkCommon()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "normal-button",
                        attrs: { icon: "el-icon-folder-add" },
                        on: { click: _vm.openAddDialog },
                      },
                      [_vm._v(_vm._s(_vm.$t("file.createFolder")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode == 0 && _vm.ids.length > 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "delete-button",
                        attrs: { icon: "iconfont icon-delete" },
                        on: { click: _vm.deleteMultiple },
                      },
                      [_vm._v(_vm._s(_vm.$t("remove")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode != 0
                  ? _c(
                      "div",
                      [
                        _vm.isTextToVideo
                          ? _c(
                              "el-button",
                              {
                                staticClass: "gradient-button",
                                on: { click: _vm.confirmSelection },
                              },
                              [_vm._v(_vm._s(_vm.$t("confirmSelection")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-row-center" },
              [
                _vm.isSelectionMode == 0
                  ? _c(
                      "el-radio-group",
                      {
                        staticClass: "custom-radio-group",
                        model: {
                          value: _vm.styleType,
                          callback: function ($$v) {
                            _vm.styleType = $$v
                          },
                          expression: "styleType",
                        },
                      },
                      [
                        _c("el-radio", {
                          attrs: {
                            label: "grid",
                            border: _vm.styleType === "grid",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "iconfont font20 icon-grid",
                                      class:
                                        _vm.styleType === "grid"
                                          ? "gradient-purple"
                                          : "unbind",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2790080755
                          ),
                        }),
                        _c("el-radio", {
                          attrs: {
                            label: "list",
                            border: _vm.styleType === "list",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "iconfont font20 icon-list",
                                      class:
                                        _vm.styleType === "list"
                                          ? "gradient-purple"
                                          : "unbind",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3722370355
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    style: {
                      margin: "0 10px",
                      width: _vm.isTextToVideo ? "200px" : "400px",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "account-input",
                        attrs: {
                          placeholder:
                            _vm.activeTab == "text"
                              ? _vm.$t("copywriting.tipName")
                              : _vm.$t("material.tipName"),
                          clearable: "",
                        },
                        on: { change: _vm.searchValueChange },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          },
                        },
                        model: {
                          value: _vm.queryParams.searchValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "searchValue", $$v)
                          },
                          expression: "queryParams.searchValue",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-input__icon iconfont icon-sousuotubiao",
                          attrs: { slot: "prefix" },
                          on: { click: _vm.handleQuery },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("refresh"),
                      placement: "top",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        size: "mini",
                        circle: "",
                        icon: "el-icon-refresh",
                      },
                      on: { click: _vm.refresh },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingOther,
                  expression: "loadingOther",
                },
              ],
            },
            [
              _c("div", { staticClass: "file-system" }, [
                _vm.currentPath.length > 0 || _vm.isSearchResult
                  ? _c(
                      "div",
                      { staticClass: "flex-row-center fileSpace" },
                      [
                        _c("div", { staticClass: "breadcrumb" }, [
                          !_vm.isSearchResult
                            ? _c("span", { on: { click: _vm.goLast } }, [
                                _vm._v("← " + _vm._s(_vm.$t("file.goBack"))),
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            { staticClass: "ml10", on: { click: _vm.goBack } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("file.goBackMaterial")) + " /"
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "breadcrumb",
                            staticStyle: { "margin-left": "5px" },
                          },
                          _vm._l(
                            _vm.currentPathFolders,
                            function (folder, index) {
                              return _c(
                                "span",
                                {
                                  key: folder.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.navigateTo(index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(folder.name) + " "),
                                  index < _vm.currentPath.length - 1
                                    ? _c("span", [_vm._v(" / ")])
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm.isSearchResult
                          ? _c("span", { staticClass: "ml10" }, [
                              _vm._v(_vm._s(_vm.$t("file.searchResult"))),
                            ])
                          : _vm._e(),
                        _vm.isSearchResult
                          ? _c(
                              "el-button",
                              {
                                staticClass: "delete-button ml20",
                                attrs: { icon: "iconfont icon-guanbi1" },
                                on: { click: _vm.closeSearch },
                              },
                              [_vm._v(_vm._s(_vm.$t("file.closeSearch")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.styleType === "grid" && !_vm.isSearchResult
                  ? _c(
                      "div",
                      { staticClass: "flex-row f-wrap folders" },
                      _vm._l(_vm.filterFile, function (folder) {
                        return _c("folder", {
                          key: folder.id,
                          staticClass: "video-parent",
                          attrs: { folder: folder },
                          on: {
                            "open-folder": _vm.openFolder,
                            "edit-folder": _vm.openEditDialog,
                            "delete-folder": _vm.deleteMaterialType,
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.styleType === "grid"
                ? _c(
                    "div",
                    { staticClass: "flex-row f-wrap" },
                    _vm._l(_vm.materialList, function (video, index) {
                      return _c("video-grid-item", {
                        key: index.id,
                        ref: "videoItem-" + video.id,
                        refInFor: true,
                        staticClass: "video-parent",
                        attrs: {
                          video: video,
                          isSelectionMode: _vm.isSelectionMode != 0,
                          "current-audio-id": _vm.currentAudioId,
                        },
                        on: {
                          "play-audio": _vm.handlePlayAudio,
                          "delete-video": _vm.deleteVideo,
                          "edit-video": _vm.editVideo,
                          "view-text": _vm.viewText,
                          "edit-text": _vm.editText,
                          "toggle-selection": _vm.handleToggleSelection,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.styleType === "list"
                ? _c(
                    "div",
                    { staticClass: "w-100 mt10" },
                    [
                      _c("video-list-table", {
                        attrs: {
                          videoList: _vm.mergedList,
                          isSelectionMode: _vm.isSelectionMode != 0,
                        },
                        on: {
                          "open-folder": _vm.openFolder,
                          "edit-folder": _vm.openEditDialog,
                          "delete-folder": _vm.deleteMaterialType,
                          "delete-video": _vm.deleteVideo,
                          "view-text": _vm.viewText,
                          "edit-video": _vm.editVideo,
                          "edit-text": _vm.editText,
                          "toggle-selection": _vm.handleToggleSelection,
                          "select-multiple": _vm.handleSelectMultiple,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.materialList.length == 0
                ? _c("el-empty", { attrs: { "image-size": 200 } })
                : _vm._e(),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                  pageSizes: [12, 24, 36, 48, 60],
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getMaterialList,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.isSelectionMode != 0 && !_vm.isTextToVideo
        ? _c(
            "div",
            {
              staticClass: "f-shrik flex-center mt10",
              staticStyle: { background: "white" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-white",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "gradient-button",
                  on: { click: _vm.confirmSelection },
                },
                [_vm._v(_vm._s(_vm.$t("confirmSelection")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("addFolderDialog", {
        attrs: {
          visible: _vm.editDialogVisible,
          title: _vm.editDialogTitle,
          initialValue: _vm.initialInput,
          initialLabel: _vm.initialLabel,
          labelList: _vm.labelList,
          isSystem: _vm.isSystem,
          treeFileList: _vm.list,
          materialTypeId: _vm.initialParentId,
          editMaterialId: _vm.editMaterialId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.editDialogVisible = $event
          },
          submitAddFile: _vm.handleConfirm,
        },
      }),
      _c("MaterialUploadDialog", {
        ref: "materialUploadDialog",
        attrs: {
          visible: _vm.uploadDialogVisible,
          title: _vm.uploadTitle,
          list: _vm.list,
          labelList: _vm.labelList,
          materialTypeId: _vm.currentTypeId,
          isSystem: _vm.isSystemFile,
          activeTab: _vm.activeTab,
        },
        on: {
          "update:visible": function ($event) {
            _vm.uploadDialogVisible = $event
          },
          closeUpload: _vm.closeUpload,
          addMaterialSuccess: _vm.addMaterialSuccess,
          editMaterialSuccess: _vm.editMaterialSuccess,
          updateFile: _vm.updateFile,
        },
      }),
      _c("add-text-dialog", {
        ref: "textDialog",
        attrs: {
          visible: _vm.copywritingVisible,
          title: _vm.copywritingTitle,
          isSystemFile: _vm.isSystemFile,
          "label-list": _vm.labelList,
          materialTypeList: _vm.list,
          materialTypeId: _vm.currentTypeId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.copywritingVisible = $event
          },
          submit: _vm.getMaterialList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }