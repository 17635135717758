var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "header-row-class-name": "custom_table_header",
            "max-height": "500",
            data: _vm.list,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              align: "center",
              selectable: _vm.checkSelectable,
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("file.name"), prop: "url", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex-row-center" }, [
                      scope.row.documentType == "folder"
                        ? _c("div", [
                            scope.row.type === "system"
                              ? _c("img", {
                                  staticClass: "img-forder-list",
                                  attrs: {
                                    src: require("../../assets/images/folder-system.png"),
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "img-forder-list",
                                  attrs: {
                                    src: require("../../assets/images/folder-user.png"),
                                    alt: "",
                                  },
                                }),
                          ])
                        : _c("div", [
                            scope.row.fileType == "image"
                              ? _c("div", [
                                  _c("img", {
                                    staticClass: "video-cover",
                                    attrs: { src: scope.row.url, alt: "image" },
                                  }),
                                ])
                              : scope.row.fileType == "text"
                              ? _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/video/text.png"),
                                      alt: "",
                                      width: "30px",
                                      height: "30px",
                                    },
                                  }),
                                ])
                              : scope.row.fileType == "audio"
                              ? _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/video/audio.png"),
                                      alt: "",
                                      width: "30px",
                                      height: "30px",
                                    },
                                  }),
                                ])
                              : _c("div", [
                                  _c("video", {
                                    staticClass: "video-cover",
                                    attrs: { src: scope.row.url, alt: "video" },
                                  }),
                                ]),
                          ]),
                      _c(
                        "span",
                        {
                          staticClass: "single-line-ellipsis ml5 material-name",
                          on: {
                            click: function ($event) {
                              return _vm.nameClick(scope.row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.fileType == "text"
                                  ? scope.row.title
                                  : scope.row.name
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("label.label"),
              align: "center",
              prop: "labels",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.labels, function (tag, index) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        staticStyle: {
                          "margin-right": "5px",
                          "margin-bottom": "5px",
                        },
                      },
                      [_vm._v(" " + _vm._s(tag) + " ")]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("file.format"),
              align: "center",
              prop: "suffix",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("time.createTime"),
              align: "center",
              prop: "createTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("file.fileSize"),
              align: "center",
              prop: "fileSize",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.fileSize
                            ? _vm.formatFileSize(scope.row.fileSize)
                            : "--"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operate"),
              align: "center",
              "class-name": "small-padding fixed-width",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.documentType == "folder"
                      ? _c(
                          "div",
                          [
                            scope.row.type !== "system"
                              ? _c(
                                  "el-tooltip",
                                  { attrs: { content: _vm.$t("edit") } },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "edit-folder",
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.type !== "system"
                              ? _c(
                                  "el-tooltip",
                                  { attrs: { content: _vm.$t("remove") } },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        icon: "el-icon-delete",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "delete-folder",
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", [
                          scope.row.fileType == "text"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-tooltip",
                                    { attrs: { content: _vm.$t("view") } },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "iconfont icon-chakan",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit(
                                              "view-text",
                                              scope.row
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  scope.row.type !== "system"
                                    ? _c(
                                        "el-tooltip",
                                        { attrs: { content: _vm.$t("edit") } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-edit",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "edit-text",
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  scope.row.type !== "system"
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: { content: _vm.$t("remove") },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-delete",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "delete-video",
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _vm.type == 3 &&
                                  scope.row.publishSuccessCount == 0
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: _vm.$t(
                                              "videoManager.publish"
                                            ),
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-position",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.publish(scope.row)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isFailed(scope.row) &&
                                  _vm.splitStatus(scope.row) ===
                                    _vm.$t("videoItem.clipSuccess")
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: _vm.$t(
                                              "videoItem.clipDetails"
                                            ),
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-more",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.splitDetail(
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isFailed(scope.row)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "ml10 mr10",
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _c("video-button", {
                                            attrs: {
                                              type: 2,
                                              video: scope.row,
                                              tip: _vm.$t("file.filePreview"),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isFailed(scope.row)
                                    ? _c(
                                        "el-tooltip",
                                        { attrs: { content: _vm.$t("edit") } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-edit",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "edit-video",
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isFailed(scope.row) &&
                                  scope.row.type !== "system"
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: _vm.$t("file.download"),
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-download",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadFile(
                                                  scope.row.url
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isFailed(scope.row) &&
                                  scope.row.type !== "system"
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: _vm.$t("copyLink"),
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-link",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyLink(scope.row)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  scope.row.type !== "system"
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: { content: _vm.$t("remove") },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-delete",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "delete-video",
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }