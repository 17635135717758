var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h4", { staticClass: "form-header h4" }, [_vm._v("基本信息")]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8, offset: 2 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户昵称", prop: "nickName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", disabled: "" },
                        model: {
                          value: _vm.form.nickName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nickName", $$v)
                          },
                          expression: "form.nickName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 2 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录账号", prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", disabled: "" },
                        model: {
                          value: _vm.form.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userName", $$v)
                          },
                          expression: "form.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h4", { staticClass: "form-header h4" }, [_vm._v("角色信息")]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            "row-key": _vm.getRowKey,
            data: _vm.roles.slice(
              (_vm.pageNum - 1) * _vm.pageSize,
              _vm.pageNum * _vm.pageSize
            ),
          },
          on: {
            "row-click": _vm.clickRow,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.pageNum - 1) * _vm.pageSize + scope.$index + 1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              type: "selection",
              "reserve-selection": true,
              width: "55",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "角色编号", align: "center", prop: "roleId" },
          }),
          _c("el-table-column", {
            attrs: { label: "角色名称", align: "center", prop: "roleName" },
          }),
          _c("el-table-column", {
            attrs: { label: "权限字符", align: "center", prop: "roleKey" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("time.createTime"),
              align: "center",
              prop: "createTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.pageNum, limit: _vm.pageSize },
        on: {
          "update:page": function ($event) {
            _vm.pageNum = $event
          },
          "update:limit": function ($event) {
            _vm.pageSize = $event
          },
        },
      }),
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            {
              staticStyle: {
                "text-align": "center",
                "margin-left": "-120px",
                "margin-top": "30px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("back")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }