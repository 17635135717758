<!-- AI混剪 -->
<template>
  <div >
    <div v-show="currentTab == 0" class="aicross-container">
      <router-view></router-view>
      <div class="flex-row-center f-end" style="margin-bottom: 15px;">
        <el-popover
          placement="left-start"
          width="518"
          trigger="click"
        >
          <div class="popover-title">{{ $t('guidance.txtToVidWelcome') }}</div>
          <div class="popover-content">{{ $t('guidance.txtToVidInstructions') }} </div>
          <video controls :src="videoSrc" class="video-player" ref="videoPlayer"></video>
          <div slot="reference" class="title-header-text pointer">
            <i class="iconfont icon-dacha2" />
            <span class="font12 fontweight400 ml5">{{ $t('guidance.guide') }}</span>
          </div>
        </el-popover>
      </div>
      <div class="aicross-content">
        <!-- Tab Navigation -->
        <!-- <el-tabs v-model="activeTab">
          <el-tab-pane :label="$t('aiCrosscutting.templateGeneration')" name="template"></el-tab-pane>
          <el-tab-pane :label="$t('aiCrosscutting.customGeneration')" name="custom"></el-tab-pane>
          <el-tab-pane :label="$t('aiCrosscutting.customScript')" name="manual"></el-tab-pane>
        </el-tabs> -->

        <!-- Template Generation -->

        <div class="generation-container">
           <el-row style="width: 100%;">
            <el-col :span="12">
              <div class="left-section" >
                <div class="cut-scroll">
                  <div>
                    <div class="flex-row-center f-between">
                      <span class="font16">
                        <img src="../../assets/images/aiCutting/copywriting.png" alt="" class="img-title">
                        {{$t('aiCrosscutting.scriptCopy')}}
                      </span>
                      <span class="font12 mr10 pointer" @click="reset">
                        <i class="iconfont icon-shuaxin"></i>
                        {{$t('aiCrosscutting.resetContent')}}
                      </span>
                    </div>
                    <div v-if="activeTab === 'template'">
                      <TemplateItemEdit
                        v-if="currentTemplate && currentTemplate.title"
                        :template="currentTemplate"
                        @change-Template="changeTemplate"
                        @update-template="updateTemplate"
                      />
                      <div v-else class="template-selector" v-loading="templateLoading">
                        <div v-for="template in templates.slice(0, 4)" :key="template.id" class="template-card-item">
                          <TemplateItem
                            :template="template"
                            @view-template="viewTemplate"
                            @apply-template="applyTemplate"
                          />
                        </div>
                        <div class="flex-center w-100">
                          <el-button type="primary" plain  @click="moreTemplate">{{$t('aiCrosscutting.viewMore')}}</el-button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="cut-title mt10 mb5">{{$t('aiCrosscutting.inputPrompt')}}：</div>
                      <div class="f-grow" style="padding: 10px 10px 0;">
                        <el-input type="textarea" maxlength="200" show-word-limit v-model="form.text" :rows="8"  :placeholder="$t('aiCrosscutting.keywordPrompt')"/>
                      </div>
                    </div>
                    <div class="flex-row-center f-end mt10 mr10">
                      <div class="button-normal" @click="moreTemplate">
                        <i class="iconfont icon-wenanmoban font14"></i>
                        {{$t('aiCrosscutting.selectTemp')}}
                      </div>
                      <div class="button-normal ml10" @click="importText">
                        <i class="iconfont icon-sucaiku1 font14"></i>
                        {{$t('aiCrosscutting.selectMaterial')}}
                      </div>
                    </div>
                  </div>
                  <!-- Additional Options -->
                  <div class="flex-column">
                    <div class="cut-title mt10 mb5">{{$t('aiCrosscutting.contentLength')}}：</div>
                    <el-radio-group v-model="form.wordsSize" class="mt5">
                      <el-radio :label="50">{{$t('aiCrosscutting.short')}}(0～50)</el-radio>
                      <el-radio :label="100">{{$t('aiCrosscutting.medium')}}(50～100)</el-radio>
                      <el-radio :label="200">{{$t('aiCrosscutting.long')}}(100～200)</el-radio>
                    </el-radio-group>
                    <div class="cut-title mt10 mb5">{{$t('aiCrosscutting.generationCount')}}：<span style="font-size: 11px;margin-left: 10px">{{$t('aiCrosscutting.maxGeneration')}}</span></div>
                    <el-input-number v-model="form.generateCount" :min="1" :max="5" class="mt5"/>
                    <div class="cut-title mt10 mb5">{{$t('aiCrosscutting.seoIntegration')}}：</div>
                    <el-select v-model="form.seo" filterable multiple remote clearable default-first-option :placeholder="$t('tip.select')" class="mt5" >
                      <el-option
                        v-for="dict in seoList"
                        :key="dict.id"
                        :label="dict.label"
                        :value="dict.label"
                      />
                    </el-select>
                    <div class="cut-title mt10 mb5">{{$t('aiCrosscutting.golden3sStart')}}：</div>
                    <div class="golden-3s">
                      <el-radio-group v-model="form.startStyle" class="mt5">
                        <el-radio-button
                          v-for="type in goldenTypes" :key="type"
                          :label="type"
                          border
                          class="content-length-item"
                        >
                          {{type}}
                        </el-radio-button>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
                <div class="flex-center mt10">
                  <el-button :loading="contentLoading" class="gradient-button" @click="generate">{{$t('aiCrosscutting.generateContent')}}</el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="right-section">
                <div class="cut-scroll">
                  <div>
                    <span class="font16">
                      <img src="../../assets/images/aiCutting/preview.png" alt="" class="img-title">
                      {{$t('aiCrosscutting.scriptPreview')}}
                    </span>
                    <span class="font14 color-gray6 ml10">
                      <i class="iconfont icon-zhushi font16" style="color: #626364;"></i>
                      {{$t('aiCrosscutting.parseHint')}}
                    </span>
                  </div>
                  <div class="preview-list" v-loading="contentLoading">
                    <div v-for="(item, index) in generatedContents" :key="index" class="preview-item">
                      <div class="flex-row-center">
                        <el-checkbox v-model="item.checked" @change="checkChange"></el-checkbox>
                        <div class="ml5">
                          <p class="resutl-title">{{ item.text }}</p>
                          <h4 class="resutl-title" v-if="item && item.title">{{ item.title }}</h4>
                        </div>
                      </div>
                      <div class="actions ml20">
                        <el-button :loading="item.loading && rewriteType === 'bt'" type="mini" :disabled="item.loading" @click="rewrite('bt', index, item.text)">
                          {{$t('aiCrosscutting.titleExtraction')}}
                        </el-button>
                        <el-button :loading="item.loading && rewriteType === 'kx'" type="mini" :disabled="item.loading" @click="rewrite('kx', index, item.text)">
                          {{$t('aiCrosscutting.scriptSupplement')}}
                        </el-button>
                        <el-button :loading="item.loading && rewriteType === 'rs'" type="mini" :disabled="item.loading" @click="rewrite('rs', index, item.text)">
                          {{$t('aiCrosscutting.scriptPolish')}}
                        </el-button>
                        <el-button :loading="item.loading && rewriteType === 'sx'" type="mini" :disabled="item.loading" @click="rewrite('sx', index, item.text)">
                          {{$t('aiCrosscutting.scriptSimplify')}}
                        </el-button>
                        <!-- <el-button :loading="rewriteType=='bt'" type="mini" :disabled="rewriteType && rewriteType !== 'bt'" @click="rewrite('bt', index, item.text)">{{$t('aiCrosscutting.titleExtraction')}}</el-button>
                        <el-button :loading="rewriteType=='kx'" type="mini" :disabled="rewriteType && rewriteType !== 'kx'" @click="rewrite('kx', index, item.text)">{{$t('aiCrosscutting.scriptSupplement')}}</el-button>
                        <el-button :loading="rewriteType=='rs'" type="mini" :disabled="rewriteType && rewriteType !== 'rs'" @click="rewrite('rs', index, item.text)">{{$t('aiCrosscutting.scriptPolish')}}</el-button>
                        <el-button :loading="rewriteType=='sx'" type="mini" :disabled="rewriteType && rewriteType !== 'sx'" @click="rewrite('sx', index, item.text)">{{$t('aiCrosscutting.scriptSimplify')}}</el-button> -->
                      </div>
                    </div>
                    <el-empty
                      :image-size="128"
                      :image="require('@/assets/images/aiCutting/blank.png')"
                      v-if="generatedContents.length === 0">
                      <span slot="description">
                        <span class="font14 color-gray9">
                          {{$t('aiCrosscutting.keywordInputHint')}}
                        </span>
                        <br>
                        <span class="font12 color-gray">
                          {{$t('aiCrosscutting.uniqueContent')}}
                        </span>
                      </span>
                    </el-empty>
                  </div>
                </div>
                <div class="flex-center mt10" v-if="generatedContents.length > 0">
                  <el-button :loading="parseLoading" class="gradient-button" @click="parseAll">{{$t('aiCrosscutting.analyze')}}</el-button>
                </div>
              </div>
            </el-col>
           </el-row>
        </div>

        <!-- Manual Content Input -->
        <!-- <div v-if="activeTab === 'manual'">
          <div class="flex-row-center f-wrap">
            <div
              v-for="(item, index) in copywritings"
              :key="index"
              class="copywriting-item"
              :class="{ active: index === activeStep }"
              @click="selectStep(index)"
            >{{$t('aiCrosscutting.script')}}{{ index + 1 }}</div>
            <el-button type="primary" size="medium" plain @click="addCopywriting" v-if="copywritings.length < 10">+ {{$t('aiCrosscutting.addScript')}}</el-button>
          </div>
          <div class="cut-title mt10 mb10">{{$t('aiCrosscutting.title')}}</div>
          <el-input v-model="copywritings[activeStep].title" :placeholder="$t('aiCrosscutting.enterTitle')" />
          <div class="cut-title mt10 mb10"><span style="color: red;">*</span>{{$t('aiCrosscutting.scriptContent')}}</div>
          <el-input type="textarea" v-model="copywritings[activeStep].text" :rows="8" :placeholder="$t('aiCrosscutting.enterScriptContent')" />
          <div class="flex-center mt10" >
            <el-button :loading="titleLoading" @click="imitateContent(activeStep)">{{$t('aiCrosscutting.contentRewrite')}}</el-button>
            <el-button @click="deleteCopywriting(activeStep)" v-if="copywritings.length > 1">{{$t('aiCrosscutting.deleteScript')}}</el-button>
            <el-button type="primary" :loading="parseLoading" @click="parseManual">{{$t('aiCrosscutting.analyze')}}</el-button>
          </div>
        </div> -->

        <templateItemDetail
          :visible.sync="detailVisible"
          :template="templateDetail"
          @confirm="applyTemplate"
        />
        <TemplateMore
          :visible.sync="moreVisible"
          @view-template="viewTemplate"
          @apply-template="applyTemplate"
        />
        <material-drawer
          ref="materialDrawer"
          :visible.sync="drawerMaterial"
          @selectionConfirmed="selectionText"
        />
      </div>
    </div>
    <div v-show="currentTab == 1">
      <EditManuscripts
        ref="editManuscripts"
        @last="last(0)"
        @next="next"
      />
    </div>
    <div v-show="currentTab == 2">
      <EditVideo
        ref="editVideo"
        @last="last(1)"
      />
    </div>
  </div>
</template>

<script>
import { rewrite } from '../../api/ai/language';
import { generateParagraph, generateText } from '../../api/aiClip/aiClip';
import { listTemplate } from '../../api/aiClip/aiTemplate';
import { listLabel } from '../../api/system/label';
import TemplateItem from './templateItem.vue';
import TemplateItemDetail from './templateItemDetail.vue';
import TemplateItemEdit from './templateItemEdit.vue';
import TemplateMore from './templateMore.vue';
import EditManuscripts from './editManuscripts.vue';
import EditVideo from './editVideo.vue';
import MaterialDrawer from '@/components/MaterialDrawer/index.vue';
export default {
  name: 'AiCrosscutting',
  components: {
    TemplateItem,
    TemplateItemDetail,
    TemplateItemEdit,
    TemplateMore,
    EditManuscripts,
    EditVideo,
    MaterialDrawer
  },
  data() {
    return {
      currentTab: '0',//整体的三步：0-AI混剪，1-编辑文案，2-编辑视频
      activeTab: 'custom',
      templates: [],
      templateLoading: false,
      goldenTypes: [
        this.$t('goldenTypes.curiosity'),
        this.$t('goldenTypes.leverage'),
        this.$t('goldenTypes.painPoint'),
        this.$t('goldenTypes.extreme'),
        this.$t('goldenTypes.fear'),
        this.$t('goldenTypes.contrast'),
        this.$t('goldenTypes.benefitTransfer'),
        this.$t('goldenTypes.empathy')
      ],
      generatedContents: [],//生成的文案
      manualTitle: '',
      manualText: '',
      detailVisible: false, // 控制模板详情的显示与隐藏
      templateDetail: {}, // 模板详情数据
      currentTemplate: null, // 当前选中的模板
      moreVisible:false,//查看更多
      form: {
        aiTemplate:null,
        text: '',
        wordsSize:100,
        generateCount: 1,
        seo: [],
        startStyle: '',
      },
      seoList:[],
      contentLoading:false,//生成内容的loading
      rewriteType: undefined,//重写类型
      titleLoading: false,//生成标题的loading
      parseLoading: false,//解析的loading
      copywritings:[{
        title:'',
        text:''
      }],//自己输入的文案
      activeStep:0,//当前选中的文案index
      drawerMaterial:false,//文案素材库
      videoSrc: 'https://pub.grds.cn/web_video/%E6%96%87%E7%94%9F%E8%A7%86%E9%A2%91_%E6%93%8D%E4%BD%9C%E5%BC%95%E5%AF%BC.mp4',
      isFaddish: false,//是否从爆款进入
    };
  },
  created(){
    this.isFaddish = this.$route.query.isFaddish || false;
    if (this.isFaddish) {
      this.faddishTurn()
    }
    this.getTemplateList('')
    this.getLabelList()
  },
  methods: {
    //导入文案素材
    importText(){
      this.drawerMaterial = true
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(4)
      });
    },
    selectionText(items){
      if(items.length==1){
        this.form.text = items[0].content
        this.activeTab = 'custom'
      }
      this.drawerMaterial = false
    },
    checkChange(){
      console.log('this.generatedContents',this.generatedContents)
    },
    getTemplateList(type){
      this.templateLoading = true
      listTemplate({
        type: type
      }).then(response => {
        this.templates = response.data
      }).finally(() => {
        this.templateLoading = false
      })
    },
    getLabelList() {
      listLabel(this.queryParams).then(response => {
        this.seoList = response.rows;
      });
    },
    // 查看模板
    viewTemplate(item){
      this.templateDetail = item
      this.detailVisible = true
    },
    //更多模板
    moreTemplate(){
      this.moreVisible = true
    },
    // 应用模板
    applyTemplate(item) {
      this.currentTemplate = item
      this.activeTab = 'template'
      console.log('this.currentTemplate',this.currentTemplate)
    },
    updateTemplate(temp){
      this.currentTemplate = temp
    },
    changeTemplate(){
      this.currentTemplate = null
    },
    // 生成内容
    generate() {
      console.log('this.currentTemplate',this.currentTemplate)
      if(this.activeTab === 'template'){
        if(!this.currentTemplate || !this.currentTemplate.details|| this.currentTemplate.details.length === 0){
          this.$modal.msgError(this.$t('aiCrosscutting.selectTemplate'))
          return
        }
        this.form.aiTemplate = this.currentTemplate
        this.form.text = ''
      }else if(this.activeTab === 'custom'){
        if(!this.form.text){
          this.$modal.msgError(this.$t('aiCrosscutting.enterKeyword'))
          return
        }
        this.form.aiTemplate = null
      }
      if(!this.form.seo){
        this.form.seo = null
      }
      this.contentLoading = true
      generateText(this.form).then(response => {
        if(response.data.error){
          this.$modal.alertError(response.data.error)
          return
        }
        let list = response.data.list
        if(list && list.length > 0){
          list.forEach(item => {
            this.generatedContents.unshift({
              text: item,
              title: "",
              checked: false,
              loading: false,
            });
          });
        }
      }).finally(() => {
        this.contentLoading = false
      });
    },
    reset() {
      // 重置逻辑
      this.currentTemplate = null
      this.activeTab = 'custom'
      this.form = {
        aiTemplate:null,
        text: '',
        wordsSize:100,
        generateCount: 1,
        seo: [],
        startStyle: '',
      }
    },
     //重写
     rewrite(type, index, content) {
      if (!content) {
        this.$modal.msgError(this.$t('aiCrosscutting.enterScriptFirst'));
        return;
      }
      this.rewriteType = type
      // 启用该项目整体的 loading 状态
      this.$set(this.generatedContents[index], 'loading', true);
      rewrite({
        content: content,
        type: type
      }).then(res => {
        if(type === 'bt'){
          this.generatedContents[index].title = res.data
        }else{
          this.generatedContents[index].text = res.data
        }
      }).finally(() => {
        // 启用该项目整体的 loading 状态
        this.$set(this.generatedContents[index], 'loading', false);
        this.rewriteType = undefined
      });
    },
    parseAll() {
      // 解析逻辑
      if(!this.generatedContents||this.generatedContents.length === 0){
        this.$modal.msgError(this.$t('aiCrosscutting.generateScriptFirst'))
        return
      }
      const selectedItems = this.generatedContents.filter(item => item.checked);

      if (selectedItems.length === 0) {
        this.$modal.msgError(this.$t('aiCrosscutting.selectAtLeastOneScript'));
        return;
      }
      if (selectedItems.length > 5) {
        this.$modal.msgError(this.$t('aiCrosscutting.selectUpTo5Script'));
        return;
      }
      this.requestNext(selectedItems)

    },

    faddishTurn(){
      this.currentTab = 1
      this.$nextTick().then((rs) => {
        this.$refs.editManuscripts.init(sessionStorage.getItem('editManuscriptsData'))
      });

    },
    requestNext(list){
      this.parseLoading = true
      generateParagraph({
        textList: list
      }).then(response => {
        console.log('response.data',response.data);
        sessionStorage.setItem('editManuscriptsData', JSON.stringify(response.data));
        // this.$router.push({ path: '/editManuscripts' });
        this.currentTab = 1
        this.$nextTick().then((rs) => {
          this.$refs.editManuscripts.init(JSON.stringify(response.data))
        });
        // this.$router.push({
        //   name: 'editManuscripts',
        //   params: {
        //     data: response.data
        //   }
        // });
      }).finally(() => {
        this.parseLoading = false
      });
    },
    last(index){
      if(this.isFaddish && index === 0){
        this.$tab.closeOpenPage({ path: "/faddish" });
        return
      }
      this.currentTab = index
    },
    next(data, languageCode){
      this.currentTab = 2
      this.$nextTick().then((rs) => {
        this.$refs.editVideo.init(data, languageCode)
      });
    },
    // 添加文案
    addCopywriting() {
      this.copywritings.push({
        text: '',
        title: '',
      });
    },
    selectStep(index){
      this.activeStep = index
    },
    // 删除文案
    deleteCopywriting(index) {
      this.copywritings.splice(index, 1);
      if(index > 1){
        this.activeStep = index - 1
      } else {
        this.activeStep = 0
      }
      console.log('this.copywritings',this.copywritings.length)
    },
    imitateContent(index) {
      // 内容仿写逻辑
      if (!this.copywritings[index].text) {
        this.$modal.msgError(this.$t('aiCrosscutting.enterScriptFirst'));
        return;
      }
      this.titleLoading = true
      rewrite({
        content: this.copywritings[index].text,
        type: 'rs'
      }).then(res => {
        this.copywritings[index].text = res.data
      }).finally(() => {
        this.titleLoading = false;
      });
    },
    parseManual() {
      let hasEmptyText = this.copywritings.some(item => !item.text);
      if (hasEmptyText) {
        this.$modal.msgError(this.$t('aiCrosscutting.enterScriptContent'));
        return;
      }
      this.requestNext(this.copywritings)
    }
  }
};
</script>

<style lang="scss" scoped>
.aicross-container{
  margin: 15px;
}
.aicross-content{
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid rgba(229,231,235,1);
  border-radius: 14px;
}
.popover-title{
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0,0,0,0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.popover-content{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-top: 8px;
}
.video-player {
  max-height:210px;
}
.el-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: #fff !important;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.generation-container {
  width: 100%;
  display: flex;
}
.left-section, .right-section {
  padding: 5px;
}
.template-selector {
  display: flex;
  flex-wrap: wrap;
}

.steps {
  display: flex;
  flex-wrap: wrap;
}
.golden-3s {
  margin: 10px 0;
}
.golden-type {
  display: inline-block;
  margin-right: 10px;
}
.preview-list {
  list-style: none;
  padding: 0;
}
.preview-item {
  margin-top: 10px;
  padding: 10px;
  /* border: 1px solid #ddd; */
  box-sizing: border-box;
}
.template-card-item{
  width: 48%;
  margin: 1%;
}
.cut-title{
  /* padding-left: 10px; */
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.content-length-item .el-radio {
  margin: 5px 10px 0 0;
}
.cut-scroll {
  overflow-y: auto;
  height: calc(100vh - 260px);
  scroll-padding-bottom: 20px;
}
.resutl-title{
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.copywriting-item {
  padding: 8px 12px;
  margin: 5px 10px 5px 0;
  font-size: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 5px;
  color:gray;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.copywriting-item.active {
  border-color: #016FEE; /* 选中步骤的边框颜色 */
  color:#016FEE;
}
.img-title {
  width: 24px;
  height: 24px;
}
.button-normal{
  height: 24px;
  background: #0156FF;
  border-radius: 4px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  padding: 0 8px;
  cursor: pointer;
}
</style>
