<template>
  <div>
    <div class="subtitle-title">{{$t('aiTitle.setup')}}</div>
    <div v-if="settings.content && settings.content.length > 0">
      <div v-for="(temp, index) in settings.content" :key="index" class="flex-row-center w-100" >
        <div class="language-tag">{{ getLanguageName(temp.value) }}</div>
        <el-input class="w-100" type="textarea" v-model="temp.text" maxlength="200" @blur="onInputBlur(temp, 1)"/>
      </div>
    </div>
    <el-input v-else class="w-100" type="textarea" maxlength="200" v-model="tempContent" @blur="onInputBlur(tempContent, 2)"/>
    <div class="subtitle-title">{{$t('aiTitle.font')}}</div>
    <el-select v-model="settings.font" :placeholder="$t('title.tipFont')" @change="handleItemUpdated">
      <el-option
        v-for="dict in dict.type.font_typeface"
        :key="dict.value"
        :label="dict.label"
        :value="dict.value"
      />
    </el-select>
    <div class="subtitle-title">{{$t('aiTitle.fontSize')}}</div>
    <el-radio-group v-model="settings.fontSizeType" @input="fontSizeChange">
      <el-radio label="small">{{$t('title.small')}}</el-radio>
      <el-radio label="normal">{{$t('title.normal')}}</el-radio>
      <el-radio label="big">{{$t('title.big')}}</el-radio>
    </el-radio-group>
    <div class="subtitle-title">{{$t('aiTitle.letterSpacing')}}</div>
    <el-input-number v-model="settings.spacing" :min="0" :max="20" @change="handleItemUpdated"></el-input-number>
    <div class="subtitle-title">{{$t('aiTitle.fontColor')}}</div>
    <el-color-picker v-model="settings.fontColor" @change="handleItemUpdated"></el-color-picker>
    <div class="subtitle-title">{{$t('aiTitle.strokeWidth')}}</div>
    <el-input-number v-model="settings.outline" :min="0" :max="10" @change="handleItemUpdated"></el-input-number>
    <div class="subtitle-title">{{$t('aiTitle.strokeColor')}}</div>
    <el-color-picker v-model="settings.outlineColour" @change="handleItemUpdated"></el-color-picker>
    <div class="subtitle-title">{{$t('aiTitle.position')}}</div>
    <el-radio-group v-model="settings.alignment" @input="alignmentUpdated">
      <el-radio label="TopLeft">{{$t('title.left')}}</el-radio>
      <el-radio label="TopCenter">{{$t('title.center')}}</el-radio>
      <el-radio label="TopRight">{{$t('title.right')}}</el-radio>
    </el-radio-group>
    <div class="subtitle-title">{{$t('aiTitle.opacity')}}</div>
    <el-slider v-model="settings.fontColorOpacity" :max="1" :min="0" :step="0.01" show-input @change="handleItemUpdated"></el-slider>
    <div class="subtitle-title">{{$t('aiTitle.style')}}</div>
    <el-checkbox-group v-model="settings.fontFaces" @change="changeFontFace">
      <el-checkbox label="Italic">{{$t('title.italic')}}</el-checkbox>
      <el-checkbox label="Bold">{{$t('title.blod')}}</el-checkbox>
      <el-checkbox label="Underline">{{$t('title.underline')}}</el-checkbox>
    </el-checkbox-group>
    <div class="subtitle-title flex-row-center">{{$t('aiTitle.decorativeText')}}
      <div class="flex-row-center f-grow f-between">
        <el-switch v-model="settings.openEffectColorStyle" style="margin: 10px;" @change="switchEffectUpdated"></el-switch>
        <el-button type="text" v-if="settings.openEffectColorStyle" @click="isExpansion = !isExpansion">{{ isExpansion ? $t('collapse') : $t('expansion') }}</el-button>
      </div>
    </div>
    <div class="image-container" v-if="settings.openEffectColorStyle">
      <img v-for="(item, index) in styleList"
        :key="index"
        :src="item.imgUrl"
        :class="{ selected: settings.effectColorStyle === item.effectColorStyle }"
        @click="selectEffectItem(item)"
      />
    </div>
  </div>
</template>

<script>
import { batchTranslate } from '../../api/ai/language';

export default {
  dicts: [
    'font_typeface',
  ],
  props:{
    value: {
      type: Object,
      default: () => ({}) // 默认值为空对象
    },
    effectColorStyleList:{
      type: Array,
      default: () => []
    },
    languageCode: {
      type: Array,
      default: () => []
    },
    languageList: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    value: {
      handler(val) {
        // 合并 settings 的初始值与传入的 value 值，保留初始化内容
        // this.settings = {
        //   ...this.settings,
        //   ...val
        // };
        this.settings = val
      },
      deep: true
    }
  },
  data() {
    return {
      // settings:{
      //   adaptMode:"AutoWrapAtSpaces",
      //   content: [],//标题
      //   fontColorOpacity: 1,//透明度传的之后0-1
      //   x: 0,
      //   y: 0.1,
      //   outline: 0,//描边宽度
      //   outlineColour: "#ffffff",//描边颜色
      //   spacing: 0,//字间距
      //   // lineSpacing: '',//行间距
      //   fontSize: 100,//字体大小
      //   fontSizeType:"normal",
      //   font:"SimSun",//字体
      //   fontColor:"#ffffff",//字体颜色
      //   alignment:'TopCenter',
      //   position:'center',
      //   fontFaces: [],//字体样式
      //   fontFace:{
      //     Italic:false,
      //     Underline:false,
      //     Bold:false
      //   },
      //   openEffectColorStyle:false,
      //   effectColorStyle:'',//花字
      // },
      settings: this.value,
      isExpansion: false, //是否展开花字
      tempContent: '', // 临时存储的标题内容
    };
  },
  computed: {
    styleList(){
      return this.isExpansion? this.effectColorStyleList : this.effectColorStyleList.slice(0, 6)
    }
  },
  methods:{
    getLanguageName(code) {
      const item = this.languageList.find(lang => lang.bdCode === code);
      return item ? item.language : code;
    },
    // 失焦时调用的方法
    onInputBlur(temp, type) {
      console.log('onInputBlur',temp)
      // 如果是中文输入框
      if(type == 1 && this.settings.content && this.settings.content.length > 1 && temp.value === 'zh'){
        if(temp.text){
          this.translate(temp)
        } else {
          this.settings.content = this.settings.content.map(item => {
            return {
              ...item,
              text: '' // 清空 text 值
            };
          });
        }
      }else if(type == 2 && this.tempContent){
        this.settings.content = [{text: this.tempContent,  value: 'zh'}]
        this.translate(this.settings.content[0])
      }
      this.handleItemUpdated()
    },
    translate(temp){
      batchTranslate({
        text: temp.text,
        to: this.languageCode.join(','),
        from:'auto'
      }).then(response => {
        console.log('response',response)
        if(response.data && response.data.length > 0){
          let translateList = response.data
          // 将翻译结果映射为需要的格式
          let updatedTextList = translateList.map(item => {
            return {
              text: item.dst,  // 翻译后的文本
              value: item.to   // 目标语言
            };
          });
          // 保留原有的 'zh' 文本项
          const originalZh = this.settings.content.find(item => item.value === 'zh');
          if (originalZh) {
            updatedTextList.unshift({
              text: originalZh.text,  // 原始中文文本
              value: 'zh'             // 保留 value 为 'zh'
            });
          }
          // 更新 settings.content
          this.settings.content = updatedTextList;
          this.handleItemUpdated()
        }
      });
    },
    //选中花字
    selectEffectItem(item){
        this.settings.effectColorStyle = item.effectColorStyle
        this.settings.outlineColour = item.outlineColour
        this.handleItemUpdated()
    },
    switchEffectUpdated(){
      if(!this.settings.openEffectColorStyle){
        this.settings.effectColorStyle = ''
      }
      this.handleItemUpdated()
    },
    //更新数据到上个页面
    handleItemUpdated() {
      this.$emit('item-updated', this.settings);
    },
    fontSizeChange(){
      if(this.settings.fontSizeType==="small"){
        this.settings.fontSize = 37
      }else if(this.settings.fontSizeType==="big"){
        this.settings.fontSize = 70
      }else {
        this.settings.fontSize = 53
      }
      this.handleItemUpdated()
    },
    alignmentUpdated(){
      if(this.settings.alignment==='TopLeft'){
        this.settings.position = 'left'
      }else if(this.settings.alignment==='TopRight'){
        this.settings.position = 'right'
      }else{
        this.settings.position = 'center'
      }
      this.handleItemUpdated()
    },
    changeFontFace(){
      let fontFace = {};
      if(this.settings.fontFaces && Array.isArray(this.settings.fontFaces)) {
        fontFace.Bold = this.settings.fontFaces.includes('Bold');
        fontFace.Italic = this.settings.fontFaces.includes('Italic');
        fontFace.Underline = this.settings.fontFaces.includes('Underline');
      }
      this.settings.fontFace = fontFace;
      this.handleItemUpdated()
    },
  }
};
</script>

<style scoped>
.preview {
  position: relative;
  width: 253px;
  height: 450px;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
}
.selected {
  border: 1px solid blue;
  border-radius: 5px;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 图片间距 */
}

.image-container img {
  width: calc(32% - 5px); /* 两个图片一排，减去间距的一半 */
  box-sizing: border-box; /* 包含 padding 和 border */
  padding: 5px;
}
.el-form-item{
  margin-bottom: 2px;
}
.el-form--label-top .el-form-item__label{
  padding: 0;
}
.subtitle-title{
  margin: 5px 0;
}

.language-tag {
  width: 60px;
  background: #FFEEE8;
  border-radius: 4px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #FF5D01;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  padding: 3px 0;
  margin-right: 5px;
  text-align: center;
}
</style>
