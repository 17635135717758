var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "template-card" }, [
    _c(
      "div",
      { staticClass: "steps flex-column" },
      _vm._l(_vm.item.details, function (step, i) {
        return _c(
          "div",
          { key: i, staticClass: "w-100 flex-row mt5" },
          [
            _c("span", { staticClass: "step f-shrink" }, [
              _vm._v(_vm._s(step.label)),
            ]),
            _c("el-input", {
              staticClass: "f-grow",
              attrs: { type: "textarea", maxlength: "200" },
              on: {
                input: function ($event) {
                  return _vm.updateStep(i, step.value)
                },
              },
              model: {
                value: step.value,
                callback: function ($$v) {
                  _vm.$set(step, "value", $$v)
                },
                expression: "step.value",
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }