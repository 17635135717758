var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "cut-scroll" },
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "form",
            staticClass: "form-top",
            attrs: {
              "label-position": "top",
              model: _vm.form,
              rules: _vm.rules,
            },
          },
          [
            _c("div", { staticClass: "model-title" }, [
              _vm._v(_vm._s(_vm.$t("model.basicInfo"))),
            ]),
            _c(
              "el-form-item",
              {
                attrs: { label: _vm.$t("model.modelName"), prop: "modelName" },
              },
              [
                _c("el-input", {
                  attrs: { maxlength: "50", placeholder: _vm.$t("tip.input") },
                  model: {
                    value: _vm.form.modelName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "modelName", $$v)
                    },
                    expression: "form.modelName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("model.modelDescription"),
                  prop: "description",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "200",
                    placeholder: _vm.$t("tip.input"),
                    rows: 3,
                  },
                  model: {
                    value: _vm.form.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "model-title" }, [
              _vm._v(_vm._s(_vm.$t("model.fileModel"))),
            ]),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("model.trainingModule"),
                  prop: "modelType",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.form.modelType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "modelType", $$v)
                      },
                      expression: "form.modelType",
                    },
                  },
                  _vm._l(_vm.dict.type.training_module, function (dict) {
                    return _c(
                      "el-radio",
                      { key: dict.value, attrs: { label: dict.value } },
                      [_vm._v(" " + _vm._s(dict.label) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "model-tip flex-row-center" }, [
              _c("i", { staticClass: "el-icon-warning" }),
              _vm._v(" " + _vm._s(_vm.$t("model.trainingAdvice")) + " "),
            ]),
            _c(
              "el-form-item",
              { attrs: { prop: "files" } },
              [
                _c("videoUpload", {
                  attrs: {
                    buttonText: _vm.$t("model.upload"),
                    limit: 10,
                    fileSize: 50,
                    fileType: _vm.fileType,
                  },
                  model: {
                    value: _vm.form.files,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "files", $$v)
                    },
                    expression: "form.files",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "model-title" }, [
              _vm._v(_vm._s(_vm.$t("model.qnaModel"))),
            ]),
            _vm._l(_vm.form.questions, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "question-item flex-row-center" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: _vm.$t("tip.input"),
                        maxlength: "200",
                      },
                      model: {
                        value: item.problem,
                        callback: function ($$v) {
                          _vm.$set(item, "problem", $$v)
                        },
                        expression: "item.problem",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v(_vm._s(_vm.$t("model.question"))),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-input",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        placeholder: _vm.$t("tip.input"),
                        maxlength: "200",
                      },
                      model: {
                        value: item.answer,
                        callback: function ($$v) {
                          _vm.$set(item, "answer", $$v)
                        },
                        expression: "item.answer",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v(_vm._s(_vm.$t("model.answer"))),
                      ]),
                    ],
                    2
                  ),
                  _c("i", {
                    staticClass: "iconfont icon-delete",
                    on: {
                      click: function ($event) {
                        return _vm.deleteQuestion(index)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            _c(
              "el-button",
              { staticClass: "normal-button", on: { click: _vm.addQestion } },
              [
                _c("i", { staticClass: "el-icon-plus" }),
                _vm._v(_vm._s(_vm.$t("model.addQna"))),
              ]
            ),
            (_vm.form.modelFiles && _vm.form.modelFiles.length > 0) ||
            (_vm.form.modelDocumentSegments &&
              _vm.form.modelDocumentSegments.length > 0)
              ? _c("div", { staticClass: "model-title mt16" }, [
                  _vm._v(_vm._s(_vm.$t("model.trainingResult"))),
                ])
              : _vm._e(),
            _vm.form.modelFiles && _vm.form.modelFiles.length > 0
              ? _c("div", [_vm._v(_vm._s(_vm.$t("model.fileModel")))])
              : _vm._e(),
            _vm._l(_vm.form.modelFiles, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "modelFile-item flex-row-center f-between",
                },
                [
                  _c("span", [_vm._v(_vm._s(item.fileName))]),
                  _c("span", [_vm._v(_vm._s(item.fileSize))]),
                  _c("span", [_vm._v(_vm._s(item.modelType))]),
                  _c("span", { staticClass: "success-blue" }, [
                    _c("i", { staticClass: "el-icon-circle-check" }),
                    _vm._v(_vm._s(_vm.$t("model.trainingSuccess"))),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.createTime))]),
                  _c(
                    "span",
                    [
                      _c(
                        "el-tooltip",
                        { attrs: { content: _vm.$t("file.download") } },
                        [
                          item.fileUrl
                            ? _c("i", {
                                staticClass: "iconfont icon-a-dagoubeifen21",
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(item.fileUrl)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        { attrs: { content: _vm.$t("remove") } },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteFile(item, index)
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            _vm.form.modelDocumentSegments &&
            _vm.form.modelDocumentSegments.length > 0
              ? _c("div", [_vm._v(_vm._s(_vm.$t("model.qnaModel")))])
              : _vm._e(),
            _vm.isShowQuestion
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "question-item flex-row-center" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: _vm.$t("tip.input"),
                            maxlength: "200",
                          },
                          model: {
                            value: _vm.currentQuestion.problem,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentQuestion, "problem", $$v)
                            },
                            expression: "currentQuestion.problem",
                          },
                        },
                        [
                          _c("template", { slot: "prepend" }, [
                            _vm._v(_vm._s(_vm.$t("model.question"))),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-input",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: {
                            placeholder: _vm.$t("tip.input"),
                            maxlength: "200",
                          },
                          model: {
                            value: _vm.currentQuestion.answer,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentQuestion, "answer", $$v)
                            },
                            expression: "currentQuestion.answer",
                          },
                        },
                        [
                          _c("template", { slot: "prepend" }, [
                            _vm._v(_vm._s(_vm.$t("model.answer"))),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-center mt10 mb10" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-white",
                          on: { click: _vm.cancelQestion },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "gradient-button",
                          attrs: { loading: _vm.quesLoading },
                          on: { click: _vm.confirmQestion },
                        },
                        [_vm._v(_vm._s(_vm.$t("sure")))]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
        _vm._l(_vm.form.modelDocumentSegments, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "modelFile-item flex-row-center f-between",
            },
            [
              _c("span", [_vm._v(_vm._s(item.problem))]),
              _c("span", [_vm._v(_vm._s(item.modelType))]),
              _c("span", { staticClass: "success-blue" }, [
                _c("i", { staticClass: "el-icon-circle-check" }),
                _vm._v(_vm._s(_vm.$t("model.trainingSuccess"))),
              ]),
              _c("span", [_vm._v(_vm._s(item.createTime))]),
              _c(
                "span",
                [
                  _c("el-tooltip", { attrs: { content: _vm.$t("edit") } }, [
                    _c("i", {
                      staticClass: "iconfont icon-edit",
                      on: {
                        click: function ($event) {
                          return _vm.editDocument(item)
                        },
                      },
                    }),
                  ]),
                  _c("el-tooltip", { attrs: { content: _vm.$t("remove") } }, [
                    _c("i", {
                      staticClass: "iconfont icon-delete",
                      on: {
                        click: function ($event) {
                          return _vm.deleteDocument(item, index)
                        },
                      },
                    }),
                  ]),
                ],
                1
              ),
            ]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "flex-center mt10" },
      [
        _c(
          "el-button",
          { staticClass: "button-white", on: { click: _vm.cancel } },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
        _c(
          "el-button",
          {
            staticClass: "gradient-button",
            attrs: { loading: _vm.buttonLoading },
            on: { click: _vm.submitForm },
          },
          [_vm._v(_vm._s(_vm.$t("model.startTraining")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }