<template>
  <el-form ref="form" :model="user" :rules="rules" label-width="80px">
    <el-form-item :label="$t('user.oldPassword')" prop="oldPassword">
      <el-input
        v-model="user.oldPassword"
        :placeholder="$t('user.oldPasswordRequired')"
        type="password"
        show-password
        maxlength="50"
      />
    </el-form-item>
    <el-form-item :label="$t('user.newPassword')" prop="newPassword">
      <el-input
        v-model="user.newPassword"
        :placeholder="$t('user.newPasswordRequired')"
        type="password"
        show-password
        maxlength="50"
      />
    </el-form-item>
    <el-form-item :label="$t('user.confirmPassword')" prop="confirmPassword">
      <el-input
        v-model="user.confirmPassword"
        :placeholder="$t('user.confirmPasswordRequired')"
        type="password"
        show-password
        maxlength="50"
      />
    </el-form-item>
    <el-form-item>
      <el-button :loading="loading" type="primary" size="mini" @click="submit">{{$t('save')}}</el-button>
      <el-button type="danger" size="mini" @click="close">{{$t('back')}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {updateUserPwd} from "@/api/system/user";

export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error(this.$t('user.passwordsDoNotMatch')));
      } else {
        callback();
      }
    };
    return {
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      },
      // 表单校验
      rules: {
        oldPassword: [{required: true, message: this.$t('user.oldPasswordCannotBeEmpty'), trigger: "blur"}],
        newPassword: [
          {required: true, message: this.$t('user.newPasswordCannotBeEmpty'), trigger: "blur"},
          {
            pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/])[0-9a-zA-Z~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/]{8,18}$/,
            message: this.$t('user.passwordPolicy'),
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {required: true, message: this.$t('user.confirmPasswordCannotBeEmpty'), trigger: "blur"},
          {required: true, validator: equalToPassword, trigger: "blur"},
        ],
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true
          updateUserPwd(this.user.oldPassword, this.user.newPassword).then((response) => {
            this.$modal.msgSuccessTime(this.$t('user.updateSuccess'), 3000);
            this.reset()
            // let redirectPath = this.$route.query.redirectPath
            // if (redirectPath != null) {
            //   this.$router.push({
            //     path: "/" + redirectPath,
            //   });
            // }
          }).finally(() => {
            this.loading = false
          })
        }
      });
    },
    reset() {
      this.user = {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      }
    },
    close() {
      this.$tab.closePage();
    },
  },
};
</script>
