var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog",
      attrs: {
        visible: _vm.localVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
        "show-close": false,
        "before-close": _vm.handleBeforeClose,
        "destroy-on-close": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("file.folderName"), prop: "input" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "50",
                  placeholder: _vm.$t("file.folderNameTip"),
                },
                model: {
                  value: _vm.form.input,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "input", $$v)
                  },
                  expression: "form.input",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("file.folderPath"),
                prop: "materialTypeId",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flexTreeselect" },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.treeFileList,
                      "show-count": false,
                      normalizer: _vm.normalizer,
                      clearable: false,
                      "load-options": _vm.loadOptions,
                      placeholder: _vm.$t("file.tipSelectFolder"),
                      disabled: _vm.isSystem,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "value-label",
                        fn: function (ref) {
                          var node = ref.node
                          return [_vm._v(" " + _vm._s(node.raw.name) + " ")]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.form.materialTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "materialTypeId", $$v)
                      },
                      expression: "form.materialTypeId",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("file.folderLabel"), prop: "label" } },
            [
              _c(
                "el-select",
                {
                  ref: "searchSelect",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "allow-create": "",
                    filterable: "",
                    multiple: "",
                    "default-first-option": "",
                    placeholder: _vm.$t("label.tipSelect"),
                  },
                  on: { input: _vm.handleInput },
                  model: {
                    value: _vm.form.labels,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "labels", $$v)
                    },
                    expression: "form.labels",
                  },
                },
                _vm._l(_vm.labelList, function (dict) {
                  return _c("el-option", {
                    key: dict.id,
                    attrs: { label: dict.label, value: dict.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "cancelBtn", on: { click: _vm.close } },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn",
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleConfirm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }