<template>
  <el-dialog
    class="publicDialog"
    :visible.sync="localVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :show-close="false"
    :before-close="handleBeforeClose"
    :destroy-on-close="true"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ title }}</div>
        <i
          class="el-icon-close"
          @click="close"
        ></i>
      </div>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-position="left"
      label-width="100px"
    >
      <el-form-item
        :label="$t('file.folderName')"
        prop="input"
      >
        <el-input
          v-model="form.input"
          style="width:100%;"
          maxlength="50"
          :placeholder="$t('file.folderNameTip')"
        />
      </el-form-item>
      <el-form-item
        :label="$t('file.folderPath')"
        prop="materialTypeId"
      >
        <div class="flexTreeselect">
          <treeselect
            v-model="form.materialTypeId"
            :options="treeFileList"
            :show-count="false"
            :normalizer="normalizer"
            :clearable="false"
            :load-options="loadOptions"
            :placeholder="$t('file.tipSelectFolder')"
            :disabled="isSystem"
          >
            <template #value-label="{ node }">
              {{ node.raw.name }}
            </template>
          </treeselect>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('file.folderLabel')"
        prop="label"
      >
        <el-select
          ref="searchSelect"
          v-model="form.labels"
          style="width:100%;"
          allow-create
          filterable
          multiple
          default-first-option
          :placeholder="$t('label.tipSelect')"
          @input="handleInput"
        >
          <el-option
            v-for="dict in labelList"
            :key="dict.id"
            :label="dict.label"
            :value="dict.label"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="cancelBtn"
        @click="close"
      >{{$t('cancel')}}</el-button>
      <el-button
        class="submitBtn"
        :loading="buttonLoading"
        type="primary"
        @click="handleConfirm('ruleForm')"
      >{{$t('confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addMaterialType, editMaterialType } from "../../api/ai/materialType";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    Treeselect,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    labelList: {
      type: Array,
      default: () => [],
    },
    treeFileList: {
      type: Array,
      default: () => [],
    },
    isSystem: {
      type: Boolean,
      default: false,
    },
    //默认选择的素材分类
    materialTypeId: {
      type: Number,
      default: 0,
    },
    initialValue: {
      type: String,
      default: "",
    },
    initialLabel: {
      type: String,
      default: "",
    },
    editMaterialId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      buttonLoading: false,
      form: {
        input: this.initialValue,
        materialTypeId: this.materialTypeId,
        labels: this.initialLabel ? this.initialLabel.split(",") : [],
      },
      rules: {
        input: [
          { required: true, message: this.$t("tip.input"), trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
    initialValue(newVal) {
      this.form.input = newVal;
    },
    initialLabel(newVal) {
      this.form.labels = newVal ? newVal.split(",") : [];
    },
    materialTypeId(newVal) {
      console.log("materialTypeId", newVal);
      this.form.materialTypeId = newVal || 0;
    },
  },
  methods: {
    findParentIdById(targetId) {
      const item = this.treeFileList.find((entry) => entry.id === targetId);
      return item ? item.parentId : 0; // 如果找到，返回 parentId，否则返回 null
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.materialCount > 0 ? `  (${node.materialCount})` : "";
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children,
      };
    },
    loadOptions(node) {
      // 如果节点没有子节点，返回空数组,此接口可以添加检索功能检索是否有子节点
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([]);
    },
    handleInput(value) {
      if (value != null) {
        var label = value[value.length - 1];
        if (label != null && label.length > 20) {
          value[value.length - 1] = label.substring(0, 20);
        }
      }
    },
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title == this.$t("file.editFolder")) {
            if (this.editMaterialId == this.form.materialTypeId) {
              this.$modal.msgError(this.$t("file.editFolderTip"));
              return;
            }
            this.buttonLoading = true;
            editMaterialType({
              id: this.editMaterialId,
              name: this.form.input,
              parentId: this.form.materialTypeId || 0,
              label: this.form.labels.join(","),
              typeCode: this.form.materialTypeId
                ? this.form.materialTypeId + "." + this.editMaterialId + "."
                : this.editMaterialId + ".",
            })
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.update"));
                this.$emit("submitAddFile");
                this.close();
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          } else {
            this.buttonLoading = true;
            // 处理输入内容，例如发送请求或更新数据
            addMaterialType({
              name: this.form.input,
              parentId: this.form.materialTypeId || 0,
              label: this.form.labels.join(","),
            })
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.add"));
                this.$emit("submitAddFile");
                this.close();
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleBeforeClose(done) {
      this.close();
      done();
    },
    close() {
      this.localVisible = false;
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.form = {
        input: this.initialValue,
        materialTypeId: this.materialTypeId,
        labels: this.initialLabel ? this.initialLabel.split(",") : [],
      };
      this.buttonLoading = false;
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
