<template>
  <el-drawer
    append-to-body
    :title="$t('basicInfo.materia')"
    size="65%"
    :visible.sync="visible"
    :with-header="false"
    :modal="false"
    :before-close="handleClose"
  >
    <Material_library
      ref="materialLibrary"
      :skipInit="true"
      @selectionConfirmed="handleSelectionConfirmed"
      @cancel="handleClose"
    ></Material_library>
  </el-drawer>
</template>
<script>
import Material_library from "../../views/aivideo/material.vue";
export default {
  name: "MaterialDrawer",
  components: {
    Material_library,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleSelectionConfirmed(payload) {
      this.$emit("selectionConfirmed", payload);
    },
    handleClose(){
      this.$nextTick().then((rs) => {
        this.$refs.materialLibrary.closeAudio();
      });
      this.$emit("update:visible", false);
    }
  },
};
</script>
