var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("user.oldPassword"), prop: "oldPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: _vm.$t("user.oldPasswordRequired"),
              type: "password",
              "show-password": "",
              maxlength: "50",
            },
            model: {
              value: _vm.user.oldPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "oldPassword", $$v)
              },
              expression: "user.oldPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("user.newPassword"), prop: "newPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: _vm.$t("user.newPasswordRequired"),
              type: "password",
              "show-password": "",
              maxlength: "50",
            },
            model: {
              value: _vm.user.newPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "newPassword", $$v)
              },
              expression: "user.newPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("user.confirmPassword"),
            prop: "confirmPassword",
          },
        },
        [
          _c("el-input", {
            attrs: {
              placeholder: _vm.$t("user.confirmPasswordRequired"),
              type: "password",
              "show-password": "",
              maxlength: "50",
            },
            model: {
              value: _vm.user.confirmPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "confirmPassword", $$v)
              },
              expression: "user.confirmPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.$t("back")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }