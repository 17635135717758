<template>
  <div class="login-container flex-center">
    <div class="login-main flex-row">
      <div class="login-bg-50 login-left-radius flex-column-center">
        <img
          src="@/assets/images/login/bg-small.jpg"
          alt=""
          class="login-img-left login-left-radius"
        />
        <div
          class="left-logo"
          v-show="isOnLoad"
        >InfoPub</div>
        <div
          class="left-des f-grow"
          v-show="isOnLoad"
        >智能创作发布管理系统</div>
        <div
          class="left-bottom"
          v-show="isOnLoad"
        >一键发布多个平台</div>
      </div>
      <div class="login-bg-50 login-right-radius flex-column">
        <el-form
          ref="forgotPasswordForm"
          label-width="0"
          class="forgot-password-form"
          :model="forgotPasswordForm"
          :rules="forgotPasswordRules"
        >
          <div class="forgot-password-title">{{ $t('forget.retrievePassword') }}</div>
          <div class="go-login">
            <router-link
              class="link-type"
              :to="'/login'"
            >{{ $t('forget.backToLogin') }}</router-link>
          </div>
          <el-form-item prop="username">
            <el-input
              v-model="forgotPasswordForm.username"
              type="text"
              auto-complete="off"
              :placeholder="$t('forget.inputEmailPhone')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="forgotPasswordForm.password"
              type="password"
              show-password
              auto-complete="off"
              :placeholder="$t('forget.setNewPassword')"
              @keyup.enter.native="handleForgotPassword"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="againPassword">
            <el-input
              v-model="forgotPasswordForm.againPassword"
              type="password"
              show-password
              autocomplete="off"
              :placeholder="$t('newRegister.againPassword')"
              @keyup.enter.native="handleForgotPassword"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="forgotPasswordForm.code"
              auto-complete="off"
              :placeholder="$t('forget.inputVerificationCode')"
              @keyup.enter.native="handleForgotPassword"
            >
              <div
                slot="suffix"
                class="el-input__icon input-icon phone-code"
              >
                <el-button
                  class="phone-code-img"
                  :loading="codeLoding"
                  @click="sendCode"
                  v-if="!isDisabled"
                >{{ content }}</el-button>
                <el-button
                  class="phone-code-img wait"
                  v-if="isDisabled"
                >{{ content }}</el-button>
              </div>
            </el-input>
          </el-form-item>
          <el-button
            :loading="loading"
            class="login-btn"
            style="margin-top: 56px;"
            @click.native.prevent="handleForgotPassword"
          >
            <span v-if="!loading">{{ $t('forget.resetPassword') }}</span>
            <span v-else>{{ $t('forget.resetting') }}</span>
          </el-button>
        </el-form>
      </div>
    </div>
    <div class="el-login-footer">
      <span>Copyright © 2022-2025 Infonity AI </span>
    </div>
    <SlideVerifyDialog
      :showSlide.sync="showSlide"
      @success="onSuccess"
      @fail="onFail"
    />
  </div>
</template>

<script>
import { forgetPassword } from "@/api/login";
import { sendEmilCode, sendMyPhone } from "@/api/base/resource";
import Cookies from "js-cookie";
import { encrypt } from "@/utils/jsencrypt";
import SlideVerifyDialog from "../components/dialog/SlideVerifyDialog.vue";

export default {
  name: "ForgotPassword",
  components: {
    SlideVerifyDialog,
  },
  data() {
    return {
      isOnLoad: false,// 图片是否加载完成
      isDisabled: false,
      content: this.$t("newRegister.getEmailOrPhoneCode"),
      timer: null,
      count: "",
      codeType: "",
      forgotPasswordForm: {
        username: "",
        code: "",
        password: "",
        againPassword: "",
      },
      forgotPasswordRules: {
        username: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipEmail"),
          },
          { validator: this.validateEmailOrPhone, trigger: ["blur", "change"] },
        ],
        password: [
          {
            required: true,
            message: this.$t("register.passwordEmpty"),
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/])[0-9a-zA-Z~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/]{8,18}$/,
            message: this.$t("register.passwordCriteria"),
            trigger: "blur",
          },
        ],
        againPassword: [
          {
            required: true,
            message: this.$t("register.passwordEmpty"),
            trigger: ["blur", "change"],
          },
          { validator: this.validateAgainPassword, trigger: "blur" },
        ],
        code: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("register.enterVerificationCode"),
          },
        ],
      },
      loading: false,
      captchaEnabled: true,
      codeLoding: false,
      showSlide: false, //滑块验证
    };
  },
  created() {},
  methods: {
    onImageLoad() {
      // 图片加载完成后，设置isOnLoad为true
      this.isOnLoad = true;
    },
    onSuccess(times) {
      this.showSlide = false;
      this.getCodeClick();
    },
    onFail() {},
    validateEmailOrPhone(rule, value, callback) {
      // 邮箱正则表达式
      const emailPattern =
        /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      // 手机号正则表达式 (中国手机号格式)
      const phonePattern = /^1[3-9]\d{9}$/;

      // 校验邮箱或手机号
      if (emailPattern.test(value)) {
        this.codeType = "email"; // 如果是邮箱，设置 inputType 为 'email'
        callback();
      } else if (phonePattern.test(value)) {
        this.codeType = "phone"; // 如果是手机号，设置 inputType 为 'phone'
        callback();
      } else {
        callback(new Error(this.$t("newRegister.tipCorrectEmailOrPhone")));
      }
    },
    validateAgainPassword(rule, value, callback) {
      // 检查再次输入的密码是否与前一次的密码一致
      if (value !== this.forgotPasswordForm.password) {
        callback(new Error(this.$t("newRegister.passwordMismatch")));
      } else {
        callback(); // 校验通过
      }
    },
    // 发送验证码-滑块
    sendCode() {
      const emailOrPhone = this.forgotPasswordForm.username;
      if (!this.codeType || !emailOrPhone) {
        this.$refs.forgotPasswordForm.validateField("email");
        return;
      }
      const isValidEmail =
        /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          emailOrPhone
        );
      const isValidPhone = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(emailOrPhone);
      if (
        (this.codeType === "email" && !isValidEmail) ||
        (this.codeType === "phone" && !isValidPhone)
      ) {
        this.$refs.forgotPasswordForm.validateField("email");
        return;
      }
      this.showSlide = true;
    },
    getCodeClick() {
      if (this.codeType === "email") {
        this.sendEmail();
      } else if (this.codeType === "phone") {
        this.sendPhone();
      }
    },
    // 发送邮箱验证码
    sendEmail() {
      let vm = this;
      vm.content = "";
      this.codeLoding = true;
      sendEmilCode({
        sendType: "reset",
        email: vm.forgotPasswordForm.username,
      })
        .then((res) => {
          if (res.code === 200) {
            this.codeLoding = false;
            vm.isDisabled = false;
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.content = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                vm.isDisabled = true;
                vm.count--;
                vm.content = vm.count + this.$t("login.later");
              } else {
                vm.isDisabled = false;
                vm.content = this.$t("login.getEmailCode");
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.codeLoding = false;
          vm.content = this.$t("login.getEmailCode");
        });
    },
    // 发送手机验证码
    sendPhone() {
      let vm = this;
      vm.content = "";
      this.codeLoding = true;
      sendMyPhone({
        sendType: "reset",
        phone: vm.forgotPasswordForm.username,
      })
        .then((res) => {
          if (res.code === 200) {
            this.codeLoding = false;
            vm.isDisabled = false;
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.content = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                vm.isDisabled = true;
                vm.count--;
                vm.content = vm.count + this.$t("login.later");
              } else {
                vm.isDisabled = false;
                vm.content = this.$t("login.getSMSCode");
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.codeLoding = false;
          vm.content = this.$t("login.getSMSCode");
        });
    },
    handleForgotPassword() {
      this.$refs.forgotPasswordForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.codeType === "email") {
            this.forgotPasswordForm.email = this.forgotPasswordForm.username;
          } else if (this.codeType === "phone") {
            this.forgotPasswordForm.phonenumber =
              this.forgotPasswordForm.username;
          }
          forgetPassword({
            ...this.forgotPasswordForm,
            resetType: this.codeType,
          })
            .then((res) => {
              this.$alert(
                "<font color='red'>" +
                  this.$t("forget.resetSuccess") +
                  "</font>",
                this.$t("register.systemPrompt"),
                {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }
              )
                .then(() => {
                  Cookies.set("username", this.forgotPasswordForm.username, {
                    expires: 30,
                  });
                  Cookies.set(
                    "password",
                    encrypt(this.forgotPasswordForm.password),
                    { expires: 30 }
                  );
                  this.$router.push({ path: "/login" });
                })
                .catch(() => {});
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss"  lang="scss" scoped>
.forgot-password-form {
  padding: 0 110px;

  ::v-deep .el-input--medium .el-input__inner {
    height: 56px;
    line-height: 56px;
    background: #eff6fd;
    border-radius: 14px;
    border: 1px solid #b9c4d5;
  }
  ::v-deep .el-form-item {
    margin-bottom: 32px;
  }
}
.forgot-password-title {
  height: 34px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  line-height: 34px;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin-top: 61px;
}
.go-login {
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin: 16px 0 40px;
}
@media screen and (max-height: 900px) {
  .forgot-password-form {
    padding: 0 65px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 46px;
      line-height: 46px;
      background: #eff6fd;
      border-radius: 14px;
      border: 1px solid #b9c4d5;
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
  }
  .forgot-password-title {
    height: 34px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 30px;
    color: #000000;
    line-height: 34px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    margin-top: 40px;
  }
  .go-login {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 16px 0 32px;
  }
}
@media screen and (max-height: 768px) {
  .forgot-password-form {
    padding: 0 35px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 40px;
      line-height: 40px;
      background: #eff6fd;
      border-radius: 10px;
      border: 1px solid #b9c4d5;
    }
    ::v-deep .el-form-item {
      margin-bottom: 24px;
    }
  }
  .forgot-password-title {
    height: 34px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    line-height: 34px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    margin-top: 32px;
  }
  .go-login {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 10px 0 24px;
  }
}
</style>
