<!-- 新增文案 -->
<template>
  <el-dialog
    class="publicDialog"
    width="500px"
    :visible.sync="localVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :before-close="handleBeforeClose"
    :destroy-on-close="true"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ title }}</div>
        <i
          class="el-icon-close"
          @click="close"
        ></i>
      </div>
    </div>
    <el-form
      :model="textForm"
      :rules="rules"
      ref="textForm"
      label-position="left"
      label-width="100px"
    >
      <el-form-item
        :label="$t('copywriting.title')"
        prop="title"
      >
        <el-input
          v-model="textForm.title"
          :placeholder="$t('tip.input')"
          maxlength="50"
          show-word-limit
          :disabled="isSystemFile"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('copywriting.content')"
        prop="content"
      >
        <el-input
          type="textarea"
          maxlength="500"
          show-word-limit
          v-model="textForm.content"
          :placeholder="$t('tip.input')"
          :autosize="{ minRows: 2, maxRows: 4}"
          :disabled="isSystemFile"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        :label="$t('material.position')"
        prop="materialTypeId"
      >
        <!-- <el-select v-model="textForm.materialTypeId" :placeholder="$t('file.tipSelectFolder')">
            <el-option v-for="item in materialTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select> -->
        <treeselect
          v-model="textForm.materialTypeId"
          :options="materialTypeList"
          :normalizer="normalizer"
          :load-options="loadOptions"
          :clearable="false"
          :placeholder="$t('file.tipSelectFolder')"
          :disabled="isSystemFile"
        >
          <template #value-label="{ node }">
            {{ node.raw.name }}
          </template>
        </treeselect>
      </el-form-item>
      <el-form-item :label="$t('label.label')">
        <el-select
          v-model="textForm.labels"
          allow-create
          filterable
          multiple
          :placeholder="$t('label.tipSelect')"
          @input="handleInput"
        >
          <el-option
            v-for="dict in labelList"
            :key="dict.id"
            :label="dict.label"
            :value="dict.label"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="cancelBtn"
        @click="close"
      >{{$t('cancel')}}</el-button>
      <el-button
        class="submitBtn"
        :loading="buttonLoading"
        type="primary"
        @click="handleConfirm"
      >{{$t('confirm')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { addMaterial, editMaterial } from "../../api/ai/material";
export default {
  components: {
    Treeselect,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isSystemFile: {
      type: Boolean,
      default: false,
    },

    labelList: {
      type: Array,
      default: () => [],
    },
    materialTypeList: {
      type: Array,
      default: () => [],
    },
    //默认选择的素材分类
    materialTypeId: {
      type: Number,
      default: 0,
    },
    editMaterialId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      buttonLoading: false,
      textForm: {
        title: "",
        content: "",
        label: "",
        labels: [],
        materialTypeId: 0,
        fileType: "text",
      },
      form: {
        materialTypeId: this.materialTypeId,
      },
      rules: {
        title: [
          {
            required: true,
            message: this.$t("copywriting.tipTitle"),
            trigger: ["blur", "change"],
          },
        ],
        content: [
          {
            required: true,
            message: this.$t("copywriting.tipContent"),
            trigger: ["blur", "change"],
          },
        ],
        // materialTypeId: [
        //   {required: true, message: this.$t('file.tipSelectFolder'), trigger: ['blur', 'change']}
        // ],
      },
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
    materialTypeId(newVal) {
      console.log("materialTypeId", newVal);
      this.form.materialTypeId = newVal || 0;
    },
  },
  methods: {
    init(form) {
      this.textForm = Object.assign({}, this.textForm, form);
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.materialCount > 0 ? `  (${node.materialCount})` : "";
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children,
      };
    },
    loadOptions(node) {
      // 如果节点没有子节点，返回空数组,此接口可以添加检索功能检索是否有子节点
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([]);
    },
    handleInput(value) {
      if (value != null) {
        var label = value[value.length - 1];
        if (label != null && label.length > 20) {
          value[value.length - 1] = label.substring(0, 20);
        }
      }
    },
    handleConfirm() {
      this.$refs.textForm.validate((valid) => {
        if (valid) {
          if (this.textForm.labels.length > 0) {
            this.textForm.label = this.textForm.labels.join(",");
          } else {
            this.textForm.label = "";
          }
          this.buttonLoading = true;
          if (this.title == this.$t("copywriting.new")) {
            addMaterial(this.textForm)
              .then((res) => {
                this.$modal.msgSuccess(this.$t("tip.add"));
                this.$emit("submit");
                this.close();
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          } else {
            editMaterial(this.textForm)
              .then((res) => {
                this.$modal.msgSuccess(this.$t("tip.update"));
                this.$emit("submit");
                this.close();
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        }
      });
    },
    handleBeforeClose(done) {
      this.close();
      done();
    },
    close() {
      this.localVisible = false;
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.textForm = {
        title: "",
        content: "",
        label: "",
        labels: [],
        materialTypeId: 0,
        fileType: "text",
      };
      this.buttonLoading = false;
    },
  },
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 20px;
}
</style>
