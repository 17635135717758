var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "uploadboxPic flex-column-center f-center",
    },
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "hover" } },
        [
          _c("div", { staticClass: "local" }, [
            _c("i", { staticClass: "iconfont icon-shangchuan" }),
            _vm._v(" " + _vm._s(_vm.$t("file.upload")) + " "),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "",
                        accept: "image/*",
                        "before-upload": _vm.handleBeforeUpload,
                        limit: 1,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("file.localUpload")) + " ")]
                  ),
                ],
                1
              ),
              _c("el-dropdown-item", [
                _c("div", { on: { click: _vm.picSelectMaterial } }, [
                  _vm._v(_vm._s(_vm.$t("file.selectMaterial"))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }