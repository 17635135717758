<!-- 编辑视频中场景的item -->
<template>
  <div
    class="template-card flex-row-center"
    :class="{ selected: isSelected }"
    @click="selectItem"
  >
    <div class="left-section">
      <video :src="item.url" alt="video" class="video-cover" />
      <span class="video-duration" v-if="item.duration && item.duration > 0">{{ secondsFormatTime( item.duration ) }}</span>
    </div>
    <div class="f-grow ml10">
      <div v-for="(temp, index) in item.text" :key="index" class="flex-row-center w-100" >
        <div class="language-tag">{{ getLanguageName(temp.value) }}</div>
        <el-input class="w-100" type="textarea" maxlength="200" v-model="temp.text" @blur="onInputBlur(temp)"/>
      </div>
      <div class="flex-row-center mt10 w-100 f-between">
        {{ secondsFormatTime( item.startTime )}} - {{ secondsFormatTime( item.endTime )}}
        <div style="align-self: flex-end;">
          <el-tooltip :content="$t('editVideo.autoGenerate')">
            <el-button icon="el-icon-orange" size="mini" circle @click="$emit('make-video', item)"></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('editVideo.replaceClip')">
            <el-button icon="el-icon-connection" size="mini" circle @click="$emit('replace-video', item)"></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('remove')">
            <el-button icon="el-icon-delete" size="mini" circle @click="$emit('delete-video', item)"></el-button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { batchTranslate } from '../../api/ai/language';

export default {
  name: 'templateItem',
  components: {  },
  props: {
    template: {
      type: Object,
      default: () => ({})
    },
    selectedItem: {
      type: Object,
      default: null
    },
    languageCode: {
      type: Array,
      default: () => []
    },
    languageList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      item: this.template,
    }
  },
  watch: {
    template: {
      handler(val) {
        this.item = val;
      },
      deep: true
    }
  },
  computed: {
    isSelected() {
      return this.selectedItem === this.template; // 判断当前 item 是否是选中的
    }
  },
  methods: {
    getLanguageName(code) {
      const item = this.languageList.find(lang => lang.bdCode === code);
      return item ? item.language : code;
    },
    selectItem() {
      this.$emit('item-selected', this.template); // 传递当前选中的 item
    },
     // 失焦时调用的方法
     onInputBlur(temp) {
      console.log('onInputBlur',temp)
      // 如果是中文输入框
      if (this.item.text && this.item.text.length > 1 && temp.value === 'zh') {
        if(temp.text){
          // 调用翻译接口
          batchTranslate({
            text: temp.text,
            to: this.languageCode.join(','),
            from:'auto'
          }).then(response => {
            console.log('response',response)
            if(response.data && response.data.length > 0){
              let translateList = response.data
              // 将翻译结果映射为需要的格式
              let updatedTextList = translateList.map(item => {
                return {
                  text: item.dst,  // 翻译后的文本
                  value: item.to   // 目标语言
                };
              });

              // 保留原有的 'zh' 文本项
              const originalZh = this.item.text.find(item => item.value === 'zh');
              if (originalZh) {
                updatedTextList.unshift({
                  text: originalZh.text,  // 原始中文文本
                  value: 'zh'             // 保留 value 为 'zh'
                });
              }
              // 更新 item.text
              this.item.text = updatedTextList;
            }
          });
        } else {
          this.item.text = this.item.text.map(item => {
            return {
              ...item,
              text: '' // 清空 text 值
            };
          });
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.template-card {
  position: relative; /* 确保蒙层相对于模板卡片定位 */
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  &.selected {
    border-color: #409EFF;
    background-color: rgba(64, 158, 255, 0.1);
  }
}

.left-section {
  position: relative;
}
.video-cover {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.video-duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}
.language-tag {
  width: 60px;
  background: #FFEEE8;
  border-radius: 4px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #FF5D01;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  padding: 3px 0;
  margin-right: 5px;
  text-align: center;
}
</style>
