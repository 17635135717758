<!-- 编辑文稿 -->
<template>
  <div class="app-container">
    <div class="flex-row-center f-wrap">
      <div
        v-for="(item, index) in copywritings"
        :key="index"
        class="copywriting-item"
        :class="{ active: index === activeStep }"
        @click="selectStep(index)"
      >
        {{$t('editManuscripts.script')}}{{ index + 1 }}
      </div>
    </div>
    <div class="mt10 mb10">{{$t('editManuscripts.title')}}</div>
    <el-input v-if="copywritings[activeStep]" v-model="copywritings[activeStep].title"  maxlength="50" :placeholder="$t('editManuscripts.enterTitle')" />
    <div class="mt10">{{$t('editManuscripts.scriptSegmentation')}}</div>
    <div class="preview-list" v-if="copywritings[activeStep]" v-loading="selectLoading">
      <div v-for="(it, position) in copywritings[activeStep].analysisTextList" :key="position" class="flex-row-center mt10">
        <span class="item-num"> {{ position + 1 }} </span>
        <el-input class="f-grow" v-model="it.text"  maxlength="200"/>
        <el-select v-if="typeList.length" v-model="it.materialTypeId" :placeholder="$t('editManuscripts.grouping')">
          <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
            <div class="flexOption">
              <div class="optionLeft">{{ item.name }}</div>
              <!-- style="float: right; color: #8492a6; font-size: 13px" -->
              <div class="optionRight" v-if="item.id != 0">{{ item.materialCount }} {{$t('editManuscripts.material')}}</div>
            </div>
          </el-option>
        </el-select>
        <!-- <treeselect
            v-model="it.materialTypeId"
            :options="typeList"
            :show-count="true"
            :normalizer="normalizer"
            :clearable="false"
            :placeholder="$t('editManuscripts.grouping')"
            style="width: 150px;margin: 0 5px;"
          /> -->
        <i class="el-icon-delete" v-if="copywritings[activeStep].analysisTextList.length > 1" @click="deleteItem(activeStep,position)" style="cursor: pointer;"></i>
      </div>
    </div>
    <div class="mt10">{{$t('editManuscripts.addLanguage')}}</div>
    <el-select
      v-model="languageCode"
      :placeholder="$t('issueItem.translateLanguage')"
      multiple
      filterable
      clearable
      :default-first-option="true"
      style="width: 100%;"
    >
      <el-option v-for="item in languageList" :key="item.bdCode" :label="item.language"
        :value="item.bdCode" />
    </el-select>
    <!-- <el-dialog
      class="selectDialog"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="70%"
      >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">添加语种</div>
          <i class="el-icon-close" @click="dialogVisible = false"></i>
        </div>
      </div>
      <div class="langTagBox">
        <div v-for="item in languageList" :key="item.dictValue" :class="['tag', item.select ? 'selectTag' : '']" @click="selectTagFun(item.dictValue)">
          <el-tooltip v-if="item.dictLabel.length > 4" class="item" effect="dark" :content="item.dictLabel" placement="top">
            <span>{{ item.dictLabel }}</span>
          </el-tooltip>
          <span v-else>{{ item.dictLabel }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer flex-center">
        <el-button class="cancelBtn" @click="dialogVisible = false">取 消</el-button>
        <el-button class="submitBtn" type="primary" @click="submitTagList">添 加</el-button>
      </span>
    </el-dialog> -->
    <!-- <div class="langBox">
      <div v-if="selectLangList && selectLangList.length > 0">
        <el-tag size="medium" closable v-for="item in selectLangList" :key="item.dictValue" @close="closeLang(item.dictValue)">{{ item.dictLabel }}</el-tag>
      </div>
      <div class="placeholder" v-else>请选择要添加的语种</div>
      <div class="addLang"><el-button type="text" icon="el-icon-plus" @click="addLangOpen">添加语种</el-button></div>
    </div> -->
    <div class="flex-center mt10" >
      <el-button class="button-white" @click="last">{{$t('register.previous')}}</el-button>
      <el-button class="gradient-button" :loading="loading" @click="next">{{$t('editManuscripts.nextStep')}}</el-button>
    </div>
  </div>
</template>
<script>
import { listMaterialType } from '../../api/ai/materialType';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { analysisVideo } from '../../api/aiClip/aiClip';
import { getLanguages } from '../../api/ai/ali';

export default {
  name: 'EditManuscripts',
  components: {
    Treeselect
  },
  data() {
    return {
      copywritings: [],
      activeStep: 0,
      typeList: [],//素材分组列表
      loading: false,
      languageCode: null,//翻译语言
      languageList: [],
      dialogVisible: false,
      selectLangList: [],
      selectLoading: false
    }
  },
  mounted() {
    this.getLanguage()
    // this.init(sessionStorage.getItem('editManuscriptsData'))
  },
  methods: {
    // closeLang(code) {
    //   this.selectLangList = this.selectLangList.filter(item => item.dictValue !== code)
    // },
    // addLangOpen() {
    //   if (this.selectLangList.length) {
    //     this.languageList.forEach(selectItem => {
    //       const exists = this.selectLangList.some(item => item.dictValue === selectItem.dictValue)
    //       if (exists) {
    //         selectItem.select = true
    //       } else {
    //         selectItem.select = false
    //       }
    //     })
    //   } else {
    //     this.languageList.forEach(item => item.select = false)
    //   }
    //   this.dialogVisible = true
    // },
    // submitTagList() {
    //   this.selectLangList = this.languageList.filter(item => item.select === true)
    //   this.dialogVisible = false
    // },
    // selectTagFun(code) {
    //   this.languageList.forEach(item => {
    //     if (item.dictValue === code) {
    //       item.select = !item.select
    //     }
    //   })
    // },
    init(data) {
      // let list = JSON.parse(sessionStorage.getItem('editManuscriptsData'));
      let list = JSON.parse(data);
      console.log(list);
      this.getMaterialTypeList()
      this.copywritings = list.map(item => {
        return {
          title: item.title || '',
          analysisTextList: item.paragraphList.map((text, index) => ({
              text: text,
              materialTypeId: 0
          }))
        };
      });
      // 如果不再需要，可以在读取后删除
      // sessionStorage.removeItem('editManuscriptsData');
    },
    getLanguage(){
      getLanguages().then(response => {
        const newList = response.data.map(item => {
          return {...item, select: false}
        })
        const oftenZero = newList.filter(item => item.isOften === 1);
        const others = newList.filter(item => item.isOften !== 1);
        this.languageList = oftenZero.concat(others).filter(item => item.bdCode !== 'zh');
        console.log('this.languageList',this.languageList)
      });
    },
    //素材分类列表
    getMaterialTypeList() {
      this.selectLoading = true
      listMaterialType().then(response => {
        this.typeList = response.data
        this.typeList.unshift({
          id: 0,
          name: this.$t('basicInfo.materia'),
        })
        this.selectLoading = false
        console.log(this.typeList);
      });
    },
    deleteItem(index,position) {
      this.copywritings[index].analysisTextList.splice(position, 1);
    },
    selectStep(index) {
      this.activeStep = index;
    },
     /** 转换菜单数据结构 */
     normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    last(){
      this.$emit("last")
    },
    next(){
      if (this.languageCode.length > 3) {
        this.$modal.msgError(this.$t('editManuscripts.tipAddLanguage'));
        return;
      }
      this.loading = true
      const submitData = this.copywritings.map(copywriting => {
        return {
          ...copywriting,
          analysisTextList: copywriting.analysisTextList.map(textItem => ({
            ...textItem,
            materialTypeId: textItem.materialTypeId == 0 ? null : textItem.materialTypeId
          }))
        };
      });
      // this.languageCode = this.selectLangList.map(item => {
      //   return item.dictValue
      // })
      analysisVideo({
        paragraphList: submitData,
        languageCode: this.languageCode
      }).then(response => {
        console.log(response);
        sessionStorage.setItem('editVideo', JSON.stringify(response.data));
        // this.$router.push({ path: '/editVideo' });
        // this.$tab.closeOpenPage({path: "/editVideo"});
        this.$emit("next", JSON.stringify(response.data), this.languageCode)
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>
<style lang="scss" scoped>

.langBox {
  width: 100%;
  min-height: 54px;
  background: #F6F8FA;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 100px 10px 5px;
  position: relative;
  .placeholder {
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    padding-left: 15px;
  }
  .addLang {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    .el-button {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #0156FF;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
  }
  .el-tag {
    margin-right: 5px;
    margin-top: 5px;
  }
}

.selectDialog {
  .el-dialog {
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    border-radius: 12px;
  }
  .el-dialog__header {
    padding: 0px;
    padding-bottom: 0px;
  }
  .headerFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background: #F3F3F3;
    border-radius: 12px 12px 0px 0px;
    padding: 0 20px;
    .headerTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: rgba(0,0,0,0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }
    .el-icon-close {
      color: #4887EE;
      cursor: pointer;
    }
  }
  .langTagBox {
    display: flex;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: scroll;
    .tag {
      cursor: pointer;
      width: 80px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 12px;
      border: 1px solid #E5E7EB;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0,0,0,0.4);
      margin: 4px;
      span {
        width: 75px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
    .selectTag {
      background: #0156FF;
      color: #fff;
    }
  }
}

.preview-list {
  list-style: none;
  padding: 0;
}
.copywriting-item {
  padding: 8px 12px;
  margin: 5px 10px 5px 0;
  font-size: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 5px;
  color:gray;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.copywriting-item.active {
  border-color: #016FEE; /* 选中步骤的边框颜色 */
  color:#016FEE;
}
.item-num{
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.el-select-dropdown {
  width: 800px !important;
  max-width: 800px !important;
  overflow: scroll;
}

.flexOptions {
  display: flex;
  padding-left: 20px;
  width: 80%;
  max-width: 80%;
  flex-wrap: wrap;
}

// .el-select-dropdown__item {
//   // max-width: 500px;
// }

.tagContent {
  max-width: 100px;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .custom-popper .el-select-dropdown__item {
  display: flex;
  flex-wrap: wrap;
}

.custom-popper .el-select-dropdown__item:hover {
  background-color: transparent;
} */

.tag-default {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  text-align: center;
  font-style: normal;
  padding: 5px 5px;
  display: inline-block;
  width: 120px;
}

.tag-selected {
  background: #0156ff;
  border-radius: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  padding: 5px 5px;
  display: inline-block;
  width: 120px;
}

.flexOption {
  display: flex;
  align-items: center;
  .optionLeft {
    margin-right: 20px;
  }
  .optionRight {
    color: #8492a6;
    font-size: 13px
  }
}
</style>
