<template>
  <div class="login-container flex-center">
    <div class="login-main flex-row">
      <div class="login-bg-50 flex-column-center">
        <img
          src="@/assets/images/login/bg-small.jpg"
          alt=""
          class="login-img-left"
          @load="onImageLoad"
        />
        <div
          class="left-logo"
          v-show="isOnLoad"
        >InfoPub</div>
        <div
          class="left-des f-grow"
          v-show="isOnLoad"
        >智能创作发布管理系统</div>
        <div
          class="left-bottom"
          v-show="isOnLoad"
        >一键发布多个平台</div>
      </div>
      <div class="login-bg-50 flex-column">
        <lang-select class="set-language self-end" />
        <img
          src="@/assets/logo/logo.png"
          alt=""
          class="login-logo"
        />
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
        >
          <div
            class="flex-row-center between"
            style="margin-bottom: 32px;"
          >
            <div class="title-container flex-column-center">
              <div
                class="title"
                :style="loginForm.loginType !== 'username' ? 'color:rgba(0,0,0,0.4);':'color:black;'"
                @click="changeLoginType('username')"
              >{{ $t('login.accountLogin') }}</div>
              <div
                class="title-line"
                v-if="loginForm.loginType == 'username'"
              ></div>
            </div>
            <div class="title-container flex-column-center">
              <div
                class="title"
                :style="loginForm.loginType !== 'phone' ? 'color:rgba(0,0,0,0.4);':'color:black;'"
                @click="changeLoginType('phone')"
              >{{ $t('login.mobileLogin') }}</div>
              <div
                class="title-line"
                v-if="loginForm.loginType == 'phone'"
              ></div>
            </div>
          </div>
          <div v-if="loginForm.loginType === 'username'">
            <el-form-item
              prop="username"
              label-width="0"
            >
              <el-input
                :name="usernameInputName"
                :autocomplete="autocompleteOff"
                v-model="loginForm.username"
                type="text"
                auto-complete="off"
                :placeholder="$t('loginNew.inputAccount')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                :name="passwordInputName"
                :autocomplete="autocompleteOff"
                v-model="loginForm.password"
                type="password"
                auto-complete="off"
                show-password
                :placeholder="$t('loginNew.inputPassword')"
                @keyup.enter.native="handleLogin"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              prop="code"
              v-if="captchaEnabled"
            >
              <el-input
                v-model="loginForm.code"
                auto-complete="off"
                :placeholder="$t('loginNew.inputCaptcha')"
                @keyup.enter.native="handleLogin"
              >
                <div
                  class="el-input__icon input-icon login-code"
                  slot="suffix"
                >
                  <img
                    :src="codeUrl"
                    @click="getCode"
                    class="login-code-img"
                  />
                </div>
              </el-input>
            </el-form-item>
            <!-- <el-checkbox v-model="loginForm.rM" style="margin: 0px 0px 25px 0px">
              {{$t('login.rememberMe')}}
            </el-checkbox> -->
          </div>
          <div v-else>
            <el-form-item
              prop="phone"
              label-width="0"
            >
              <el-input
                v-model="loginForm.phone"
                type="text"
                auto-complete="off"
                :placeholder="$t('loginNew.inputPhone')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="imgCode">
              <el-input
                v-model="loginForm.imgCode"
                auto-complete="off"
                :placeholder="$t('login.imageVerificationCode')"
              >
                <div
                  class="el-input__icon input-icon login-code"
                  slot="suffix"
                >
                  <img
                    class="login-code-img"
                    :src="codeUrl"
                    @click="getCode"
                  />
                </div>
              </el-input>

            </el-form-item>
            <el-form-item prop="code">
              <el-input
                v-model="loginForm.code"
                auto-complete="off"
                :placeholder="$t('login.verificationCode')"
              >
                <div
                  slot="suffix"
                  class="el-input__icon input-icon phone-code"
                >
                  <el-button
                    class="phone-code-img"
                    :loading="phoneLoading"
                    @click="sendPhone()"
                    v-if="!isDisabled"
                  >{{ phoneContent }}
                  </el-button>
                  <el-button
                    class="phone-code-img"
                    v-if="isDisabled"
                  >{{ phoneContent }}</el-button>
                </div>

              </el-input>

            </el-form-item>
          </div>
          <div class="flex-row-center f-between mt16 ml10 mr10">
            <div
              v-if="register"
              style="text-align: center"
            >
              <span
                style="color: #194BFB;cursor: pointer;font-size: 14px;"
                @click="gotoRegister"
              >{{ $t('loginNew.registerAccount') }}</span>
            </div>
            <span
              v-if="loginForm.loginType === 'username'"
              style="color: rgba(0,0,0,0.4);cursor: pointer;font-size: 14px;"
              @click="gotoRorgot"
            >{{ $t('loginNew.forgotPassword') }}</span>
          </div>
          <el-button
            :loading="loading"
            class="login-btn"
            style="margin-top: 8px;"
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">{{ $t('login.logIn') }}</span>
            <span v-else>{{ $t('login.logIng') }}</span>
          </el-button>
          <!-- <div v-if="register" style="text-align: center">
            <span style="color: #194BFB;cursor: pointer" @click="quickRegister">{{ $t('login.quickSignUp') }}</span>
          </div> -->
          <el-form-item prop="agreed">
            <div style="display: flex; align-items: center; flex-wrap: wrap;">
              <input
                type="checkbox"
                v-model="loginForm.agreed"
              />
              <span style="margin-left: 8px;">
                {{$t('agreed.readAndAgree')}}
                <el-link
                  type="primary"
                  :underline="false"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/service"
                  target="_blank"
                >{{$t('agreed.userAgreement')}}</el-link>
                {{$t('agreed.and')}}
                <el-link
                  type="primary"
                  :underline="false"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/privacy"
                  target="_blank"
                >{{$t('agreed.privacyPolicy')}}</el-link>
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 2022-2025 Infonity AI </span>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { decrypt, encrypt } from "@/utils/jsencrypt";
import { sendEmilCode, sendMyPhone } from "@/api/base/resource";
import { createFreeAccount } from "../api/system/user";

export default {
  name: "Login",
  components: { LangSelect },
  data() {
    return {
      isOnLoad: false, // 图片是否加载完成
      isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
      content: this.$t("login.getEmailCode"),
      phoneContent: this.$t("login.getSMSCode"),
      codeUrl: "",
      sendSmsForm: {
        imgCode: "",
        uuid: "",
      },
      loginForm: {
        loginType: "username",
        email: "",
        username: "",
        password: "",
        rM: true,
        agreed: true,
        code: "",
        uuid: "",
      },
      loginRules: {
        agreed: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t("agreed.prompt")));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipEmail"),
          },
          {
            pattern:
              /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: this.$t("login.tipCorrectEmail"),
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipPhone"),
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: this.$t("login.tipCorrectPhone"),
            trigger: ["blur", "change"],
          },
        ],
        username: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipAccount"),
          },
        ],
        password: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipPassword"),
          },
        ],
        code: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipCode"),
          },
        ],
        imgCode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipCode"),
          },
        ],
      },
      loading: false,
      emailLoading: false,
      phoneLoading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: true,
      redirect: undefined,
      usernameInputName: "usernameInput",
      passwordInputName: "passwordInput",
      autocompleteOff: "new-password",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
    //切换登录类型时，清空必填项提示
    "loginForm.loginType": function (newType) {
      // 更新输入字段名称以避免浏览器自动填充
      this.usernameInputName =
        newType === "username" ? "usernameInput" : `usernameInput-${newType}`;
      this.passwordInputName =
        newType === "username" ? "passwordInput" : `passwordInput-${newType}`;
      this.$nextTick(() => {
        this.$refs.loginForm.clearValidate();
      });
    },
  },
  created() {
    // 判断是否为手机浏览器
    if (this.isMobile()) {
      // 如果是手机浏览器，跳转到跨域地址
      this.redirectToMobileSite();
    } else {
      this.getCode();
      this.getCookie();
    }
  },
  mounted() {
    // DOM 渲染（$nextTick）：Vue 会先渲染和更新 DOM，然后才会执行 this.$nextTick() 中的回调。也就是说，当 this.$nextTick() 执行时，DOM 已经完成更新。
    // 图片加载（@load）：@load 事件是在图片加载完成后触发的，它是在浏览器加载图片资源时发生的。浏览器会先加载图片，然后触发 @load 事件。
    // 所以，img 加载完毕会比 DOM 渲染和 Vue 更新（$nextTick）要晚，因为图片加载是一个独立的过程，和 DOM 渲染的过程并不完全同步。
    // dom加载完毕之后，展示文字
    // this.$nextTick(() => {
    //   this.isOnLoad = true;
    // });
  },
  methods: {
    onImageLoad() {
      // 图片加载完成后，设置isOnLoad为true
      this.isOnLoad = true;
    },
    isMobile() {
      const userAgent = navigator.userAgent;
      // 判断是否包含手机浏览器的标志字符串
      return /iPhone|iPad|iPod|Android/i.test(userAgent);
    },
    redirectToMobileSite() {
      // 这里可以替换为你的跨域网址
      window.location.href = process.env.VUE_APP_PHONE_ADDRESS; // 替换为目标跨域地址
    },
    //快速注册
    // quickRegister() {
    //   createFreeAccount().then((res) => {
    //     this.$confirm(
    //       this.$t("quickSignUp.rememberCredentials") +
    //         this.$t("quickSignUp.accountLabel") +
    //         res.data.username +
    //         this.$t("quickSignUp.passwordLabel") +
    //         res.data.password,
    //       this.$t("quickSignUp.registrationSuccess"),
    //       {
    //         confirmButtonText: this.$t("quickSignUp.autoFill"),
    //         cancelButtonText: this.$t("cancel"),
    //         type: "success",
    //         dangerouslyUseHTMLString: true, // 启用 HTML 渲染
    //       }
    //     ).then(() => {
    //       this.loginForm.loginType = "username";
    //       this.loginForm.username = res.data.username;
    //       this.loginForm.password = res.data.password;
    //     });
    //   });
    // },
    // 发送邮箱验证码
    sendEmail() {
      if (this.loginForm.email === "") {
        this.$refs.loginForm.validateField("email");
        return;
      } else {
        if (
          !/^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            this.loginForm.email
          )
        ) {
          this.$refs.loginForm.validateField("email");
          return;
        } else if (this.loginForm.imgCode == null) {
          this.$refs.loginForm.validateField("imgCode");
          return;
        }
      }
      let vm = this;
      vm.content = "";
      this.emailLoading = true;
      // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
      sendEmilCode({
        email: vm.loginForm.email,
        uuid: vm.loginForm.uuid,
        code: vm.loginForm.imgCode,
      })
        .then((res) => {
          if (res.code === 200) {
            this.emailLoading = false;
            vm.isDisabled = false;
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.content = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true;
                // 计时秒数
                vm.count--;
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t("login.later");
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false;
                // 更新按钮文字内容
                vm.content = this.$t("login.getEmailCode");
                // 清空定时器!!!
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.emailLoading = false;
          vm.content = this.$t("login.getEmailCode");
          this.getCode();
        });
    },
    // 发送手机验证码
    sendPhone() {
      if (this.loginForm.phone === "") {
        this.$refs.loginForm.validateField("phone");
        return;
      }
      if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.loginForm.phone)) {
        this.$refs.loginForm.validateField("phone");
        return;
      }
      if (this.loginForm.imgCode == null) {
        this.$refs.loginForm.validateField("imgCode");
        return;
      }
      let vm = this;
      vm.phoneContent = "";
      this.phoneLoading = true;
      // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
      sendMyPhone({
        phone: vm.loginForm.phone,
        uuid: vm.loginForm.uuid,
        code: vm.loginForm.imgCode,
      })
        .then((res) => {
          if (res.code === 200) {
            this.phoneLoading = false;
            vm.isDisabled = false;
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.phoneContent = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true;
                // 计时秒数
                vm.count--;
                // 更新按钮的文字内容
                vm.phoneContent = vm.count + this.$t("login.later");
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false;
                // 更新按钮文字内容
                vm.phoneContent = this.$t("login.getSMSCode");
                // 清空定时器!!!
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.phoneLoading = false;
          vm.phoneContent = this.$t("login.getSMSCode");
          this.getCode();
        });
    },
    changeLoginType(type) {
      this.loginForm.loginType = type;
    },
    getCode() {
      getCodeImg().then((res) => {
        this.captchaEnabled =
          res.data.captchaEnabled === undefined
            ? true
            : res.data.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.loginForm.uuid = res.data.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rM = Cookies.get("rM");
      this.loginForm = {
        loginType: "username",
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rM: rM === undefined ? false : Boolean(rM),
        agreed: true, // 默认勾选
      };
    },
    gotoRegister() {
      this.$router.push("/register");
    },
    gotoRorgot() {
      this.$router.push("/forgotPassword");
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rM) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), {
              expires: 30,
            });
            Cookies.set("rM", this.loginForm.rM, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rM");
          }
          let type;
          if (this.loginForm.loginType === "username") type = "Login";
          else if (this.loginForm.loginType === "email") type = "EmailLogin";
          else if (this.loginForm.loginType === "phone") type = "smsLogin";
          this.$store
            .dispatch(type, this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => {});
            })
            .catch(() => {
              this.loading = false;
              if (this.captchaEnabled) {
                this.getCode();
              }
            });
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.set-language {
  width: 20px;
  height: 20px;
  margin: 18px 24px;
  cursor: pointer;
}
.login-logo {
  height: 36px;
  width: 154px;
  margin: 37px 110px 44px;
}
.title-container {
  height: 48px;
  width: 168px;
  padding-top: 12px;
  .title {
    cursor: pointer;
    height: 24px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    line-height: 24px;
    text-align: left;
    font-style: normal;
  }
  .title-line {
    width: 60px;
    height: 2px;
    background: linear-gradient(90deg, #0090ff 0%, #a200ff 100%);
    border-radius: 1px;
    margin-top: 10px;
  }
}

.login-form {
  padding: 0 110px;

  ::v-deep .el-input--medium .el-input__inner {
    height: 56px;
    line-height: 56px;
    background: #eff6fd;
    border-radius: 14px;
    border: 1px solid #b9c4d5;
  }
  ::v-deep .el-form-item {
    margin-bottom: 32px;
  }
}

.login-code {
  width: 100px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-code-img {
    width: 100px;
    height: 36px;
    border-radius: 6px;
    cursor: pointer;
    vertical-align: middle;
  }
}
@media screen and (max-height: 900px) {
  .set-language {
    width: 20px;
    height: 20px;
    margin: 16px 16px;
    cursor: pointer;
  }
  .login-logo {
    height: 36px;
    width: 154px;
    margin: 5px 65px 44px;
  }

  .login-form {
    padding: 0 65px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 46px;
      line-height: 46px;
      background: #eff6fd;
      border-radius: 14px;
      border: 1px solid #b9c4d5;
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
  }

  .login-code {
    width: 72px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-code-img {
      width: 72px;
      height: 28px;
      border-radius: 6px;
      cursor: pointer;
      vertical-align: middle;
    }
  }
}
@media screen and (max-height: 768px) {
  .set-language {
    width: 20px;
    height: 20px;
    margin: 16px 16px;
    cursor: pointer;
  }
  .login-logo {
    height: 26px;
    width: 111px;
    margin: 0 35px 24px;
  }

  .login-form {
    padding: 0 26px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 40px;
      line-height: 40px;
      background: #eff6fd;
      border-radius: 10px;
      border: 1px solid #b9c4d5;
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
  }
  .login-code {
    width: 72px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-code-img {
      width: 72px;
      height: 28px;
      border-radius: 6px;
      cursor: pointer;
      vertical-align: middle;
    }
  }
}
</style>
