<!-- 模板编辑 -->
<template>
  <div class="template-card">
    <div class="steps flex-column">
      <div  v-for="(step, i) in item.details" :key="i" class="w-100 flex-row mt5">
        <span class="step f-shrink">{{ step.label }}</span>
        <el-input type="textarea"  maxlength="200" v-model="step.value" @input="updateStep(i, step.value)" class="f-grow"/>
      </div>
    </div>
    <!-- <span slot="footer" class="flex-center mt10">
      <el-button type="primary" @click="$emit('change-Template',item)">{{$t('template.changeTemplate')}}</el-button>
    </span> -->
  </div>
</template>
<script>

export default {
  name: 'templateItem',
  components: {  },
  props: {
    template: {
      type: Object,
      default: () => ({})
  }
  },
  data () {
    return {
      item: this.template,
    }
  },
  watch: {
    template: {
      handler(val) {
        this.item = val;
      },
      deep: true
    }
  },
  methods: {
    updateStep(index, value) {
      this.item.details[index].value = value;
      this.$emit('update-template', { ...this.item });
    },
  }
}
</script>
<style lang="scss" scoped>
.template-card {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}
.steps {
  width: 100%;
  color: blue;
}
.step {
  padding: 3px 6px 3px 0;
  // border:1px solid blue;
  // border-radius: 5px;
}

.dialog-footer {
  text-align: center;
}
</style>
