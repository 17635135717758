<template>
  <div class="flex-column">
    <!-- <div class="videoTitle">{{ $t('subtitle.subtitle') }}</div> -->
    <!-- 字幕开关 -->
    <el-switch
      v-model="localOpenSubtitle"
      :active-text="$t('subtitle.open')"
      :inactive-text="$t('subtitle.close')"
      style="margin: 10px;"
      @change="switchUpdated"
    ></el-switch>
    <div
      v-if="localOpenSubtitle"
      class="flex-row"
    >
      <el-form
        :model="settings"
        :rules="rules"
        ref="subtitleForm"
        label-width="120px"
        label-position="top"
        class="f-grow"
      >
        <!-- 字幕文本 -->
        <el-form-item
          prop="content"
          v-loading="loading"
        >
          <!-- <template #label>
            <div class="label-container">
              <span class="required-star">*</span>
              <span>{{ $t('subtitle.content') }}</span>
              <el-button type="primary" @click="importText" class="import-button">
                {{ $t('basicInfo.materialImport') }}
              </el-button>
            </div>
          </template> -->

          <div class="flex-row-center f-between">

            <div
              class="label"
              style="font-weight: bold;color: #606266"
            >
              <span class="required-star">*</span>
              {{ $t('subtitle.content') }}
            </div>
            <el-button
              type="text"
              @click="importText"
            >{{ $t('basicInfo.materialImport') }}</el-button>
          </div>
          <el-input
            v-model="settings.content"
            type="textarea"
            maxlength="1000"
            show-word-limit
            :placeholder="$t('subtitle.tipContent')"
            :rows="4"
            @input="contentChange"
          >
          </el-input>
          <div class="flex-row-center mt5">
            <div class="f-grow">
              <el-tooltip :content="$t('subtitle.calculate')">
                <svg-icon
                  icon-class="计算器"
                  style="width: 25px;height: 25px;margin-left: 10px;"
                  @click="calculate"
                />
              </el-tooltip>
              <el-tooltip :content="$t('subtitle.translate')">
                <svg-icon
                  icon-class="翻译"
                  style="width: 25px;height: 25px;margin-left: 10px;"
                  @click="chooseLanguage"
                />
              </el-tooltip>
              <el-tooltip :content="$t('subtitle.sumup')">
                <svg-icon
                  icon-class="ai_sum_up"
                  style="width: 25px;height: 25px;margin-left: 10px;"
                  @click="rewrite('zj')"
                />
              </el-tooltip>
              <el-tooltip :content="$t('subtitle.abbreviation')">
                <svg-icon
                  icon-class="ai_abbreviation"
                  style="width: 25px;height: 25px;margin-left: 10px;"
                  @click="rewrite('sx')"
                />
              </el-tooltip>
              <el-tooltip :content="$t('subtitle.expand')">
                <svg-icon
                  icon-class="ai_expand"
                  style="width: 25px;height: 25px;margin-left: 10px;"
                  @click="rewrite('kx')"
                />
              </el-tooltip>
              <el-tooltip :content="$t('subtitle.rewrite')">
                <svg-icon
                  icon-class="ai_rewrite"
                  style="width: 25px;height: 25px;margin-left: 10px;"
                  @click="rewrite('cx')"
                />
              </el-tooltip>
            </div>
            <!-- <el-button @click="importText" class="f-shrink">{{ $t('basicInfo.materialImport') }}</el-button> -->
          </div>
          <div v-if="total_seconds">
            <div
              v-if="total_seconds > videoDuration"
              style="color: red;"
            >
              {{$t('subtitle.tipCalculate1')}}{{ total_seconds }} {{$t('subtitle.tipCalculate3')}}
            </div>
            <div
              v-else
              style="color: green;"
            >
              {{$t('subtitle.tipCalculate1')}}{{ total_seconds }} {{$t('subtitle.tipCalculate2')}}
            </div>
          </div>
        </el-form-item>
        <!-- 字幕语言 -->
        <el-alert
          :title="$t('subtitle.fitTip')"
          type="warning"
          show-icon
          :closable="false"
        ></el-alert>
        <el-form-item
          :label="$t('subtitle.language')"
          prop="languageCode"
        >
          <el-select
            v-model="settings.languageCode"
            :placeholder="$t('tip.select')"
            filterable
            :default-first-option="true"
          >
            <el-option
              v-for="dict in azureVoiceList"
              :key="dict.name"
              :label="dict.name"
              :value="dict.name"
            />
          </el-select>
        </el-form-item>
        <!-- 是否开启双字幕 -->
        <el-form-item
          :label="$t('subtitle.enableDualSubtitles')"
          prop="isDoubleLanguage"
        >
          <el-switch
            v-model="settings.isDoubleLanguage"
            :active-text="$t('subtitle.open')"
            :inactive-text="$t('subtitle.close')"
            style="margin: 10px;"
            @change="handleItemUpdated"
          ></el-switch>
        </el-form-item>
        <el-form-item
          v-if="settings.isDoubleLanguage"
          :label="$t('subtitle.secondSubtitleLanguage')"
          prop="languageSecond"
        >
          <el-select
            v-model="settings.languageSecond"
            :placeholder="$t('tip.select')"
            filterable
            :default-first-option="true"
            @change="languageSecondChange"
          >
            <el-option
              v-for="dict in languageList"
              :key="dict.bdCode"
              :label="dict.language"
              :value="dict.bdCode"
            />
          </el-select>
        </el-form-item>
        <div
          v-for="(effect, index) in settings.effects"
          :key="`asr-${index}`"
        >
          <template v-if="effect.type === 'AI_ASR'">
            <!-- 字幕字体 -->
            <el-form-item
              :label="$t('subtitle.font')"
              :prop="'effects.' + index + '.font'"
            >
              <el-select
                v-model="effect.font"
                filterable
                :placeholder="$t('subtitle.tipFont')"
                :default-first-option="true"
                @change="handleItemUpdated"
              >
                <el-option
                  v-for="dict in dict.type.font_typeface"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>

            <!-- 字幕字号 -->
            <!-- <el-form-item label="字幕字号" :prop="'effects.' + index + '.fontSize'">
              <el-input-number v-model="effect.fontSize" :min="10" :max="100" @change="logoChange(effect)"></el-input-number>
            </el-form-item> -->
            <el-form-item
              :label="$t('subtitle.fontSizeType')"
              :prop="'effects.' + index + '.fontSizeType'"
            >
              <el-radio-group
                v-model="effect.fontSizeType"
                @input="fontSizeChange(effect)"
              >
                <el-radio label="small">{{ $t('subtitle.small') }}</el-radio>
                <el-radio label="normal">{{ $t('subtitle.normal') }}</el-radio>
                <el-radio label="big">{{ $t('subtitle.big') }}</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- 字幕字间距 -->
            <el-form-item
              :label="$t('subtitle.spacing')"
              :prop="'effects.' + index + '.spacing'"
            >
              <el-input-number
                v-model="effect.spacing"
                :min="0"
                :max="20"
                @change="handleItemUpdated"
              ></el-input-number>
            </el-form-item>

            <!-- 字幕颜色 -->
            <el-form-item
              :label="$t('subtitle.fontColor')"
              :prop="'effects.' + index + '.fontColor'"
            >
              <el-color-picker
                v-model="effect.fontColor"
                @change="handleItemUpdated"
              ></el-color-picker>
            </el-form-item>

            <!-- 描边宽度 -->
            <el-form-item
              :label="$t('subtitle.outline')"
              :prop="'effects.' + index + '.outline'"
            >
              <el-input-number
                v-model="effect.outline"
                :min="0"
                :max="10"
                @change="handleItemUpdated"
              ></el-input-number>
            </el-form-item>

            <!-- 描边颜色 -->
            <el-form-item
              :label="$t('subtitle.outlineColour')"
              :prop="'effects.' + index + '.outlineColour'"
            >
              <el-color-picker
                v-model="effect.outlineColour"
                @change="handleItemUpdated"
              ></el-color-picker>
            </el-form-item>
            <!-- 字幕位置 -->
            <el-form-item
              :label="$t('subtitle.position')"
              :prop="'effects.' + index + '.position'"
            >
              <el-radio-group
                v-model="effect.position"
                @input="logoChange(effect)"
              >
                <el-radio label="top">{{ $t('subtitle.top') }}</el-radio>
                <el-radio label="center">{{ $t('subtitle.center') }}</el-radio>
                <el-radio label="bottom">{{ $t('subtitle.bottom') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 字幕位置 -->
            <!-- <el-form-item label="对应Y坐标" :prop="'effects.' + index + '.y'">
              <el-input-number v-model="effect.y" :min="0" :max="450" @change="handleItemUpdated"></el-input-number>
            </el-form-item> -->

            <!-- 字幕透明度 -->
            <!-- <el-form-item label="字幕透明度" prop="opacity">
              <el-slider v-model="effect.opacity" :max="100" :min="0" show-input></el-slider>
            </el-form-item> -->

            <!-- 字幕样式 -->
            <el-form-item
              :label="$t('subtitle.fontFaces')"
              :prop="'effects.' + index + '.fontFaces'"
            >
              <el-checkbox-group
                v-model="effect.fontFaces"
                @change="changeFontFace"
              >
                <el-checkbox label="Italic">{{ $t('subtitle.italic') }}</el-checkbox>
                <el-checkbox label="Bold">{{ $t('subtitle.blod') }}</el-checkbox>
                <el-checkbox label="Underline">{{ $t('subtitle.underline') }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <!-- 花字效果 -->
            <el-form-item
              :label="$t('subtitle.effectColorStyle')"
              :prop="'effects.' + index + '.effectColorStyle'"
            >
              <div class="flex-row-center f-between">
                <el-switch
                  v-model="effect.openEffectColorStyle"
                  style="margin: 10px;"
                  @change="switchEffectUpdated"
                ></el-switch>
                <el-button
                  type="text"
                  v-if="effect.openEffectColorStyle"
                  @click="isExpansion = !isExpansion"
                >{{ isExpansion ? $t('collapse') : $t('expansion') }}</el-button>
              </div>

              <div
                class="image-container"
                v-if="effect.openEffectColorStyle"
              >
                <img
                  v-for="(item, in2) in styleList"
                  :key="in2"
                  :src="item.imgUrl"
                  :class="{ selected: effect.effectColorStyle === item.effectColorStyle }"
                  @click="selectEffectItem(item)"
                />
              </div>
            </el-form-item>
          </template>
        </div>
        <div class="videoTitle flex-row-center">
          {{ $t('subtitle.dubbingSettings') }}
          <el-switch
            v-model="settings.dubbing"
            style="margin: 10px;"
          ></el-switch>
        </div>
        <!-- 配音音色 -->
        <div v-if="settings.dubbing">
          <el-form-item
            :label="$t('subtitle.voice')"
            prop="voice"
          >
            <el-select
              v-model="settings.voice"
              :no-data-text="$t('subtitle.tipLanguageFirst')"
              :placeholder="$t('subtitle.tipVoice')"
              @change="handleItemUpdated"
              @visible-change="closeAudio"
            >
              <el-option
                v-for="voice in filteredVoiceList"
                :key="voice.shortName"
                :label="voice.localName"
                :value="voice.shortName"
              >
                <span style="float: left">{{ voice.localName }}</span>
                <el-button
                  v-if="voice.url"
                  style="float: left;margin-left: 10px"
                  type="text"
                  icon="el-icon-video-play"
                  @click.stop="playAudio(voice.url)"
                ></el-button>
              </el-option>
            </el-select>
          </el-form-item>
          <div
            v-for="(effect, index) in settings.effects"
            :key="`volume-${index}`"
          >
            <template v-if="effect.type === 'Volume'">
              <!-- 配音音量 -->
              <el-form-item
                :label="$t('subtitle.gain')"
                :prop="'effects.' + index + '.gain'"
              >
                <el-slider
                  :step="0.1"
                  v-model="effect.gain"
                  :max="10"
                  :min="0"
                  show-input
                  @change="handleItemUpdated"
                ></el-slider>
              </el-form-item>
            </template>
          </div>
        </div>

      </el-form>

      <!-- 实时预览 -->
      <!-- <div style="width: 253px;margin-left: 10px;">
        {{ $t('subtitle.preview') }}
        <div class="preview" :style="previewStyle">
          <p :style="subtitlePreviewStyle">{{ settings.content }}</p>
        </div>
      </div> -->
    </div>

    <!-- 配音开关 -->
    <!-- <el-switch v-model="localOpenSubtitle" active-text="开启配音" inactive-text="关闭配音"
    style="margin: 10px;"></el-switch>
    <div v-if="localOpenSubtitle">
      <el-form :model="settings" label-width="120px"> -->
    <!-- 配音语言 -->
    <!-- <el-form-item label="配音语言" prop="language">
      <el-select v-model="settings.language" placeholder="选择语言">
        <el-option label="中文" value="zh"></el-option>
        <el-option label="英文" value="en"></el-option>
      </el-select>
    </el-form-item> -->

    <!-- 配音性别 -->
    <!-- <el-form-item label="配音性别" prop="gender">
      <el-radio-group v-model="settings.gender">
        <el-radio label="男声">男声</el-radio>
        <el-radio label="女声">女声</el-radio>
      </el-radio-group>
    </el-form-item> -->
    <!-- </el-form>
  </div> -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('issueItem.translateTo')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50%"
    >
      <el-select
        v-model="language"
        :placeholder="$t('issueItem.translateLanguage')"
        filterable
        clearable
        style="width: 100%;"
        :default-first-option="true"
      >
        <el-option
          v-for="dict in languageList"
          :key="dict.bdCode"
          :label="dict.language"
          :value="dict.bdCode"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleCancel">{{ $t('cancel') }}</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="handleConfirm"
        >{{ $t('confirm') }}</el-button>
      </span>
    </el-dialog>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawer"
      @selectionConfirmed="selectionConfirmed"
    />
  </div>
</template>

<script>
import { getAzureVoice, getLanguages } from "../../api/ai/ali";
import { aiTtsDuration, rewrite, translate } from "../../api/ai/language";
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";

export default {
  //props: ["value", "backgroundImageUrl", "effectColorStyleList"],
  dicts: ["font_typeface"],
  components: {
    MaterialDrawer,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}), // 默认值为空对象
    },
    openSubtitle: {
      type: Boolean,
      default: false,
    },
    backgroundImageUrl: String,
    videoDuration: {
      type: Number,
      default: 0,
    },
    effectColorStyleList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // 根据名称筛选部门树
    value(val) {
      console.log("watch value:", this.value);
      this.watchValue();
      this.$forceUpdate();
      //this.handleItemUpdated()
    },
    openSubtitle(val) {
      this.localOpenSubtitle = val;
    },
    subtitlePreviewStyle(newStyle) {
      this.$emit("update-subtitle-style", newStyle);
    },
    "settings.voice": function (newValue) {
      const selectedVoice = this.filteredVoiceList.find(
        (voice) => voice.shortName === newValue
      );
      console.log("selectedVoice", selectedVoice);
      // 将 settings.language 设置为匹配项的 locale
      if (selectedVoice) {
        this.settings.language = selectedVoice.locale;
        this.settings.languageFirstBdCode = selectedVoice.bdCode;
        this.isSomeLanguage();
      } else {
        this.settings.language = null; // 若未找到，清空 language
        this.settings.languageFirstBdCode = null;
      }
      this.handleItemUpdated();
    },
    "settings.languageCode": function (newValue) {
      if (
        this.settings.languageCode &&
        this.azureVoiceList &&
        this.azureVoiceList.length > 0
      ) {
        // this.settings.voice = this.voiceTypes[0].shortName
        const selectedGroup = this.azureVoiceList.find(
          (group) => group.name === this.settings.languageCode
        );
        console.log("selectedGroup", selectedGroup);
        // 如果 `list` 存在且不为空，将 `selectedVoice` 设置为 `list[0]` 的 `localName`
        if (selectedGroup && selectedGroup.list.length > 0) {
          this.settings.voice = selectedGroup.list[0].shortName;
          this.settings.language = selectedGroup.list[0].locale;
          this.settings.languageFirstBdCode = selectedGroup.list[0].bdCode;
          this.isSomeLanguage();
        }
        console.log("this.settings.voice", this.settings.voice);
        console.log("this.settings.language", this.settings.language);
      }
      this.handleItemUpdated();
    },
  },
  mounted() {
    this.$nextTick(() => {
      //console.log('子组件 mounted 后的 value:', this.value);
    });
  },
  updated() {
    this.$nextTick(() => {
      //  console.log('子组件 updated 后的 value:', this.value);
    });
  },
  data() {
    return {
      settings: {
        languageCode: "", //语言——取自name——仅前端
        languageFirstBdCode: "", //语言——取自bdCode——判断第二语种是否相同时使用
        language: "", //语言——取自locale
        voice: "", //音色——取自shortName
        isDoubleLanguage: false, //是否双语言
        // languageCodeSecond: "", //第二语言——取自name——仅前端
        languageSecond: "", //第二语言——取自locale
        type: "AI_TTS",
        content: "", //文本
        effects: [
          {
            fontFaces: [],
            fontFace: {
              Italic: false,
              Underline: false,
              Bold: false,
            },
            type: "AI_ASR",
            spacing: 0, //字间距
            // lineSpacing: 10,//行间距
            outline: 0, //描边宽度
            outlineColour: "#ffffff", //描边颜色
            y: 0.9,
            fontSize: 53, //字体大小
            fontSizeType: "normal",
            alignment: "BottomCenter",
            position: "bottom",
            fontColor: "#ffffff", //字体颜色
            font: "SimSun", //字体
            adaptMode: "AutoWrap",
            openEffectColorStyle: false,
            effectColorStyle: "", //花字
            // backColour:'',//花字中的背景色
          },
          {
            type: "Volume",
            gain: 1, //声音大小（0-10）
          },
        ],
        ...this.value,
      },
      localOpenSubtitle: this.openSubtitle,
      rules: {
        content: [
          {
            required: true,
            message: this.$t("subtitle.tipContent"),
            trigger: ["blur", "change"],
          },
        ],
        "effects.0.font": [
          {
            required: true,
            message: this.$t("subtitle.tipFont"),
            trigger: ["blur", "change"],
          },
        ],
        "effects.0.fontSizeType": [
          {
            required: true,
            message: this.$t("subtitle.tipFontSizeType"),
            trigger: ["blur", "change"],
          },
        ],
        "effects.0.fontColor": [
          {
            required: true,
            message: this.$t("subtitle.tipFontColor"),
            trigger: ["blur", "change"],
          },
        ],
        // outline: [
        //   { required: true, message: '请输入描边宽度', trigger: 'blur' }
        // ],
        // outlineColour: [
        //   { required: true, message: '请选择描边颜色', trigger: 'blur' }
        // ],
        languageCode: [
          {
            required: true,
            message: this.$t("subtitle.tipLanguage"),
            trigger: ["blur", "change"],
          },
        ],
        languageSecond: [
          {
            required: true,
            message: this.$t("subtitle.tipLanguageSecond"),
            trigger: ["blur", "change"],
          },
        ],
        voice: [
          {
            required: true,
            message: this.$t("subtitle.tipVoice"),
            trigger: ["blur", "change"],
          },
        ],
      },
      dialogVisible: false, //选择翻译语言
      language: "en", //需要翻译成的语言
      loading: false, //选择翻译语言的确定按钮
      drawer: false, //素材选择的抽屉
      total_seconds: 0,
      isExpansion: false, //是否展开花字
      languageList: [], //翻译语言列表
      azureVoiceList: [], //音色列表-微软
      // languageMap: {
      //   it: "意大利语",
      //   spa: "西班牙语",
      //   ru: "俄语",
      //   kor: "韩语",
      //   vie: "越南语",
      //   jp: "日语",
      //   id: "印尼语",
      //   may: "马来语",
      //   fil: "菲律宾语",
      //   en: ["英文", "通用"],
      //   zh: ["中文", "中英", "通用"],
      //   th: "泰语",
      //   yue: "粤语",
      //   de: "德语",
      //   fra: "法语",
      // },//语言对应筛选音色的关系
      currentAudio: null, // 当前播放的音频实例
      // languagePatterns: {
      //   it: /[àèìòù]/g, // 意大利语特征字符
      //   spa: /[ñáéíóú]/g, // 西班牙语特征字符
      //   ru: /[а-яё]/g, // 俄语特征字符
      //   kor: /[가-힣]/g, // 韩语特征字符
      //   vie: /[ờảầ]/g, // 越南语特征字符
      //   jp: /[あ-んア-ン]/g, // 日语特征字符
      //   id: /[ng]/g, // 印尼语特征字符
      //   may: /[ku]/g, // 马来语特征字符
      //   fil: /[ng]/g, // 菲律宾语特征字符
      //   en: /[a-zA-Z]/g, // 英文特征字符
      //   zh: /[一-龥]/g, // 中文特征字符
      //   th: /[ก-๙]/g, // 泰语特征字符
      //   // yue: /[一-龥]/g, // 粤语特征字符,和中文相同，无法通过正则区分，自动识别以中文优先
      //   de: /[äöüß]/g, // 德语特征字符
      //   fra: /[àâçéèêëîïôûùüÿñæœ]/g, // 法语特征字符
      // },
    };
  },
  computed: {
    subtitlePreviewStyle() {
      const settings = this.settings || {};
      const effect_asr =
        (this.settings.effects &&
          this.settings.effects.find((effect) => effect.type === "AI_ASR")) ||
        {};
      const maxTop = 450;
      const lines =
        effect_asr.position === "top"
          ? 12
          : effect_asr.position === "center"
          ? 6
          : 1;

      const style = {
        fontFamily: effect_asr.font || "SimSun",
        fontSize: `${effect_asr.fontSize / 2.5 || 16}px`,
        letterSpacing: `${effect_asr.spacing || 0}px`,
        color: effect_asr.fontColor || "#ffffff",
        textShadow: `${effect_asr.outline || 0}px ${
          effect_asr.outline || 0
        }px ${effect_asr.outlineColour || "#000000"}`,
        // opacity: (settings.opacity || 100) / 100,
        fontStyle: (effect_asr.fontFaces || []).includes("Italic")
          ? "italic"
          : "normal",
        fontWeight: (effect_asr.fontFaces || []).includes("Bold")
          ? "bold"
          : "normal",
        textDecoration: (effect_asr.fontFaces || []).includes("Underline")
          ? "underline"
          : "none",
        position: "absolute",
        top: `${Math.min(effect_asr.y * 450, maxTop)}px`,
        textAlign: "center",
        left: "50%",
        transform: "translateX(-50%)",
        overflow: "hidden", // 隐藏超出范围的文本
        textOverflow: "ellipsis", // 超出部分显示省略号
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": lines,
        padding: "0 10px", // 调整左右边距
        width: "250px",
      };
      // this.$emit('update-subtitle-style', style);
      return style;
    },
    previewStyle() {
      return {
        backgroundImage: `url(${this.backgroundImageUrl})`,
        backgroundSize:
          "contain" /* 将背景图片调整到足够大，以使其在背景区域内完全显示 */,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat" /* 防止背景图片重复 */,
      };
    },
    styleList() {
      return this.isExpansion
        ? this.effectColorStyleList
        : this.effectColorStyleList.slice(0, 6);
    },
    // 根据选定的语言来筛出音色列表
    filteredVoiceList() {
      const selectedGroup = this.azureVoiceList.find(
        (group) => group.name === this.settings.languageCode
      );
      return selectedGroup ? selectedGroup.list : [];
    },
  },
  created() {
    this.watchValue();
    this.getLanguageList();
    this.getAzureVoiceList();
  },
  methods: {
    // 根据bdCode赋值语种、音色
    findVoiceData(bdCode) {
      for (const group of this.azureVoiceList) {
        const matchedVoice = group.list.find((item) => item.bdCode === bdCode);
        if (matchedVoice) {
          this.settings.languageCode = group.name;
          this.settings.voice = matchedVoice.shortName;
          this.settings.language = matchedVoice.locale;
          this.settings.languageFirstBdCode = matchedVoice.bdCode;
          this.isSomeLanguage();
        }
      }
    },
    // 翻译语种列表
    getLanguageList() {
      getLanguages().then((response) => {
        const newList = response.data.map((item) => {
          return { ...item, select: false };
        });
        const oftenZero = newList.filter((item) => item.isOften === 1);
        const others = newList.filter((item) => item.isOften !== 1);
        this.languageList = oftenZero.concat(others);
        console.log("this.languageList", this.languageList);
      });
    },
    // 音色列表
    getAzureVoiceList() {
      getAzureVoice().then((response) => {
        this.azureVoiceList = response.data;
      });
    },

    languageSecondChange() {
      if (this.isSomeLanguage()) {
        this.$modal.msgError(this.$t('subtitle.someLanguageTip'));
      }
      this.handleItemUpdated();
    },
    isSomeLanguage() {
      if (this.settings.languageSecond === this.settings.languageFirstBdCode) {
        this.settings.languageSecond = "";
        return true;
      }
      return false;
    },
    contentChange() {
      // if(this.settings.content){
      //   const la = this.detectLanguage(this.settings.content)
      //   console.log(la)
      //   if(la){
      //     this.settings.languageCode = la
      //   }
      // }
      this.handleItemUpdated();
    },
    // 根据文本检测语言
    // detectLanguage(text) {
    //   if(!text){
    //     return ''
    //   }
    //   const totalLength = text.length;
    //   const languageCounts = {};

    //   for (const [lang, pattern] of Object.entries(this.languagePatterns)) {
    //     const matches = text.match(pattern);
    //     languageCounts[lang] = matches ? matches.length : 0;
    //   }

    //   for (const [lang, count] of Object.entries(languageCounts)) {
    //     const percentage = (count / totalLength) * 100;
    //     console.log(`${lang}: ${percentage}%`);
    //     if (percentage > 51) {
    //         return lang; // 返回识别出的语言
    //     }
    //   }
    //   return ''; // 如果没有找到
    // },
    watchValue() {
      this.settings = {
        dubbing: true,
        voice: "", //音色
        type: "AI_TTS",
        content: "", //文本
        effects: [
          {
            fontFaces: [],
            fontFace: {
              Italic: false,
              Underline: false,
              Bold: false,
            },
            type: "AI_ASR",
            spacing: 0, //字间距
            // lineSpacing: 10,//行间距
            outline: 0, //描边宽度
            outlineColour: "#ffffff", //描边颜色
            y: 0.9,
            fontSize: 53, //字体大小
            fontSizeType: "normal",
            alignment: "BottomCenter",
            position: "bottom",
            fontColor: "#ffffff", //字体颜色
            font: "SimSun", //字体
            adaptMode: "AutoWrap",
            openEffectColorStyle: false,
            effectColorStyle: "", //花字
            // backColour:'',//花字中的背景色
          },
          {
            type: "Volume",
            gain: 1, //声音大小（0-10）
          },
        ],
        // ...this.settings,
        ...this.value,
      };
      if (this.settings.effects) {
        let index = this.settings.effects.findIndex(
          (item) => item.type === "AI_ASR"
        );
        if (index > -1) {
          let fontFace = this.settings.effects[index].fontFace;
          this.settings.effects[index].fontFaces = Object.keys(fontFace).filter(
            (key) => fontFace[key]
          );
        }
        let position = this.settings.effects.findIndex(
          (item) => item.type === "Volume"
        );
        if (position > -1) {
          this.settings.effects[position].gain = Number(
            this.settings.effects[position].gain
          );
        }
      }
    },
    //导入文案素材
    importText() {
      this.drawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(4);
      });
    },
    selectionConfirmed(items) {
      if (items.length == 1) {
        this.settings.content = items[0].content;
        this.contentChange();
        this.handleItemUpdated();
      }
      this.drawer = false;
      this.handleItemUpdated();
    },
    //计算口播时长
    calculate() {
      if (!this.settings.content) {
        this.$modal.msgError(this.$t("subtitle.tipContentFirst"));
        return;
      }
      this.loading = true;
      aiTtsDuration({
        content: this.settings.content,
      })
        .then((response) => {
          if (response.data) {
            this.total_seconds = Number(response.data);
            console.log(this.total_seconds);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //翻译
    chooseLanguage() {
      if (!this.settings.content) {
        this.$modal.msgError(this.$t("subtitle.tipContentFirst"));
        return;
      }
      this.dialogVisible = true;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleConfirm() {
      if (!this.language) {
        this.$modal.msgError(this.$t("issueItem.tipTranslate"));
        return;
      }
      this.loading = true;
      this.findVoiceData(this.language);
      // this.settings.languageCode = this.language;
      translate({
        text: this.settings.content,
        to: this.language,
        from: "auto",
      })
        .then((response) => {
          this.dialogVisible = false;
          if (
            response.data &&
            response.data.trans_result &&
            response.data.trans_result.length > 0
          ) {
            let result = response.data.trans_result[0].dst;
            if (result) {
              this.settings.content = result;
              this.handleItemUpdated();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //重写
    rewrite(type) {
      if (!this.settings.content) {
        this.$modal.msgError(this.$t("subtitle.tipContentFirst"));
        return;
      }
      this.loading = true;
      rewrite({
        content: this.settings.content,
        type: type,
      })
        .then((res) => {
          this.settings.content = res.data;
          this.handleItemUpdated();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //字体
    selectFontItem(item) {
      const asrIndex = this.settings.effects.findIndex(
        (effect) => effect.type === "AI_ASR"
      );
      if (asrIndex !== -1) {
      }
    },
    //选中花字
    selectEffectItem(item) {
      const asrIndex = this.settings.effects.findIndex(
        (effect) => effect.type === "AI_ASR"
      );
      if (asrIndex !== -1) {
        this.settings.effects[asrIndex].effectColorStyle =
          item.effectColorStyle;
        // this.settings.effects[asrIndex].backColour = item.backColour
        // this.settings.effects[asrIndex].outlineColour = item.outlineColour
        this.handleItemUpdated();
      }
    },
    //更新数据到上个页面
    handleItemUpdated() {
      this.$emit("item-updated", this.settings);
    },
    switchUpdated() {
      this.$emit("item-updated-open", this.localOpenSubtitle);
    },
    switchEffectUpdated(val) {
      console.log(this.settings.effects);
      //  const asrIndex = this.settings.effects.findIndex(effect => effect.type === 'AI_ASR');
      //  this.settings.effects[asrIndex].openEffectColorStyle = !this.settings.effects[asrIndex].openEffectColorStyle
      /*  const asrIndex = 0
        if (asrIndex !== -1) {
          const asr = this.settings.effects[asrIndex];
          if (!asr.openEffectColorStyle) {
            this.settings.effects[asrIndex].effectColorStyle = ''
          }*/
      // this.settings.effects[asrIndex].backColour = item.backColour
      // this.settings.effects[asrIndex].outlineColour = item.outlineColour
      // this.handleItemUpdated()
    },
    init(data) {
      this.settings = { ...data };
    },
    playAudio(url) {
      if (this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio = null;
      } else {
        this.currentAudio = new Audio(url); // 创建新的音频实例
        this.currentAudio.play(); // 播放音频
      }
    },
    closeAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio = null; // 清空当前音频实例
      }
    },
    fontSizeChange(effect) {
      if (effect.fontSizeType === "small") {
        effect.fontSize = 37;
      } else if (effect.fontSizeType === "big") {
        effect.fontSize = 70;
      } else {
        effect.fontSize = 53;
      }
      this.handleItemUpdated();
    },
    logoChange(effect) {
      const h = 450;
      switch (effect.position) {
        case "top":
          // effect.y = 20;
          effect.y = 0.1;
          effect.alignment = "TopCenter";
          break;
        case "center":
          // effect.y = (h-effect.fontSize)/2;
          effect.y = 0.5;
          effect.alignment = "CenterCenter";
          break;
        case "bottom":
          // effect.y = h - effect.fontSize - 20;
          effect.y = 0.9;
          effect.alignment = "BottomCenter";
          break;
        default:
          // effect.y = h - effect.fontSize - 20;
          effect.y = 0.9;
          effect.alignment = "BottomCenter";
          break;
      }
      this.handleItemUpdated();
    },
    changeFontFace() {
      const asrIndex = this.settings.effects.findIndex(
        (effect) => effect.type === "AI_ASR"
      );
      if (asrIndex !== -1) {
        let fontFace = {};
        const asr = this.settings.effects[asrIndex];
        if (asr.fontFaces && Array.isArray(asr.fontFaces)) {
          fontFace.Bold = asr.fontFaces.includes("Bold");
          fontFace.Italic = asr.fontFaces.includes("Italic");
          fontFace.Underline = asr.fontFaces.includes("Underline");
        }
        // 更新 AI_ASR 下的 fontFace 字段
        this.settings.effects[asrIndex].fontFace = fontFace;
        this.handleItemUpdated();
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        if (this.localOpenSubtitle) {
          this.$refs["subtitleForm"].validate((valid) => {
            if (valid) {
              console.log("valid-subtitle-1");
              resolve();
            } else {
              console.log("valid-subtitle-2");
              reject(new Error("Validation failed"));
            }
          });
        } else {
          console.log("valid-subtitle-3");
          resolve();
        }
      });
    },
    getTempInfo() {
      return this.settings;
    },
  },
};
</script>

<style scoped>
.preview {
  position: relative;
  width: 253px;
  height: 450px;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
  overflow: hidden; /* 确保内容不会超出预览框 */
}

.selected {
  border: 1px solid blue;
  border-radius: 5px;
}

.image-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 图片间距 */
}

.image-container img {
  width: calc(50% - 5px); /* 两个图片一排，减去间距的一半 */
  box-sizing: border-box; /* 包含 padding 和 border */
  padding: 5px;
}
* {
  max-width: 100%;
  box-sizing: border-box; /* 确保内边距和边框都计算在宽度内 */
}

.label-container {
  display: flex;
  align-items: center;
}

.required-star {
  color: #f56c6c;
  margin-right: 4px;
  font-size: 16px;
  line-height: 1;
}

.import-button {
  margin-left: auto;
}
.el-form-item {
  margin-bottom: 12px;
}
.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
