var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog",
      attrs: {
        width: "500px",
        visible: _vm.localVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "before-close": _vm.handleBeforeClose,
        "destroy-on-close": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "textForm",
          attrs: {
            model: _vm.textForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("copywriting.title"), prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("tip.input"),
                  maxlength: "50",
                  "show-word-limit": "",
                  disabled: _vm.isSystemFile,
                },
                model: {
                  value: _vm.textForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.textForm, "title", $$v)
                  },
                  expression: "textForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("copywriting.content"), prop: "content" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "500",
                  "show-word-limit": "",
                  placeholder: _vm.$t("tip.input"),
                  autosize: { minRows: 2, maxRows: 4 },
                  disabled: _vm.isSystemFile,
                },
                model: {
                  value: _vm.textForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.textForm, "content", $$v)
                  },
                  expression: "textForm.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("material.position"),
                prop: "materialTypeId",
              },
            },
            [
              _c("treeselect", {
                attrs: {
                  options: _vm.materialTypeList,
                  normalizer: _vm.normalizer,
                  "load-options": _vm.loadOptions,
                  clearable: false,
                  placeholder: _vm.$t("file.tipSelectFolder"),
                  disabled: _vm.isSystemFile,
                },
                scopedSlots: _vm._u([
                  {
                    key: "value-label",
                    fn: function (ref) {
                      var node = ref.node
                      return [_vm._v(" " + _vm._s(node.raw.name) + " ")]
                    },
                  },
                ]),
                model: {
                  value: _vm.textForm.materialTypeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.textForm, "materialTypeId", $$v)
                  },
                  expression: "textForm.materialTypeId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("label.label") } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "allow-create": "",
                    filterable: "",
                    multiple: "",
                    placeholder: _vm.$t("label.tipSelect"),
                  },
                  on: { input: _vm.handleInput },
                  model: {
                    value: _vm.textForm.labels,
                    callback: function ($$v) {
                      _vm.$set(_vm.textForm, "labels", $$v)
                    },
                    expression: "textForm.labels",
                  },
                },
                _vm._l(_vm.labelList, function (dict) {
                  return _c("el-option", {
                    key: dict.id,
                    attrs: { label: dict.label, value: dict.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "cancelBtn", on: { click: _vm.close } },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn",
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }