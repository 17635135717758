var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.releaseTime"),
                prop: "publishTimeStr",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "picker-options": _vm.pickerOptions,
                  placeholder: _vm.$t("operation.selectReleaseTime"),
                },
                on: { change: _vm.changeTime },
                model: {
                  value: _vm.dataTime,
                  callback: function ($$v) {
                    _vm.dataTime = $$v
                  },
                  expression: "dataTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("operation.videoTopic"),
                prop: "videoTheme",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "50",
                  placeholder: _vm.$t("operation.videoTopic"),
                  autosize: { minRows: 6, maxRows: 8 },
                },
                model: {
                  value: _vm.form.videoTheme,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "videoTheme", $$v)
                  },
                  expression: "form.videoTheme",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("issueItem.selectVideo"), prop: "video" },
            },
            [
              _c(
                "div",
                { staticClass: "aspect-ratio-box-container" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.videoList.length == 0,
                          expression: "videoList.length==0",
                        },
                      ],
                      staticClass: "aspect-ratio-box flex-column",
                      on: { click: _vm.selectVideo },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticStyle: { "margin-top": "5px" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("issueItem.import")) + " "),
                      ]),
                    ]
                  ),
                  _vm._l(_vm.videoList, function (material, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "aspect-ratio-box flex-column",
                        on: {
                          mouseover: function ($event) {
                            material.hover = true
                          },
                          mouseleave: function ($event) {
                            material.hover = false
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "material-container" }, [
                          _c("video", {
                            staticClass: "material-thumbnail",
                            attrs: { src: material.url },
                          }),
                          material.hover
                            ? _c("div", { staticClass: "overlay" }, [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.playVideo(material.url)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("issueItem.paly")))]
                                ),
                                _c("div", { staticStyle: { margin: "5px" } }, [
                                  _vm._v("|"),
                                ]),
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeMaterial(index)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("issueItem.remove")))]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("issueItem.product"),
            size: "65%",
            visible: _vm.drawer,
            "append-to-body": true,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("generated-video", {
            ref: "generated",
            on: { selectionConfirmed: _vm.selectionConfirmed },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPlay,
            width: "50%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPlay = $event
            },
          },
        },
        [
          _c("video", {
            ref: "videoPlayer",
            staticClass: "video-player",
            attrs: { controls: "", src: _vm.playUrl },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }