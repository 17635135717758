var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "voice-container" },
    [
      _vm.type == 0
        ? _c("el-alert", {
            attrs: {
              title: _vm.$t("editVideo.timbreTip"),
              type: "warning",
              "show-icon": "",
              closable: false,
            },
          })
        : _vm._e(),
      _c("div", {
        staticClass: "flex-row-center",
        staticStyle: { "justify-content": "flex-end" },
      }),
      _c(
        "el-radio-group",
        {
          staticClass: "custom-radio-group",
          on: { input: _vm.selectItem },
          model: {
            value: _vm.item,
            callback: function ($$v) {
              _vm.item = $$v
            },
            expression: "item",
          },
        },
        _vm._l(_vm.voiceList, function (voices, category) {
          return _c("div", { key: category, staticClass: "voice-category" }, [
            _c("div", { staticClass: "category w-100" }, [
              _vm._v(_vm._s(category)),
            ]),
            _c(
              "div",
              { staticClass: "voice-items" },
              _vm._l(voices, function (voice) {
                return _c(
                  "el-radio",
                  {
                    key: voice.id,
                    staticClass: "voice-item",
                    style: { width: _vm.computedWidth },
                    attrs: { label: voice.voice },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(voice.name) +
                        "(" +
                        _vm._s(voice.description) +
                        ") "
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { content: _vm.$t("editVideo.preview") } },
                      [
                        voice.voiceUrl
                          ? _c("i", {
                              staticClass: "el-icon-video-play",
                              staticStyle: { "font-size": "16px" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.play(voice.voiceUrl)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }