<template>
  <div class="chat-input">
    <el-tooltip
      :content="$t('chat.end')"
      v-if="isRecording"
    >
      <el-button
        type="text"
        circle
        icon="el-icon-turn-off-microphone"
        @click="toggleRecording"
        :disabled="disabled"
      >
      </el-button>
    </el-tooltip>
    <span
      v-if="isRecording"
      class="ml5 mr5"
    >
      {{ formattedRecordingTime }}
    </span>
    <el-tooltip
      :content="$t('chat.start')"
      v-else
    >
      <el-button
        type="text"
        circle
        icon="el-icon-microphone"
        @click="toggleRecording"
        :loading="isRecording"
        :disabled="disabled"
      >
      </el-button>
    </el-tooltip>
    <el-tooltip
      :content="$t('chat.cancel')"
      v-if="isRecording"
    >
      <el-button
        type="text"
        icon="el-icon-circle-close"
        @click="cancelRecording"
      >
      </el-button>
    </el-tooltip>
    <!-- type="textarea" -->
    <el-input
      style="width: 95%;"
      v-model="message"
      type="textarea"
      maxlength="200"
      :placeholder="disabled ? $t('ai.workHard') : $t('ai.tipInstruct')+$t('enter')"
      :rows="1"
      :autosize="{ minRows: 1, maxRows: 3}"
      :disabled="disabled || isRecording"
      @keydown.native="handleKeyDown"
    ></el-input>
    <!-- <el-button
      type="primary"
      @click="sendMessage"
      :disabled="disabled || isRecording"
    >
      {{ $t('ai.send') }}
    </el-button> -->
    <div
      class="sendMessage"
      :style="{cursor: disabled || isRecording ? 'not-allowed' : 'pointer'}"
      @click="sendMessage"
    >
      <i class="iconfont icon-telegram-normal"></i>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import Recorder from "recorder-js";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: "",
      isRecording: false,
      mediaRecorder: null,
      recorder: null,
      audioChunks: [],
      mimeType: "",
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/resource/oss/upload", // 上传文件服务器地址
      recordingTime: 0, // 录制的秒数
      recordingTimer: null, // 计时器
    };
  },
  computed: {
    // 格式化录制时间为 mm:ss 格式
    formattedRecordingTime() {
      const minutes = Math.floor(this.recordingTime / 60)
        .toString()
        .padStart(2, "0");
      const seconds = (this.recordingTime % 60).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
  },
  methods: {
    handleKeyDown(event) {
      // Shift + Enter 或 Option + Enter 换行
      if (
        (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) &&
        event.key === "Enter"
      ) {
        // 阻止默认换行行为
        event.preventDefault();
        this.message += "\n";
        const textarea = event.target;
        this.$nextTick(() => {
          // 确保光标位置可见
          textarea.scrollTop = textarea.scrollHeight;
        });
        return;
      }
      // 仅 Enter 发送消息
      else if (event.key === "Enter") {
        event.preventDefault();
        this.sendMessage();
      }
    },
    sendMessage() {
      if (this.message.trim()) {
        this.$emit("send", this.message);
        this.message = "";
      }
    },
    async toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        await this.startRecording();
      }
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const recorder = new Recorder(audioContext);

        recorder.init(stream);
        recorder.start().then(() => {
          this.isRecording = true;
          this.startTimer();
        });

        this.recorder = recorder;
      } catch (error) {
        if (err.name === "NotAllowedError") {
          alert(this.$t("chat.micPermission"));
        } else if (err.name === "NotFoundError") {
          alert(this.$t("chat.noMic"));
        } else {
          console.error("录音启动失败:", error);
          alert(this.$t("chat.micError"));
        }
      }
    },
    stopRecording() {
      this.recorder.stop().then(({ blob }) => {
        // 上传 Blob
        this.audioChunks.push(blob);
        this.uploadAudio(blob);
        this.isRecording = false;
        this.stopTimer();
      });
    },
    cancelRecording() {
      if (this.recorder) {
        this.audioChunks = []; // 清空已录制的数据，不进行上传
        this.recorder.stop();
        this.isRecording = false;
        this.stopTimer();
        console.log("录音已取消");
      }
    },
    // 开始录制计时
    startTimer() {
      this.recordingTime = 0;
      this.recordingTimer = setInterval(() => {
        this.recordingTime += 1;
      }, 1000);
    },
    // 停止录制计时
    stopTimer() {
      clearInterval(this.recordingTimer);
      this.recordingTimer = null;
    },
    uploadAudio() {
      if (this.audioChunks.length === 0) return; // 如果录音被取消，避免上传
      // const mimeType = MediaRecorder.isTypeSupported('audio/mp4') ? 'audio/mp4' : 'audio/webm';
      const mimeType = MediaRecorder.isTypeSupported("audio/wav")
        ? "audio/wav"
        : "audio/mpeg"; // 使用更广泛支持的格式
      const audioBlob = new Blob(this.audioChunks, { type: "audio/mpeg" });
      // const reader = new FileReader();
      // reader.readAsDataURL(audioBlob);
      // reader.onloadend = () => {
      //   const base64data = reader.result;
      //   this.$emit('playAudioLoca', base64data); // 使用 base64 格式播放音频
      // };
      const audioUrl = URL.createObjectURL(audioBlob);
      this.$emit("playAudioLoca", audioUrl);
      // const fileName = 'voiceAudio.webm'; // 设置你想要的文件名
      const fileName = "voiceAudio.mp3"; // 设置你想要的文件名
      const audioFile = new File([audioBlob], fileName, { type: mimeType });

      this.audioChunks = [];
      const formData = new FormData();
      formData.append("file", audioFile);

      // this.$emit('setLoading');
      // 上传录音到服务器
      fetch(this.uploadFileUrl, {
        method: "POST",
        headers: {
          authorization: "Bearer " + getToken(),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("音频上传成功:", data);
          this.$emit("sendUrl", data.data.url);
        })
        .catch((error) => {
          console.error("音频上传失败:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-input {
  display: flex;
  align-items: center;
  width: 100%;
  /* width: 1592px; */
  height: 64px;
  background: #dae5f0;
  border-radius: 12px;
  padding: 0 16px 0 0;
}

.chat-input .el-input__inner {
  // width: 90%;
  border: none;
  background-color: transparent;
  caret-color: black;
}

.el-input.is-disabled .el-input__inner {
  border: none;
  background-color: transparent;
  caret-color: black;
  cursor: not-allowed;
}

.el-input__inner::placeholder {
  font-family: PingFang-SC, PingFang-SC;
  font-size: 14px;
  color: #99aeca;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}

/* 恢复单行样式 */
::v-deep .chat-input .el-textarea__inner {
  border: none; /* 去掉边框 */
  background-color: transparent; /* 背景透明 */
  caret-color: black; /* 光标颜色 */
  resize: none; /* 禁止手动调整大小 */
  font-family: PingFang-SC, PingFang-SC;
  font-size: 14px;
  color: #333; /* 输入文字颜色 */
  line-height: 20px;
  text-align: left;
  font-style: normal;
  padding: 0; /* 去除默认内边距 */
  height: auto; /* 允许高度自适应内容 */
}

/* 禁用状态样式 */
::v-deep .chat-input .el-textarea.is-disabled .el-textarea__inner {
  border: none;
  background-color: transparent;
  caret-color: black;
  cursor: not-allowed; /* 禁用状态的光标 */
}

/* 自定义 placeholder 样式 */
::v-deep .chat-input .el-textarea__inner::placeholder {
  font-family: PingFang-SC, PingFang-SC;
  font-size: 14px;
  color: #99aeca;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}

/* 禁止光标拖动时拉伸输入框 */
::v-deep .chat-input .el-textarea {
  position: relative;
}
::v-deep .chat-input .el-textarea__inner {
  overflow-y: auto; /* 保证内容较多时可滚动 */
}

.sendMessage {
  width: 44px;
  height: 44px;
  background: linear-gradient(180deg, #5896ff 0%, #434eff 100%);
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  .icon-telegram-normal {
    color: #fff;
    font-size: 18px;
  }
}
</style>
