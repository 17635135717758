<template>
    <div :class="{'has-logo':showLogo}" :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground}">
        <logo v-if="showLogo" :collapse="isCollapse" />
        <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
            <el-menu
                :default-active="activeMenu"
                :collapse="isCollapse"
                :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
                :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
                :unique-opened="true"
                :active-text-color="settings.theme"
                :collapse-transition="false"
                mode="vertical"
                @select="selectMenu"
            >
                <sidebar-item
                    v-for="(route, index) in sidebarRouters"
                    :key="route.path  + index"
                    :item="route"
                    :base-path="route.path"
                />
            </el-menu>
        </el-scrollbar>
        <img class="menuPic" :src="menuPic" alt="">
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import menuPic from '@/assets/images/menuPic.png'
import variables from "@/assets/styles/variables.scss";
import EventBus from "@/utils/EventBus";

export default {
    components: { SidebarItem, Logo },
    data() {
      return {
        menuPic
      }
    },
    computed: {
        ...mapState(["settings"]),
        ...mapGetters(["sidebarRouters", "sidebar"]),
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        showLogo() {
            return this.$store.state.settings.sidebarLogo;
        },
        variables() {
            return variables;
        },
        isCollapse() {
            return !this.sidebar.opened;
        }
    },
    methods: {
        selectMenu(key, keyPath) {
          console.log(key, keyPath);
          if(key=='/material'){
            EventBus.$emit("matreial-menu-click");
          }
        }
    }
};
</script>

<style lang="scss" scoped>
.menuPic {
  width: 325px;
  height: 325px;
  position: absolute;
  top: 60%;
  left: -56%;
  opacity: 0.05;
  cursor: none;
  pointer-events: none;
}

.el-menu--collapse .el-menu-item {
  margin: 0 0 !important;
}

.el-menu--collapse .el-submenu {
  margin: 0 0 !important;
}
.el-menu {
  margin-top: 30px;
  .el-menu-item {
    margin: 0 8px;
    height: 40px;
    line-height: 40px;
  }
  .el-menu-item.is-active {
    color: #0156FF;
    background: #E2EBFD !important;
    border-radius: 4px !important;
    height: 40px;
    line-height: 40px;
  }
  .el-submenu__title {
    height: 40px;
    line-height: 40px;
  }

  .is-active{
    .el-submenu__title {
      color:#0156FF;
      background: #E2EBFD !important;
      border-radius: 4px !important;
      height: 40px;
      line-height: 40px;
    }
  }

  .el-submenu {
    margin: 0 8px;
  }
  .el-submenu .el-menu {
    margin-top: 10px;
    .el-menu-item.is-active {
      color: #0156FF;
      background: #E2EBFD !important;
      border-radius: 4px !important;
      // margin: 0 20px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
