<!-- 创建or编辑视频主题 -->
<template>
  <div class="app-container">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <!-- <el-form-item :label="$t('operation.releaseTime')" prop="publishTimeStr">
        <el-date-picker
          v-model="form.date"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :clearable="false"
        ></el-date-picker>
        <el-time-picker
          v-model="form.time"
          :clearable="false"
        ></el-time-picker>
      </el-form-item> -->
      <el-form-item :label="$t('operation.releaseTime')" prop="publishTimeStr">
        <el-date-picker clearable
        v-model="dataTime"
        @change="changeTime"
        type="datetime"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
        :placeholder="$t('operation.selectReleaseTime')">
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('operation.videoTopic')" prop="videoTheme">
        <el-input
          type="textarea"
          v-model="form.videoTheme"
          maxlength="50"
          :placeholder="$t('operation.videoTopic')"
          :autosize="{ minRows: 6, maxRows: 8}"
        />
      </el-form-item>
      <!-- 从成品库选择上传视频 -->
      <el-form-item :label="$t('issueItem.selectVideo')" prop="video">
        <div class="aspect-ratio-box-container">
          <div class="aspect-ratio-box flex-column" @click="selectVideo" v-show="videoList.length==0">
            <i class="el-icon-upload"></i>
            <div style="margin-top: 5px;">
              {{ $t('issueItem.import') }}
            </div>
          </div>
          <div
            v-for="(material, index) in videoList"
            :key="index"
            class="aspect-ratio-box flex-column"
            @mouseover="material.hover = true"
            @mouseleave="material.hover = false"
          >
            <div class="material-container">
              <video :src="material.url" class="material-thumbnail"></video>
              <div v-if="material.hover" class="overlay">
                <div @click="playVideo(material.url)">{{ $t('issueItem.paly') }}</div>
                <div style="margin: 5px;">|</div>
                <div @click="removeMaterial(index)">{{ $t('issueItem.remove') }}</div>
              </div>
            </div>
            <!-- <div style="margin-top: 5px;">{{ material.name }}</div> -->
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" type="primary" @click="submitForm">{{$t('confirm')}}</el-button>
      <el-button @click="$emit('cancel')">{{$t('cancel')}}</el-button>
    </div>
    <el-drawer
      :title="$t('issueItem.product')"
      size="65%"
      :visible.sync="drawer"
      :append-to-body="true"
      :with-header="false">
      <generated-video ref="generated" @selectionConfirmed="selectionConfirmed"></generated-video>
    </el-drawer>
    <!-- 弹窗播放视频 -->
    <el-dialog :visible.sync="dialogPlay" width="50%" :before-close="handleClose" append-to-body>
      <video controls :src="playUrl" class="video-player" ref="videoPlayer"></video>
    </el-dialog>
  </div>
</template>
<script>
import {getProgramme} from '../../api/operation/programme';
import {
  addProgrammeItems,
  getProgrammeItems,
  updateProgrammeItems
} from '../../api/operation/programmeItems';
import GeneratedVideo from '../../views/aivideo/generatedVideo';

export default {
  name: 'editStrategy',
  components: {GeneratedVideo},
  props: {
    accountList: {
      type: Array,
      default: () => []
    },
    languageOptions: {
      type: Array,
      default: () => []
    },
    programmeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      detail: null,
      buttonLoading: false,// 按钮loading
      dataTime: '',
      pickerOptions: {
        disabledDate(time) {
          return true;
        },
      },
      form: {
        id: undefined,
        // publishTimeStr: undefined,
        publishTimeStr: undefined,
        videoTheme: undefined,
        video: [],
      },
      videoList: [],
      rules: {
        publishTimeStr: [
          {required: true, message: this.$t('operation.selectReleaseTime'), trigger: ['blur', 'change']}
        ],
        videoTheme: [
          {required: true, message: this.$t('operation.videoTopicRequired'), trigger: ['blur', 'change']}
        ],
        video: [
          {required: true, message: this.$t('operation.selectVideo'), trigger: ['blur', 'change']}
        ]
      },
      drawer: false,//成品库
      dialogPlay: false,
      videoElement: null,  // 添加一个变量用于保存视频元素
      playUrl: '',  // 播放视频的URL
    }
  },
  mounted() {
    this.init(this.$route.query.id)
  },
  created() {
  },
  methods: {
    changeTime(time) {
      this.form.publishTimeStr = time
    },
    init(id) {
      this.getDetail()
      getProgrammeItems(id).then(response => {
        this.loading = false;
        if (response.data != null) {
          this.form = response.data;
          this.form.publishTimeStr = response.data.publishTime || ''
          console.log(JSON.stringify(this.form) + '-----------------')
          this.videoList = [
            {
              url: response.data.videoUrl,
              id: response.data.productId,
              hover: false, // 添加一个hover属性来控制蒙层的显示
            }
          ]
          // this.form.video = [
          //   {
          //     url: response.data.videoUrl,
          //     id: response.data.productId,
          //     hover: false, // 添加一个hover属性来控制蒙层的显示
          //   }
          // ]
        }
      });
    },
    getDetail() {
      getProgramme(this.programmeId).then(response => {
        this.detail = response.data;
        console.log('this.detail', this.detail)
        this.pickerOptions.disabledDate = (time) => {
          const beginDate = new Date(this.detail.beginDate + 'T00:00:00');
          const endDate = new Date(this.detail.endDate + 'T23:59:59');
          return time.getTime() < beginDate.getTime() || time.getTime() > endDate.getTime();
        };
      });
    },
    selectVideo() {
      this.drawer = true
      this.$nextTick().then((rs) => {
        this.$refs.generated.init(1);
      });
    },
    selectionConfirmed(items) {
      if (items.length == 1) {
        // this.form.video = [{
        //   ...items[0],
        //   hover: false, // 添加一个hover属性来控制蒙层的显示
        // }]
        this.videoList = [{
          ...items[0],
          hover: false, // 添加一个hover属性来控制蒙层的显示
        }]
        this.form.videoUrl = items[0].url
        this.form.productId = items[0].id
      }
      console.log('选中的素材:', items)
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false
    },
    playVideo(url) {
      this.playUrl = url
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    handleClose() {
      this.dialogPlay = false;
      // 关闭弹窗时停止视频播放
      if (this.videoElement) {
        this.videoElement.pause();
        this.videoElement.currentTime = 0; // 可选：将视频播放位置重置为起始位置
      }
    },
    removeMaterial(index) {
      this.videoList = []
      this.form.video = [];
      this.form.videoUrl = ''
      this.form.productId = ''
    },
    /** 提交按钮 */
    submitForm() {
      this.form.publishTimeStr = this.dataTime
      this.form.video = this.videoList
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.programmeId = this.programmeId
          this.buttonLoading = true;
          if (this.form.id != null) {
            updateProgrammeItems(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.update'));
              this.$emit('confirm')
            }).finally(() => {
              this.buttonLoading = false;
            });
          } else {
            addProgrammeItems(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.add'));
              this.$emit('confirm')
            }).finally(() => {
              this.buttonLoading = false;
            });
          }
        }
      });
    },
    handleConfirm() {
      this.$emit('confirm', this.form);
      this.$emit('update:visible', false);
    }
  }
}
</script>
<style lang="scss" scoped>
.aspect-ratio-box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.aspect-ratio-box {
  width: 90px;
  height: 160px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
}

.material-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.material-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
</style>
