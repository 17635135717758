<template>
  <div class="app-container">
    <div class="cut-scroll">
      <el-form
        v-loading="loading"
        ref="form"
        label-position="top"
        class="form-top"
        :model="form"
        :rules="rules"
      >
        <div class="model-title">{{ $t('sale.roleSetting') }}</div>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item
              :label="$t('sale.name')"
              prop="saleName"
            >
              <el-input
                v-model="form.saleName"
                maxlength="50"
                :placeholder="$t('tip.input')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('sale.model')"
              prop="modelId"
            >
              <el-select
                v-model="form.modelId"
                filterable
                default-first-option
                :placeholder="$t('tip.select')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in modelList"
                  :key="item.id"
                  :label="item.modelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="replyTypes">
          <div class="flex-row-center">
            <span style="color: #ff4949;margin-right: 4px;">*</span>
            <span class="sale-label">{{ $t('sale.replyMethod') }}</span>
            <span class="sale-tip ml10">{{ $t('sale.platformSupport') }}</span>
          </div>
          <el-checkbox-group v-model="form.replyTypes">
            <el-checkbox label="comment">{{ $t('sale.comment') }}</el-checkbox>
            <el-checkbox label="message">{{ $t('sale.message') }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          :label="$t('sale.description')"
          prop="description"
        >
          <el-input
            v-model="form.description"
            type="textarea"
            maxlength="200"
            show-word-limit
            :placeholder="$t('tip.input')"
            style="width: 90%;"
            :rows="3"
          />
        </el-form-item>
        <div class="model-title">{{ $t('sale.roleAssignment') }}</div>
        <el-form-item
          :label="$t('sale.socialMedia')"
          prop="socialTypes"
        >
          <el-checkbox-group
            v-model="form.socialTypes"
            style="width: 90%;"
          >
            <el-checkbox
              v-for="dict in dict.type.social_platform"
              border
              :key="dict.value"
              :label="dict.value"
            >
              {{ dict.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in form.socialTypes"
          :key="index"
          :prop="`selectedValues.${item}`"
        >
          <div class="flex-row-center">
            <span style="color: #ff4949;margin-right: 4px;">*</span>
            <dict-tag
              :options="dict.type.social_platform"
              :value="item"
            />
            {{ $t('sale.account') }}
          </div>
          <el-select
            v-model="form.selectedValues[item]"
            multiple
            filterable
            default-first-option
            :placeholder="$t('tip.select')"
            style="width: 100%;"
          >
            <el-option
              v-for="account in filterAccounts(item)"
              :key="account.id"
              :label="account.username"
              :value="account.id"
            >
              <div class="flex-row-center">
                <el-avatar
                  :src="account.userImage"
                  size="small"
                  style="margin-right: 10px;"
                ></el-avatar>
                <span>{{ account.username }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('sale.intentReply')"
          prop="intentionReply"
        >
          <el-input
            v-model="form.intentionReply"
            type="textarea"
            maxlength="200"
            show-word-limit
            :placeholder="$t('tip.input')"
            style="width: 90%;"
            :rows="3"
          />
        </el-form-item>
        <div class="model-tip flex-row-center">
          <i class="el-icon-warning"></i>
          {{ $t('sale.privacyWarning') }}
        </div>
      </el-form>
    </div>
    <div class="flex-center mt10">
      <el-button
        class="button-white"
        @click="cancel"
      >{{ $t('cancel') }}</el-button>
      <el-button
        :loading="buttonLoading"
        class="gradient-button"
        @click="submitForm"
      >{{ $t('confirm') }}</el-button>
    </div>
  </div>
</template>

<script>
import { getSale, addSale, updateSale } from "@/api/sale/sale";
import { listModel } from "../../api/model/model";
import { listAllAccount } from "../../api/account/tiktok";
export default {
  name: "AddSale",
  dicts: ["social_platform"],
  components: {},
  data() {
    return {
      // 表单参数
      form: {
        replyTypes: ["comment", "message"],
        socialTypes: [],
        socialAccountIds: [],
        selectedValues: {},
        description: "",
      },
      // 表单校验
      rules: {
        saleName: [
          {
            required: true,
            message: this.$t("sale.enterName"),
            trigger: ["blur", "change"],
          },
        ],
        modelId: [
          {
            required: true,
            message: this.$t("sale.selectModel"),
            trigger: ["blur", "change"],
          },
        ],
        replyTypes: [
          {
            required: true,
            message: this.$t("sale.selectReplyMethod"),
            trigger: ["blur", "change"],
          },
        ],
        socialTypes: [
          {
            required: true,
            message: this.$t("sale.selectSocialMedia"),
            trigger: ["blur", "change"],
          },
        ],
        selectedValues: {}, //各平台的校验
        intentionReply: [
          {
            required: true,
            message: this.$t("model.enterPurchaseIntention"),
            trigger: ["blur", "change"],
          },
        ],
      },
      saleId: null,
      loading: false,
      buttonLoading: false,
      modelList: [], //模型列表
      socialAccounts: [], //社媒账号列表
      allowedSocialPlatforms: ["facebook", "instagram", "twitter"], // 当仅选中消息时允许的社交平台
    };
  },
  watch: {
    "dict.type.social_platform": {
      handler(newPlatforms) {
        if (!newPlatforms || newPlatforms.length === 0) return;

        newPlatforms.forEach((platform) => {
          // 初始化 form.selectedValues
          if (!this.form.selectedValues[platform.value]) {
            this.$set(this.form.selectedValues, platform.value, []);
          }

          // 初始化 rules.selectedValues
          if (!this.rules.selectedValues[platform.value]) {
            this.$set(this.rules.selectedValues, platform.value, [
              {
                required: true,
                message: `${this.$t("sale.selectAccount1")}${
                  platform.label
                }${this.$t("sale.selectAccount2")}`,
                trigger: ["blur", "change"],
              },
            ]);
          }
        });
      },
      immediate: true, // 如果在组件加载时数据已存在，也会立即触发
      deep: true, // 深度监听，防止数据结构复杂时漏掉变化
    },
  },
  async created() {
    this.saleId = this.$route.query.id || "";
    this.getModelList();
    await this.getSocialAccountList();
    if (this.saleId) {
      this.getDetail();
    }
  },
  methods: {
    filterAccounts(socialType) {
      return this.socialAccounts.filter(
        (account) => account.platform === socialType
      );
    },
    // 获取账号列表
    async getSocialAccountList() {
      listAllAccount().then((res) => {
        this.socialAccounts = res.data;
      });
    },
    getModelList() {
      listModel().then((response) => {
        this.modelList = response.rows.filter((item) => item.status == 1);
      });
    },
    getDetail() {
      this.loading = true;
      getSale(this.saleId)
        .then((response) => {
          this.form = { ...this.form, ...response.data };
          this.form.replyTypes = this.form.replyType
            ? this.form.replyType.split(",")
            : ["comment", "message"];
          this.form.socialTypes = this.form.socialType
            ? this.form.socialType.split(",")
            : [];
          this.form.socialAccountIds = this.form.socialIds
            ? this.form.socialIds.split(",")
            : [];
          const newSelectedValues = {};
          // 遍历 socialAccounts
          this.socialAccounts.forEach((account) => {
            if (this.form.socialAccountIds.includes(account.id)) {
              if (!newSelectedValues[account.platform]) {
                newSelectedValues[account.platform] = [];
              }
              newSelectedValues[account.platform].push(account.id);
            }
          });
          this.form.selectedValues = newSelectedValues;
          // 检查 socialIds 中未出现在 socialAccounts 的 id，分配到未知平台
          // this.form.socialIds.forEach((id) => {
          //   const exists = this.socialAccounts.some((account) => account.id === id);
          //   if (!exists) {
          //     if (!newSelectedValues.unknown) {
          //       newSelectedValues.unknown = [];
          //     }
          //     newSelectedValues.unknown.push(id);
          //   }
          // });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /** 提交按钮 */
    submitForm() {
      console.log("selectedValues", this.rules.selectedValues);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (
            this.form.replyTypes.length === 1 &&
            this.form.replyTypes[0] === "message"
          ) {
            // 找到 socialTypes 中不允许的值
            const invalidPlatforms = this.form.socialTypes.filter(
              (platform) => !this.allowedSocialPlatforms.includes(platform)
            );
            // 如果有无效值，提示
            if (invalidPlatforms.length > 0) {
              this.$modal.alertError(this.$t("sale.platformWarning"));
              return;
            }
          }
          this.form.socialAccountIds = Object.values(
            this.form.selectedValues
          ).flat();
          this.form.replyType = this.form.replyTypes.join(",");
          this.form.socialType = this.form.socialTypes.join(",");
          this.form.socialIds = this.form.socialAccountIds.join(",");
          this.buttonLoading = true;
          if (this.saleId) {
            updateSale(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.update"));
                this.$tab.closeOpenPage({ path: "/aiSale" });
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          } else {
            addSale(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.add"));
                this.$tab.closeOpenPage({ path: "/aiSale" });
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.$tab.closeOpenPage({ path: "/aiSale" });
    },
  },
};
</script>

<style scoped  lang="scss">
.form-top ::v-deep .el-form-item__label {
  padding: 0;
}
.form-top .el-form-item {
  margin-bottom: 15px;
}
.form-top .el-form--label-top .el-form-item__label {
  padding: 0 0 0px 0;
}
.model-title {
  width: 90%;
  background: rgba(236, 242, 254, 0.3);
  border-radius: 8px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0052d9;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;
  padding: 5px 16px;
}
.model-tip {
  width: 90%;
  background: #e9f1fd;
  border-radius: 12px;
  padding: 14px 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin: 20px 0 16px;

  .el-icon-warning {
    color: #0052d9;
    font-size: 18px;
    margin-right: 8px;
  }
}
.cut-scroll {
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-padding-bottom: 20px;
}
.sale-label {
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  line-height: 36px;
}
.sale-tip {
  font-size: 11px;
  color: #a1a1a1;
  font-weight: 400;
}
</style>
