import { render, staticRenderFns } from "./socialTime.vue?vue&type=template&id=f6772a2c&scoped=true"
import script from "./socialTime.vue?vue&type=script&lang=js"
export * from "./socialTime.vue?vue&type=script&lang=js"
import style0 from "./socialTime.vue?vue&type=style&index=0&id=f6772a2c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6772a2c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f6772a2c')) {
      api.createRecord('f6772a2c', component.options)
    } else {
      api.reload('f6772a2c', component.options)
    }
    module.hot.accept("./socialTime.vue?vue&type=template&id=f6772a2c&scoped=true", function () {
      api.rerender('f6772a2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/issue/socialTime.vue"
export default component.exports