import request from '@/utils/request'
// 模板快剪
const baseApi = '/admin/videoTemplate'

// 列表
export function listVideoTemplate(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function getVideoTemplateDetail(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}
// 生成视频
export function videoTemplateGenerate(data) {
  return request({
    url: baseApi + '/generate',
    method: 'post',
    data
  })
}
