var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "flex-row f-end" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.$t("refresh"),
                placement: "top",
              },
            },
            [
              _c("el-button", {
                attrs: { size: "mini", circle: "", icon: "el-icon-refresh" },
                on: { click: _vm.getList },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              (_vm.executeStatus == 0 ? true : false)
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v(_vm._s(_vm.$t("add")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              (_vm.executeStatus == 0 ? true : false)
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        plain: "",
                        icon: "el-icon-edit",
                        size: "mini",
                        disabled: _vm.single,
                      },
                      on: { click: _vm.handleUpdate },
                    },
                    [_vm._v(_vm._s(_vm.$t("update")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              (_vm.executeStatus == 0 ? true : false)
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        plain: "",
                        icon: "el-icon-delete",
                        size: "mini",
                        disabled: _vm.multiple,
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v(_vm._s(_vm.$t("remove")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "header-row-class-name": "custom_table_header",
            "max-height": "500",
            data: _vm.programmeItemsList,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.topic"),
              align: "center",
              prop: "videoTheme",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.video"),
              align: "center",
              prop: "videoUrl",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("video", {
                      staticClass: "video-cover",
                      attrs: { src: scope.row.videoUrl, alt: "video" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.generationStatus"),
              align: "center",
              prop: "videoGenerateStatus",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.videoGenerateStatus == 1
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("mall.success")))])
                      : scope.row.videoGenerateStatus == 2
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: scope.row.errorMsg } },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("mall.failure")))])]
                        )
                      : _c("div", { staticClass: "flex-column-center" }, [
                          _c("i", { staticClass: "el-icon-loading" }),
                          _c("span", { staticClass: "font12" }, [
                            _vm._v(_vm._s(_vm.$t("status.generating"))),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$t("status.generatingTip"))),
                          ]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.state"),
              align: "center",
              prop: "publishStatus",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.publishStatus == 0
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("issueManager.unpublished"))),
                        ])
                      : _vm._e(),
                    scope.row.publishStatus == 1
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("mall.success")))])
                      : _vm._e(),
                    scope.row.publishStatus == 2
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: scope.row.errorMsg } },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("mall.failure")))])]
                        )
                      : _vm._e(),
                    scope.row.publishStatus == 2
                      ? _c(
                          "div",
                          { staticClass: "hidden flex-column-center" },
                          [
                            _c("div", { staticStyle: { color: "#E34D59" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("mall.failure")) + " "
                              ),
                            ]),
                            scope.row.errorMsg && scope.row.errorMsg.length > 8
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: scope.row.errorMsg,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "single-line-ellipsis pointer font12 error-message",
                                        staticStyle: { width: "160px" },
                                      },
                                      [_vm._v(_vm._s(scope.row.errorMsg))]
                                    ),
                                  ]
                                )
                              : scope.row.errorMsg
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "single-line-ellipsis font12 error-message",
                                    staticStyle: { width: "160px" },
                                  },
                                  [_vm._v(_vm._s(scope.row.errorMsg))]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operation.releaseTime"),
              align: "center",
              prop: "publishTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.parseTime(scope.row.publishTime, "{y}-{m}-{d}")
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operate"),
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.videoUrl
                      ? _c(
                          "span",
                          {
                            staticClass: "mr10",
                            staticStyle: { display: "inline-block" },
                          },
                          [
                            _c("video-button", {
                              attrs: {
                                url: scope.row.videoUrl,
                                tip: _vm.$t("videoManager.playVideo"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    (
                      _vm.executeStatus == 0
                        ? scope.row.publishStatus == 0
                          ? true
                          : false
                        : false
                    )
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("edit") } },
                          [
                            _c("el-button", {
                              attrs: { icon: "el-icon-edit", circle: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdate(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    (_vm.executeStatus == 0 ? true : false)
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("remove") } },
                          [
                            _c("el-button", {
                              attrs: { icon: "el-icon-delete", circle: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.title,
            size: "40%",
            visible: _vm.open,
            "with-header": true,
            "destroy-on-close": true,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("EditStrategyItem", {
            ref: "editStrategy",
            attrs: {
              programmeId: _vm.queryParams.programmeId,
              languageOptions: _vm.languageOptions,
            },
            on: {
              confirm: _vm.confirmEditStrategy,
              cancel: _vm.cancelEditStrategy,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }