<template>
  <div class="cardForm">
    <el-form
      ref="form"
      label-position="top"
      class="form-top"
      :model="form"
      :rules="rules"
    >
      <div class="flex-row">
        <!-- 从成品库选择上传视频 -->
        <div class="leftUpload f-shrink">
          <el-form-item
            :label="$t('issueItem.selectVideo')"
            prop="video"
          >
            <div class="aspect-ratio-box-container">
              <!-- <div class="aspect-ratio-box flex-column" @click="selectVideo" v-show="form.video.length==0">
              <i class="el-icon-upload"></i>
              <div style="margin-top: 5px;">
                {{$t('issueItem.import')}}
              </div>
            </div> -->
              <upload-file
                :title="$t('file.selectProduct')"
                v-show="form.video.length==0"
                @selectMaterial="selectMaterial"
                @showLocaMedia="showLocaMedia"
              />
              <div
                v-for="(material, index) in form.video"
                :key="index"
                class="aspect-ratio-box flex-column"
              >
                <div class="material-container">
                  <img
                    v-if="material.fileType === 'image'"
                    :src="material.url"
                    alt="image"
                    class="material-thumbnail"
                  />
                  <video
                    v-else
                    :src="material.url"
                    class="material-thumbnail"
                  ></video>
                  <video-button
                    :video="material"
                    :type="1"
                    :tip="$t('view')"
                  >
                    <template slot="customButton">
                      <div class="cricle">
                        <i class="el-icon-caret-right"></i>
                      </div>
                    </template>
                  </video-button>
                  <!-- <div class="cricle" @click="playVideo(material.url)">
                  <i class="el-icon-caret-right"></i>
                </div> -->
                  <div
                    class="deleteIcon"
                    @click="removeMaterial(index)"
                  >
                    <i class="iconfont icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
        <!-- 选择发布账号 -->
        <div class="rightForm">
          <!-- 文案编辑 -->
          <el-form-item
            style="position: relative;"
            :label="$t('issueItem.post')"
            prop="postContent"
            v-loading="loading"
          >
            <div class="textInput">
              <el-input
                :id="'postContent'+index"
                maxlength="2000"
                show-word-limit
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                v-model="form.postContent"
                :placeholder="$t('issueItem.tipPost')"
                @input="changePost"
              >
              </el-input>
              <div
                class="importText"
                @click="importText"
              ><i class="iconfont icon-a-dagoubeifen21"></i>{{ $t('basicInfo.materialImport') }}</div>
              <div class="flexInputBtn">
                <div
                  class="btnBox"
                  @click="addSymbol"
                >#{{ $t('issueItem.topic') }}</div>
                <div
                  class="btnBox"
                  @click="showEmoPicker=!showEmoPicker"
                ><i class="iconfont icon-a-danyuanpeizhi2"></i>{{ $t('issueItem.emo') }}</div>
                <div
                  class="btnBox"
                  @click="chooseLanguage"
                ><i class="iconfont icon-danyuanpeizhi"></i>{{ $t('issueItem.translate') }}</div>
                <div
                  class="btnBox"
                  @click="rewrite('zj')"
                ><i class="iconfont icon-zongjie"></i>{{ $t('subtitle.sumup') }}</div>
                <div
                  class="btnBox"
                  @click="rewrite('sx')"
                ><i class="iconfont icon-a-danyuanpeizhi4"></i>{{ $t('subtitle.abbreviation') }}</div>
                <div
                  class="btnBox"
                  @click="rewrite('kx')"
                ><i class="iconfont icon-a-danyuanpeizhi5"></i>{{ $t('subtitle.expand') }}</div>
                <div
                  class="btnBox"
                  @click="rewrite('cx')"
                ><i class="iconfont icon-a-danyuanpeizhi6"></i>{{ $t('subtitle.rewrite') }}</div>
              </div>
            </div>
            <emo-picker
              v-show="showEmoPicker"
              :inputId="'postContent'+index"
              :showFrequentlyUsedList="false"
              @select="emojiClick"
              @selectBack="updateContent"
            />
            <div
              v-if="topics.length > 0"
              class="suggestions-box"
            >
              <div
                v-for="(item, idx) in topics"
                :key="idx"
                @click="selectTopic(item)"
                class="flex-row-center f-between"
              >
                <span class="f-grow single-line-ellipsis">#{{ item.name }}</span>
                <span class="f-shrink">{{ item.viewCount }}观看次数</span>
              </div>
            </div>
          </el-form-item>
          <!-- 发布账号 -->
          <el-form-item
            :label="$t('issueItem.account')"
            prop="socialIds"
          >
            <div class="accountBox">
              <div class="accountHeader">
                <div>{{ $t('issueItem.publishTime') }}:</div>
                <el-select
                  v-model="selectTimeType"
                  style="width: 240px; margin-right: 10px;"
                >
                  <el-option
                    :label="$t('issueItem.issueNow')"
                    :value="0"
                  ></el-option>
                  <el-option
                    :label="$t('issueItem.issueCountry')"
                    :value="1"
                  ></el-option>
                  <el-option
                    :label="$t('issueItem.issueUnify')"
                    :value="2"
                  ></el-option>
                  <el-option
                    :label="$t('issueItem.customTime')"
                    :value="3"
                  ></el-option>
                </el-select>
              </div>
              <div class="accountContent">
                <div>
                  <div v-if="selectTimeType === 1">
                    <el-date-picker
                      :picker-options="pickerOptions"
                      v-model="form.publishTime"
                      type="datetime"
                      :placeholder="$t('issueItem.tipDate')"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @change="changePickerNormal"
                    ></el-date-picker>
                  </div>
                  <div v-if="selectTimeType === 2">
                    <el-select
                      v-model="form.timeZoneId"
                      :placeholder="$t('issueItem.tipTimezone')"
                      filterable
                      @change="form.publishTime = ''"
                    >
                      <el-option
                        v-for="time in timeOptions"
                        :key="time.id"
                        :label="time.rawFormat"
                        :value="time.id"
                        class="ellipsis-option"
                      ></el-option>
                    </el-select>
                    ————
                    <el-date-picker
                      :picker-options="pickerOptions"
                      v-model="form.publishTime"
                      type="datetime"
                      :placeholder="$t('issueItem.tipDate')"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @change="changePicker"
                      :disabled="!form.timeZoneId"
                    ></el-date-picker>
                  </div>
                  <div v-if="selectTimeType === 3">
                    <SocialTime
                      :template="socialTimeMap"
                      :timeOptions="timeOptions"
                      @refreshMap="refreshMap"
                      @deleteMap="deleteMap"
                    />
                    <div
                      style="color:red;font-size: 12px;"
                      v-if="!selectAccountList.length"
                    >
                      {{$t('issueItem.tipSocialTime')}}
                    </div>
                  </div>
                </div>
                <div v-if="selectAccountList.length && selectTimeType !== 3">
                  <div
                    v-for="item in selectAccountList"
                    :key="item.id"
                    class="accountItem"
                    @mouseenter="mouseenterDel(item)"
                    @mouseleave="mouseleaveDel(item)"
                  >
                    <div class="accountItemBox">
                      <el-avatar
                        :size="28"
                        :src="item.userImage"
                      ></el-avatar>
                      <div class="mediaName">{{ item.displayName }}</div>
                    </div>
                    <i
                      v-if="mouseenterId === item.id"
                      class="iconfont icon-delete"
                      @click="deleteAccount(item)"
                    ></i>
                  </div>
                </div>
                <div class="addAccount">
                  <div
                    class="addIcon"
                    @click="selectAccount"
                  >
                    <i class="iconfont icon-tianjia"></i>
                  </div>
                  {{ $t('account.addAccount') }}
                </div>
              </div>
            </div>
            <!-- <el-cascader
              style="width: 100%;"
              :show-all-levels="false"
              :props="props"
              v-model="form.socialIds"
              :options="newAccounts"
              @change="updateSocialTimeMap"
            >
              <template slot-scope="{ node, data }">
                <i
                  v-if="data.platform"
                  class="iconfont mr5 font20"
                  :class="['icon-' + data.platform  + '-normal', data.platform]"
                ></i>
                <span>{{ data.label }}</span>
              </template>
              <template slot="default" slot-scope="{ node, data }">
                <i
                  v-if="data.platform"
                  class="iconfont mr5 font20"
                  :class="['icon-' + data.platform  + '-normal', data.platform]"
                ></i>
                <span>{{ data.label }}</span>
              </template>

            </el-cascader> -->
          </el-form-item>
          <!-- <el-select v-model="form.socialIds" filterable multiple  :placeholder="$t('issueItem.tipAccount')">
            <el-option v-for="account in accountList" :key="account.id" :label="getAccount(account)" :value="account.id"></el-option>
          </el-select> -->
          <!-- 备注 -->
          <el-form-item
            :label="$t('issueVideo.taskName')"
            prop="taskName"
            style="margin-bottom: 0px;"
          >
            <el-input
              v-model="form.taskName"
              :placeholder="$t('tip.input')"
              style="width:100%"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- <el-form-item :label="$t('issueItem.account')" prop="socialIds">
            <el-cascader
              style="width: 100%;"
              :show-all-levels="false"
              :props="props"
              v-model="form.socialIds"
              :options="newAccounts"
              @change="updateSocialTimeMap"
            >
              <template slot-scope="{ node, data }">
                <i
                  v-if="data.platform"
                  class="iconfont mr5 font20"
                  :class="['icon-' + data.platform  + '-normal', data.platform]"
                ></i>
                <span>{{ data.label }}</span>
              </template>
              <template slot="default" slot-scope="{ node, data }">
                <i
                  v-if="data.platform"
                  class="iconfont mr5 font20"
                  :class="['icon-' + data.platform  + '-normal', data.platform]"
                ></i>
                <span>{{ data.label }}</span>
              </template>

            </el-cascader>
          </el-form-item> -->
          <!-- <el-select v-model="form.socialIds" filterable multiple  :placeholder="$t('issueItem.tipAccount')">
            <el-option v-for="account in accountList" :key="account.id" :label="getAccount(account)" :value="account.id"></el-option>
          </el-select> -->

          <!-- 设置发布时间 -->
          <!-- <el-form-item :label="$t('issueItem.publishTime')" prop="timeType">
            <el-radio-group v-model="form.timeType" @input="clearPublishTime">
              <el-radio label="0">{{$t('issueItem.sameTime')}}</el-radio>
              <el-radio label="1">{{$t('issueItem.customTime')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.timeType === '0'" prop="publishTimeType">
            <el-radio-group v-model="form.publishTimeType" @input="clearPublishTime">
              <el-radio label="0">{{$t('issueItem.issueNow')}}</el-radio>
              <el-radio label="1">{{$t('issueItem.issueCountry')}}</el-radio>
              <el-radio label="2">{{$t('issueItem.issueUnify')}}</el-radio>
            </el-radio-group>
            <div v-if="form.publishTimeType === '1'">
              <el-date-picker
                :picker-options="pickerOptions"
                v-model="form.publishTime"
                type="datetime"
                :placeholder="$t('issueItem.tipDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="changePickerNormal"
              ></el-date-picker>
            </div>
            <div style="color:red;font-size: 12px;" v-if="form.publishTimeType === '1'">
              {{$t('issueItem.tipSet')}}
            </div>
            <div v-if="form.publishTimeType === '2'">
              <el-select v-model="form.timeZoneId" :placeholder="$t('issueItem.tipTimezone')" filterable @change="form.publishTime = ''">
                <el-option v-for="time in timeOptions" :key="time.id" :label="time.rawFormat"
                  :value="time.id" class="ellipsis-option"></el-option>
              </el-select>
              <el-date-picker
                :picker-options="pickerOptions"
                v-model="form.publishTime"
                type="datetime"
                :placeholder="$t('issueItem.tipDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="changePicker"
                :disabled="form.timeType === '0' && form.publishTimeType === '2' && !form.timeZoneId"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item v-if="form.timeType === '1'" prop="socialTimeMap">
            <SocialTime
              :template="socialTimeMap"
              :timeOptions="timeOptions"
              @refreshMap="refreshMap"
            />
            <div style="color:red;font-size: 12px;" v-if="!socialTimeMap||(Object.keys(socialTimeMap).length === 0)">
              {{$t('issueItem.tipSocialTime')}}
            </div>
          </el-form-item> -->
          <div class="flexCenter">
            <el-button
              class="submitBtn btnWidth"
              type="primary"
              :loading="buttonLoading2"
              @click="saveDraft(2)"
            ><i class="iconfont icon-a-dagoubeifen22"></i>{{ $t('issueItem.issueNow') }}</el-button>
            <el-button
              class="cancelBtn btnWidth"
              :loading="buttonLoading1"
              @click="saveDraft(1)"
            ><i class="iconfont icon-sousuotiaojianbaocun"></i>{{ $t('issueVideo.saveDraft') }}</el-button>
          </div>
        </div>
      </div>
    </el-form>

    <el-drawer
      :title="$t('issueItem.product')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
    >
      <generated-video
        ref="generated"
        @selectionConfirmed="selectionConfirmed"
      ></generated-video>
    </el-drawer>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawerMaterial"
      @selectionConfirmed="selectionText"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('issueItem.translateTo')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50%"
    >
      <el-select
        v-model="language"
        :placeholder="$t('issueItem.translateLanguage')"
        filterable
        clearable
        :default-first-option="true"
        style="width: 100%;"
      >
        <el-option
          v-for="dict in languageList"
          :key="dict.bdCode"
          :label="dict.language"
          :value="dict.bdCode"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleCancel">{{$t('cancel')}}</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="handleConfirm"
        >{{$t('confirm')}}</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗播放视频 -->
    <el-dialog
      :visible.sync="dialogPlay"
      width="50%"
      :before-close="handleClose"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>

    <selectAccountDialog
      v-if="accountVisible"
      :accountVisible="accountVisible"
      :selectAccountList="selectAccountList"
      @close="closeAccount"
      @submitAccount="submitAccount"
    />
  </div>
</template>

<script>
import { batchTranslate, rewrite, translate } from "../../api/ai/language";
import EmoPicker from "../../components/EmoPicker/EmoPicker.vue";
import GeneratedVideo from "../../views/aivideo/generatedVideo.vue";
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
import SocialTime from "./socialTime.vue";
import { formatDate } from "@/utils/index";
import UploadFile from "../../components/uploadFile/index.vue";
import selectAccountDialog from "../../components/selectAccountDialog/index.vue";
// import ListSelector from '../../components/ChatItem/ListSelector.vue';
import VideoButton from "../../components/VideoButton/VideoButton.vue";
import { hashtags } from "../../api/ai/publish";

export default {
  name: "IssueItem",
  components: {
    EmoPicker,
    GeneratedVideo,
    MaterialDrawer,
    SocialTime,
    UploadFile,
    selectAccountDialog,
    VideoButton,
  },
  props: {
    accountList: {
      type: Array,
      required: true,
    },
    newAccounts: {
      type: Array,
      required: true,
    },
    timeOptions: {
      type: Array,
      required: true,
    },
    languageOptions: {
      type: Array,
      required: true,
    },
    value: Object, // 父组件传入的form对象
    index: {
      type: Number,
      default: 0,
    },
    languageList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasLanguageNames() {
      return this.form.socialIdsByTranslate.some((item) => item.languageName);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.form = newVal;
        if (!this.initialized) {
          this.selectTimeType = this.form.publishTimeType * 1;
          this.initialized = true;
        }
        if (this.selectTimeType !== 3) {
          this.selectAccountList = this.accountList.filter((item) =>
            this.form.socialIds.includes(item.id)
          );
          this.submitAccount({
            ids: this.form.socialIds,
            list: this.accountList.filter((item) =>
              this.form.socialIds.includes(item.id)
            ),
          });
        } else {
          this.submitAccount({
            ids: this.form.socialIds,
            list: this.accountList.filter((item) =>
              this.form.socialIds.includes(item.id)
            ),
          });
        }
        if (this.form.socialTimeMap) {
          this.socialTimeMap = this.form.socialTimeMap;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      mouseenterId: "",
      buttonLoading2: false,
      buttonLoading1: false,
      selectAccountList: [],
      accountVisible: false,
      selectTimeType: 0,
      initialized: false,
      pickerOptions: {
        // 禁用掉今天之前的日期不让选择
        disabledDate(time) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // 设置为今天的零点
          return time.getTime() < today.getTime();
        },
      },
      form: {
        socialIds: [],
        socialIdsByTranslate: [],
        postContent: "",
        video: [],
        publishTimeType: "0",
        publishTime: "",
        socialTimeMap: {},
        ...this.value,
      },
      // form: this.value,
      rules: {
        socialIds: [
          {
            required: true,
            message: this.$t("issueItem.tipAccount"),
            trigger: ["blur"],
          },
        ],
        postContent: [
          {
            required: true,
            message: this.$t("issueItem.tipPost"),
            trigger: "blur",
          },
        ],
        video: [
          {
            required: true,
            message: this.$t("issueItem.tipVideo"),
            trigger: ["blur", "change"],
          },
        ],
        timeType: [
          {
            required: true,
            message: this.$t("issueItem.tipTimeType"),
            trigger: ["blur", "change"],
          },
        ],
        publishTimeType: [
          {
            required: true,
            message: this.$t("issueItem.tipTimeType"),
            trigger: ["blur", "change"],
          },
        ],
      },
      topics: [],
      showEmoPicker: false,
      drawer: false, //成品库
      dialogVisible: false, //选择翻译语言
      language: "en", //需要翻译成的语言
      loading: false, //选择翻译语言的确定按钮
      dialogPlay: false,
      videoElement: null, // 添加一个变量用于保存视频元素
      playUrl: "", // 播放视频的URL
      drawerMaterial: false, //文案素材库
      props: {
        // value:'name',
        // label:'name',
        emitPath: false,
        multiple: true,
      },
      socialTimeMap: {}, //自定义时间——从form挪到外面
      speechText: "", //视频文案
    };
  },
  created() {},
  methods: {
    deleteMap(val) {
      this.deleteAccount(val);
    },
    mouseleaveDel(val) {
      this.mouseenterId = "";
    },
    mouseenterDel(val) {
      this.mouseenterId = val.id;
    },
    deleteAccount(val) {
      this.selectAccountList = this.selectAccountList.filter(
        (item) => item.id !== val.id
      );
    },
    saveDraft(index) {
      this.form.publishTimeType = this.selectTimeType;
      // 检查选中值是否包含 "twitter" 平台
      const hasTwitterPlatform = this.form.socialIds.some((socialId) => {
        return this.accountList.some(
          (account) => account.id === socialId && account.platform === "twitter"
        );
      });
      if (hasTwitterPlatform && this.form.postContent.length > 280) {
        this.$modal.msgError(this.$t("issueItem.tipPostLength"));
        return;
      }
      this.$emit("saveDraftClick", index);
    },
    submitAccount({ ids, list }) {
      this.form.socialIds = ids;
      this.selectAccountList = list;
      this.accountVisible = false;
      this.updateSocialTimeMap();
    },
    closeAccount() {
      this.accountVisible = false;
    },
    selectAccount() {
      this.accountVisible = true;
    },
    //清空时间，必须先选择时区才能选择时间
    clearPublishTime() {
      if (
        this.form.timeType === "0" &&
        this.form.publishTimeType === "2" &&
        !this.form.timeZoneId
      ) {
        this.form.publishTime = "";
      }
    },
    //默认当前时区的时间加1小时
    changePickerNormal() {
      const startAt = new Date(this.form.publishTime).getTime();
      const now = new Date();
      now.setHours(now.getHours() + 1); // 当前时间加1小时
      if (startAt < now.getTime()) {
        this.form.publishTime = formatDate(now); // 设置为当前时间加1小时
      }
    },
    //选中时区的时间加1小时
    changePicker(selectedTime) {
      // 获取用户选择的时区偏移量
      let timeZone =
        this.timeOptions.find((option) => option.id === this.form.timeZoneId) ||
        {};
      const timeZoneOffset = timeZone.utc || "+08:00"; // 示例： '+08:00' or '-12:00'
      console.log("timeZoneOffset", timeZoneOffset);
      // 将时区偏移量转换为小时数
      const offsetHours = this.convertTimezoneOffsetToHours(timeZoneOffset);

      // 以时区偏移计算选择的时间
      const startAt = this.applyTimezoneOffset(
        new Date(selectedTime),
        offsetHours
      ).getTime();

      // 获取当前UTC时间，并加1小时
      const now = new Date(); // 当前时间
      const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // UTC时间
      utcNow.setHours(utcNow.getHours() + 1);
      const nowWithOffset = this.applyTimezoneOffset(utcNow, offsetHours);

      // 如果选择的时间小于当前时区时间+1小时，则重置为当前时区时间+1小时
      if (startAt < nowWithOffset.getTime()) {
        this.form.publishTime = this.formatDate(nowWithOffset);
      }
      console.log("this.form.publishTime", this.form.publishTime);
    },

    // 将时区偏移量（如 +08:00）转换为小时
    convertTimezoneOffsetToHours(offset) {
      const sign = offset.startsWith("-") ? -1 : 1;
      const [hours, minutes] = offset.slice(1).split(":").map(Number);
      return sign * (hours + minutes / 60);
    },

    // 将日期对象应用时区偏移量
    applyTimezoneOffset(date, offsetHours) {
      const dateWithOffset = new Date(date);
      dateWithOffset.setHours(dateWithOffset.getHours() + offsetHours);
      return dateWithOffset;
    },

    formatDate(date) {
      // 格式化日期为 yyyy-MM-dd HH:mm:ss 格式
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    refreshMap(map) {
      this.socialTimeMap = map;
    },
    updateSocialTimeMap() {
      const currentIds = new Set(
        this.socialTimeMap ? Object.keys(this.socialTimeMap) : []
      );

      const filteredAccounts = this.accountList.filter((account) =>
        this.form.socialIds.includes(account.id)
      );
      const originalSocialTimeMap = { ...this.socialTimeMap };
      // 清空 socialTimeMap
      this.socialTimeMap = {};
      filteredAccounts.forEach((account) => {
        if (currentIds.has(account.id)) {
          // 如果该 ID 已存在，保留原值
          this.socialTimeMap[account.id] = {
            ...originalSocialTimeMap[account.id],
            id: account.id,
          };
        } else {
          // 如果是新增 ID，设置默认值
          this.$set(this.socialTimeMap, account.id, {
            userImage: account.userImage,
            displayName: account.displayName,
            platform: account.platform,
            id: account.id,
            publishTimeType: "0", // 默认值
            publishTime: null, // 默认值
            timeZoneId: null, // 默认值
          });
        }
      });
      console.log("this.socialTimeMap", this.socialTimeMap);
    },
    changePost() {
      if (this.form.socialIdsByTranslate.length > 0) {
        this.form.socialIdsByTranslate = this.form.socialIdsByTranslate.map(
          (item) => {
            if (!item.languageName) {
              item.text = this.form.postContent;
            }
            return item;
          }
        );
      }
      this.searchTopic();
    },
    updateTranslation(index, newDst) {
      this.form.socialIdsByTranslate[index].text = newDst;
      console.log(
        "this.form.socialIdsByTranslate",
        this.form.socialIdsByTranslate
      );
    },
    //导入文案素材
    importText() {
      this.drawerMaterial = true;
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(4);
      });
    },
    selectionText(items) {
      if (items.length == 1) {
        this.form.postContent = items[0].content;
      }
      this.drawerMaterial = false;
    },
    playVideo(url) {
      this.playUrl = url;
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    handleClose() {
      this.dialogPlay = false;
      // 关闭弹窗时停止视频播放
      if (this.videoElement) {
        this.videoElement.pause();
        this.videoElement.currentTime = 0; // 可选：将视频播放位置重置为起始位置
      }
    },
    reset() {
      this.form.socialIdsByTranslate = [];
      this.form.postContent = "";
    },
    //一键翻译
    oneTranslate() {
      if (!this.form.socialIds || this.form.socialIds.length == 0) {
        this.$modal.msgError(this.$t("issueItem.tipAccount"));
        return;
      }
      if (!this.form.postContent) {
        this.$modal.msgError(this.$t("issueItem.tipPostFirst"));
        return;
      }
      const accounts =
        this.accountList.filter((account) =>
          this.form.socialIds.includes(account.id)
        ) || [];
      if (accounts.length > 0) {
        const allNoBdCode = accounts.every((acc) => !acc.bdLanguageCode);
        if (allNoBdCode) {
          this.$modal.msgError(this.$t("issueItem.tipBdCode"));
          return;
        }
        const codes = accounts
          .filter((acc) => acc.bdLanguageCode)
          .map((acc) => acc.bdLanguageCode);
        batchTranslate({
          text: this.form.postContent,
          to: codes.join(","),
          from: "auto",
        })
          .then((response) => {
            if (response.data && response.data.length > 0) {
              let translateList = response.data;
              this.form.socialIdsByTranslate = translateList.map((item) => {
                const languageId = this.accountList.find(
                  (account) => account.bdLanguageCode === item.to
                ).languageId;
                const language = this.languageOptions.find(
                  (item) => item.id === languageId
                );
                const parts = language.name.split("(");
                if (parts.length > 0) {
                  const languageName = parts[0].trim();
                  const accounts =
                    this.accountList.filter(
                      (account) => account.bdLanguageCode === item.to
                    ) || [];
                  const names = accounts.map((account) => account.username);
                  const ids = accounts.map((account) => account.id);
                  return {
                    text: item.dst,
                    socialIds: Array.isArray(ids) ? ids : [ids],
                    languageName: languageName,
                    accounts: names.join(","),
                  };
                }
              });
              const noBdCode =
                this.accountList.filter((account) => !account.bdLanguageCode) ||
                [];
              if (noBdCode.length > 0) {
                const names = noBdCode.map((account) => account.username);
                const ids = noBdCode.map((account) => account.id);
                this.form.socialIdsByTranslate.push({
                  text: this.form.postContent,
                  socialIds: Array.isArray(ids) ? ids : [ids],
                  languageName: "",
                  accounts: names.join(","),
                });
              }
              console.log(
                "this.form.socialIdsByTranslate",
                this.form.socialIdsByTranslate
              );
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$modal.msgError(this.$t("issueItem.tipNotFind"));
      }
    },
    //选择翻译语言
    chooseLanguage() {
      if (!this.form.postContent) {
        this.$modal.msgError(this.$t("issueItem.tipPostFirst"));
        return;
      }
      this.dialogVisible = true;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleConfirm() {
      if (!this.language) {
        this.$modal.msgError(this.$t("issueItem.tipTranslate"));
        return;
      }
      this.loading = true;
      translate({
        text: this.form.postContent,
        to: this.language,
        from: "auto",
      })
        .then((response) => {
          this.dialogVisible = false;
          if (
            response.data &&
            response.data.trans_result &&
            response.data.trans_result.length > 0
          ) {
            let result = response.data.trans_result[0].dst;
            if (result) this.form.postContent = result;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //重写
    rewrite(type) {
      if (!this.form.postContent) {
        this.$modal.msgError(this.$t("issueItem.tipPostFirst"));
        return;
      }
      this.loading = true;
      rewrite({
        content: this.form.postContent,
        type: type,
      })
        .then((res) => {
          this.form.postContent = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAccount(account) {
      if (account) {
        return account.username + this.formatLanguage(account.languageId);
      }
      return "";
    },
    formatLanguage(languageId) {
      const language = this.languageOptions.find(
        (item) => item.id === languageId
      );
      if (language) {
        const parts = language.name.split("(");
        if (parts.length === 2) {
          const country = parts[1].replace(")", "").trim();
          const languageName = parts[0].trim();
          return `（${country} ${languageName}）`;
        }
        return language.name;
      }
      return "";
    },
    emojiClick(emoji) {
      console.log(emoji);
      this.showEmoPicker = false;
    },
    updateContent(resultText) {
      this.form.postContent = resultText;
    },
    addEmoji(e) {
      var elInput = document.getElementById("emojiInput"); //根据id选择器选中对象
      var startPos = elInput.selectionStart; // input 第0个字符到选中的字符
      var endPos = elInput.selectionEnd; // 选中的字符到最后的字符
      if (startPos === undefined || endPos === undefined) return;
      var txt = elInput.value;
      // 将表情添加到选中的光标位置
      var result =
        txt.substring(0, startPos) + e.native + txt.substring(endPos);
      elInput.value = result; // 赋值给input的value
      // 重新定义光标位置
      elInput.focus();
      elInput.selectionStart = startPos + e.native.length;
      elInput.selectionEnd = startPos + e.native.length;
      this.content = result; // 赋值给表单中的的字段
    },
    addSymbol() {
      this.form.postContent += " #";
    },
    // 搜索话题
    searchTopic() {
      const content = this.form.postContent;
      const lastHashIndex = content.lastIndexOf("#"); // 查找最后一个#的位置

      // 如果没有#或者#后面已经有空格，停止搜索
      if (lastHashIndex === -1 || content[lastHashIndex + 1] === " ") {
        this.topics = [];
        return;
      }
      // 获取#后面的内容
      const query = content.slice(lastHashIndex + 1).trim();
      // 如果没有输入有效字符，停止
      if (!query || query.includes(" ")) {
        this.topics = [];
        return;
      }
      // 获取话题列表
      hashtags({ keyword: query }).then((response) => {
        this.topics = response.data.recommendations;
      });
    },
    // 选择话题
    selectTopic(item) {
      const content = this.form.postContent;
      const lastHashIndex = content.lastIndexOf("#");

      // 将#后面的部分替换为选中的话题并加上空格
      this.form.postContent =
        content.slice(0, lastHashIndex + 1) + item.name + " ";
      this.topics = []; // 选择后清空建议列表
    },
    selectMaterial() {
      this.selectVideo();
    },
    selectVideo() {
      this.drawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.generated.init(1);
      });
    },
    showLocaMedia(items) {
      this.selectionConfirmed(items);
    },
    selectionConfirmed(items) {
      if (items.length == 1) {
        this.form.video = [
          {
            ...items[0],
            hover: false, // 添加一个hover属性来控制蒙层的显示
          },
        ];
        this.form.url = items[0].url;
        this.form.productId = items[0].id;
        if (items[0].speechSummarize) {
          this.form.postContent = items[0].speechSummarize;
        }
        this.speechText = items[0].speechText || "";
      }
      console.log("选中的素材:", items);
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false;
    },
    removeMaterial(index) {
      this.form.video = [];
      this.form.url = "";
      this.form.productId = "";
      this.speechText = "";
    },
    validateSocialTimeMap() {
      const errors = {};
      for (const id in this.socialTimeMap) {
        const item = this.socialTimeMap[id];
        if (item.publishTimeType === "0") {
          // publishTimeType 为 '0'，publishTime 和 timeZoneId 可以为空
          continue;
        } else if (item.publishTimeType === "1") {
          // publishTimeType 为 '1'，publishTime 必填
          if (!item.publishTime) {
            errors[id] = { publishTime: "Publish time is required." };
          }
        } else if (item.publishTimeType === "2") {
          // publishTimeType 为 '2'，publishTime 和 timeZoneId 必填
          if (!item.publishTime) {
            errors[id] = { publishTime: "Publish time is required." };
          }
          if (!item.timeZoneId) {
            if (!errors[id]) {
              errors[id] = {};
            }
            errors[id].timeZoneId = "Time zone ID is required.";
          }
        }
      }

      return errors;
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            console.log("this.form.socialIds", this.form.socialIds);
            if (this.form.timeType == "0") {
              if (
                (this.form.publishTimeType == "1" ||
                  this.form.publishTimeType == "2") &&
                !this.form.publishTime
              ) {
                this.$modal.msgError(this.$t("issueItem.tipIssueTime"));
                return;
              }
              if (this.form.publishTimeType == "2" && !this.form.timeZoneId) {
                this.$modal.msgError(this.$t("issueItem.tipIssueTimezone"));
                return;
              }
              if (this.form.publishTimeType === "1") {
                const accounts =
                  this.accountList.filter((account) =>
                    this.form.socialIds.includes(account.id)
                  ) || [];
                if (accounts.length > 0) {
                  const hasNoLanguageId = accounts.some(
                    (acc) => !acc.languageId
                  );
                  if (hasNoLanguageId) {
                    this.$modal.msgError(this.$t("issueItem.tipSetNo"));
                    return;
                  }
                }
              }
              this.form.socialTimeMap = null;
            } else if (this.form.timeType == "1") {
              const validationErrors = this.validateSocialTimeMap();
              if (Object.keys(validationErrors).length > 0) {
                // 存在错误，处理逻辑
                this.$modal.msgError(this.$t("issueItem.tipIssueTimeAll"));
                return;
              }
              this.form.socialTimeMap = this.socialTimeMap;
            }
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardForm {
  height: calc(100vh - 150px);
  // max-height: 896px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #e5e7eb;
  padding: 20px;
  overflow: auto;
  .leftUpload {
    width: 200px;
    margin-right: 120px;
    .aspect-ratio-box-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .material-container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .cricle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        .el-icon-caret-right {
          color: #fff;
          font-size: 35px;
        }
      }
      .deleteIcon {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 28px;
        height: 28px;
        background: #000000;
        border-radius: 8px;
        opacity: 0.5;
        cursor: pointer;
        justify-content: center;
        display: flex;
        align-items: center;
        .icon-delete {
          color: #fff;
          font-size: 16px;
        }
      }
    }
    .aspect-ratio-box {
      width: 200px;
      height: 366px;
      border: 1px solid #e5e7eb;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      margin-bottom: 5px;
      position: relative;
    }
    .material-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .rightForm {
    // width: 50%;
    flex: 1;
    .textInput {
      width: 100%;
      position: relative;
      .importText {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: -30px;
        color: #003bff;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #003bff;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        .icon-xialajiantou {
          margin-right: 8px;
        }
      }
      .flexInputBtn {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 8px;
        left: 8px;
        .btnBox {
          cursor: pointer;
          width: 58px;
          height: 24px;
          background: #eeeeee;
          border-radius: 2px;
          color: #0156ff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #0156ff;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-right: 8px;
          .iconfont {
            font-size: 12px;
            margin-right: 5px;
          }
        }
      }
      ::v-deep .el-textarea__inner {
        // padding: 5px 15px 30px 15px;
        padding-bottom: 40px; /* 留出空间给按钮 */
        box-sizing: border-box;
      }
    }
    .accountBox {
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #e5e7eb;
      .accountHeader {
        height: 40px;
        background: #ffffff;
        border-radius: 12px 12px 0px 0px;
        border-bottom: 1px solid #e5e7eb;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ::v-deep .el-input--medium .el-input__inner {
          height: 36px;
          line-height: 36px;
          border: none;
          text-align: right;
          padding-right: 40px !important;
        }
        .el-input__suffix {
          position: absolute;
          height: 100%;
          right: -15px;
          top: 0;
          text-align: center;
          color: #c0c4cc;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          pointer-events: none;
        }
      }
      .accountContent {
        padding: 16px 10px;
        .addAccount {
          display: flex;
          .addIcon {
            cursor: pointer;
            width: 32px;
            height: 32px;
            background: #f3f3f3;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 9px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            line-height: 22px;
            text-align: left;
            font-style: normal;
            .icon-tianjia {
              color: #909090;
              font-size: 14px;
            }
          }
        }
        .accountItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          .accountItemBox {
            display: flex;
            align-items: center;
          }
          .icon-delete {
            color: #ff2f2f;
            font-size: 14px;
            cursor: pointer;
          }
          .mediaName {
            margin-left: 10px;
          }
        }
      }
    }
    .flexCenter {
      display: flex;
      justify-content: center;
      margin-top: 44px;
      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
      .btnWidth {
        width: 106px;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.form-top ::v-deep .el-form-item__label {
  padding: 0;
}
.form-top .el-form-item {
  margin-bottom: 15px;
}
.form-top .el-form--label-top .el-form-item__label {
  padding: 0 0 0px 0;
}
.video-player {
  max-height: 400px;
}

.suggestions-box {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1000;
  padding: 0 10px;
}
</style>
