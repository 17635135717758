import request from '@/utils/request'
//发布
const baseApi = '/admin/publish'

// 新增草稿
export function addPublish(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 修改
export function editPublish(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}
// 发布
export function releasePublish(data) {
  return request({
    url: baseApi + '/release',
    method: 'post',
    data: data
  })
}

// 列表
export function listPublish(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详细
export function getPublish(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 取消发布or下架
export function deletePost(params) {
  return request({
    url: baseApi + '/deletePost',
    method: 'post',
    params
  })
}
// 删除帖子
export function deletePublish(params) {
  return request({
    url: baseApi + '/delete',
    method: 'delete',
    params
  })
}

// 信息流列表
export function queryInformationFlow(query) {
  return request({
    url: baseApi + '/queryInformationFlow',
    method: 'get',
    params: query
  })
}
// 查看评论列表
export function queryComments(query) {
  return request({
    url: baseApi + '/queryComments',
    method: 'get',
    params: query
  })
}
// 评论
export function pubComments(query) {
  return request({
    url: baseApi + '/pubComments',
    method: 'put',
    params: query
  })
}
// 话题
export function hashtags(params) {
  return request({
    url: baseApi + '/hashtags',
    method: 'get',
    params
  })
}
