<template>
  <el-dialog
    class="publicDialog"
    :visible.sync="localVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleBeforeClose"
    :show-close="false"
    >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ title }}</div>
        <i class="el-icon-close" @click="close"></i>
      </div>
    </div>
    <el-form :model="form" :rules="rules" label-position="left" label-width="100px">
      <el-form-item :label="$t('file.folderName')" prop="input">
        <el-input v-model="form.input" maxlength="50" style="width:100%;" :disabled="isSystem"/>
      </el-form-item>
      <el-form-item :label="$t('file.folderLabel')" prop="label">
        <el-select
          ref="searchSelect"
          v-model="form.labels"
          :placeholder="$t('label.tipSelect')"
          allow-create
          filterable
          multiple
          default-first-option
          @input="handleInput"
          style="width:100%;">
          <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancelBtn" @click="handleCancel">{{$t('cancel')}}</el-button>
      <el-button class="submitBtn" type="primary" @click="handleConfirm">{{$t('confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    // label: {
    //   type: String,
    //   required: true
    // },
    initialValue: {
      type: String,
      default: ''
    },
    initialLabel: {
      type: String,
      default: ''
    },
    labelList: {
      type: Array,
      default: () => []
    },
    isSystem: {//系统文件夹不允许编辑名称
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localVisible: this.visible,
      form: {
        input: this.initialValue,
        labels: this.initialLabel ? this.initialLabel.split(',') : []
      },
      rules: {
        input: [{ required: true, message: this.$t('tip.input'), trigger: 'blur' }]
      }
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
    initialValue(newVal) {
      console.log("initialValue", newVal)
      this.form.input = newVal;
    },
    initialLabel(newVal) {
      console.log("initialLabel", newVal)
      this.form.labels = newVal ? newVal.split(',') : [];
    }
  },
  methods: {
    clearForm(){
      this.form = {
        input: this.initialValue,
        labels: this.initialLabel ? this.initialLabel.split(',') : []
      }
    },
    handleInput(value) {
      if (value != null) {
       var label = value[value.length - 1]
        if(label != null && label.length > 20){
           value[value.length - 1] = label.substring(0, 20)
        }
      }
    },
    handleBeforeClose(done) {
      this.close()
      done();
    },
    handleCancel() {
      this.close()
    },
    handleConfirm() {
      this.$emit('confirm', this.form);
      this.clearForm()
      this.close()
    },
    close(){
      this.localVisible = false;
      this.$emit('update:visible', false);
    },
  }
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
