<template>
  <div class="app-container">
    <div class="flex-row f-end">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('refresh')"
        placement="top"
      >
        <el-button
          size="mini"
          circle
          icon="el-icon-refresh"
          @click="getList"
        />
      </el-tooltip>
    </div>
    <el-row
      :gutter="10"
      class="mb8"
    >
      <el-col :span="1.5">
        <el-button
          v-if="executeStatus == 0 ? true : false"
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >{{ $t('add') }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          v-if="executeStatus == 0 ? true : false"
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >{{ $t('update') }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          v-if="executeStatus == 0 ? true : false"
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >{{ $t('remove') }}</el-button>
      </el-col>
      <!-- <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
        >{{ $t('export') }}</el-button>
      </el-col> -->
    </el-row>

    <el-table
      v-loading="loading"
      header-row-class-name="custom_table_header"
      max-height="500"
      :data="programmeItemsList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        :label="$t('operation.topic')"
        align="center"
        prop="videoTheme"
      />
      <!-- <el-table-column label="时区" align="center" prop="languageId" >
        <template slot-scope="scope">
          {{ formatLanguage(scope.row.languageId) }}
        </template>
      </el-table-column> -->
      <el-table-column
        :label="$t('operation.video')"
        align="center"
        prop="videoUrl"
      >
        <template slot-scope="scope">
          <video
            :src="scope.row.videoUrl"
            alt="video"
            class="video-cover"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.generationStatus')"
        align="center"
        prop="videoGenerateStatus"
        width="180"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.videoGenerateStatus == 1">{{ $t('mall.success') }}</span>
          <el-tooltip
            :content="scope.row.errorMsg"
            v-else-if="scope.row.videoGenerateStatus == 2"
          >
            <span>{{ $t('mall.failure') }}</span>
          </el-tooltip>
          <div
            v-else
            class="flex-column-center"
          >
            <i class="el-icon-loading"></i>
            <span class="font12">{{ $t('status.generating') }}<br>{{ $t('status.generatingTip') }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.state')"
        align="center"
        prop="publishStatus"
        width="180"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.publishStatus == 0">{{ $t('issueManager.unpublished') }}</span>
          <span v-if="scope.row.publishStatus == 1">{{ $t('mall.success') }}</span>
          <el-tooltip
            :content="scope.row.errorMsg"
            v-if="scope.row.publishStatus == 2"
          >
            <span>{{ $t('mall.failure') }}</span>
          </el-tooltip>
          <div
            v-if="scope.row.publishStatus == 2"
            class="hidden flex-column-center"
          >
            <div style="color: #E34D59;">
              {{ $t('mall.failure') }}
            </div>
            <el-tooltip
              v-if="scope.row.errorMsg && scope.row.errorMsg.length > 8"
              :content="scope.row.errorMsg"
              placement="top"
            >
              <span
                class="single-line-ellipsis pointer font12 error-message"
                style="width: 160px;"
              >{{ scope.row.errorMsg }}</span>
            </el-tooltip>
            <span
              v-else-if="scope.row.errorMsg"
              class="single-line-ellipsis font12 error-message"
              style="width: 160px;"
            >{{ scope.row.errorMsg }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operation.releaseTime')"
        align="center"
        prop="publishTime"
        width="180"
      >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.publishTime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('operate')"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.videoUrl"
            style="display: inline-block;"
            class="mr10"
          >
            <video-button
              :url="scope.row.videoUrl"
              :tip="$t('videoManager.playVideo')"
            ></video-button>
          </span>
          <el-tooltip
            :content="$t('edit')"
            v-if="executeStatus == 0 ? scope.row.publishStatus == 0 ? true : false : false"
          >
            <el-button
              icon="el-icon-edit"
              circle
              @click="handleUpdate(scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('remove')"
            v-if="executeStatus == 0 ? true : false"
          >
            <el-button
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.row)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <!-- <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->

    <!-- 添加或修改运营方案明细对话框 -->
    <el-drawer
      :title="title"
      size="40%"
      :visible.sync="open"
      :with-header="true"
      :destroy-on-close="true"
      :wrapperClosable="false"
    >
      <EditStrategyItem
        ref="editStrategy"
        :programmeId="queryParams.programmeId"
        :languageOptions="languageOptions"
        @confirm="confirmEditStrategy"
        @cancel="cancelEditStrategy"
      />
    </el-drawer>
  </div>
</template>

<script>
import { listLanguage } from "../../api/ai/language";
import { getProgramme } from "../../api/operation/programme";
import {
  listProgrammeItems,
  delProgrammeItems,
} from "../../api/operation/programmeItems";
import EditStrategyItem from "./editStrategyItem.vue";
import VideoButton from "../../components/VideoButton/VideoButton.vue";
import EventBus from "../../utils/EventBus";

export default {
  // name: "ProgrammeDetail",
  components: {
    EditStrategyItem,
    VideoButton,
  },
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      // total: 0,
      // 运营方案明细表格数据
      programmeItemsList: [],
      programmeDetail: {}, //方案详情
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      executeStatus: this.$route.query.executeStatus,
      // 查询参数
      queryParams: {
        programmeId: "",
        pageNum: 1,
        pageSize: 10,
      },
      languageOptions: [],
    };
  },
  created() {
    this.queryParams.programmeId = this.$route.query.id || "";
    if (this.queryParams.programmeId) {
      this.getList();
      this.getDetail();
    }
    this.getLanguage();
  },
  mounted() {
    EventBus.$on("switch-executeStatus", (row) => {
      if (row.id === this.queryParams.programmeId) {
        this.executeStatus = row.executeStatus;
      }
    });
    EventBus.$on("jump-executeStatus", (state) => {
      this.executeStatus = state;
    });
  },
  methods: {
    // 获取语言列表
    getLanguage() {
      listLanguage().then((response) => {
        this.languageOptions = response.data;
      });
    },
    /** 查询运营方案明细列表 */
    getList() {
      this.loading = true;
      listProgrammeItems(this.queryParams).then((response) => {
        this.programmeItemsList = response.data;
        // this.total = response.total;
        this.loading = false;
      });
    },
    getDetail() {
      getProgramme(this.queryParams.programmeId).then((response) => {
        this.programmeDetail = response.data;
        console.log("this.programmeDetail", this.programmeDetail);
      });
    },
    formatLanguage(languageId) {
      const language = this.languageOptions.find(
        (item) => item.id === languageId
      );
      if (language) {
        const parts = language.name.split("(");
        if (parts.length === 2) {
          const country = parts[1].replace(")", "").trim();
          const languageName = parts[0].trim();
          return `${country} ${languageName}`;
        }
        return language.name;
      }
      return "--";
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.open = true;
      this.title = this.$t("operation.createVideoTopic");
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const id = row.id || this.ids;
      this.title = this.$t("operation.editVideoTopic");
      this.open = true;
      this.$nextTick().then((rs) => {
        this.$refs.editStrategy.init(id);
      });
    },
    confirmEditStrategy() {
      this.open = false;
      this.getList();
    },
    cancelEditStrategy() {
      this.open = false;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal
        .confirm(this.$t("operation.confirmDelete"))
        .then(() => {
          this.loading = true;
          return delProgrammeItems(ids);
        })
        .then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "admin/programmeItems/export",
        {
          ...this.queryParams,
        },
        `programmeItems_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-drawer__header {
  margin-bottom: 0;
}
.video-cover {
  width: 100px;
  height: 100px;
  // cursor: pointer;
}
</style>
