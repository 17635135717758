var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-switch", {
        staticStyle: { margin: "10px" },
        attrs: {
          "active-text": _vm.$t("title.show"),
          "inactive-text": _vm.$t("title.hide"),
        },
        on: { change: _vm.switchUpdated },
        model: {
          value: _vm.openTitle,
          callback: function ($$v) {
            _vm.openTitle = $$v
          },
          expression: "openTitle",
        },
      }),
      _vm.openTitle
        ? _c(
            "div",
            { staticClass: "flex-row" },
            [
              _c(
                "el-form",
                {
                  ref: "titleForm",
                  staticClass: "f-grow",
                  attrs: {
                    model: _vm.settings,
                    rules: _vm.rules,
                    "label-width": "120px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("title.title"), prop: "content" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          "show-word-limit": "",
                          placeholder: _vm.$t("title.tipTitle"),
                        },
                        on: { input: _vm.handleItemUpdated },
                        model: {
                          value: _vm.settings.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "content", $$v)
                          },
                          expression: "settings.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("title.font"), prop: "font" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: _vm.$t("title.tipFont") },
                          on: { change: _vm.handleItemUpdated },
                          model: {
                            value: _vm.settings.font,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "font", $$v)
                            },
                            expression: "settings.font",
                          },
                        },
                        _vm._l(_vm.dict.type.font_typeface, function (dict) {
                          return _c("el-option", {
                            key: dict.value,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.fontSizeType"),
                        prop: "fontSizeType",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.fontSizeChange },
                          model: {
                            value: _vm.settings.fontSizeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "fontSizeType", $$v)
                            },
                            expression: "settings.fontSizeType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "small" } }, [
                            _vm._v(_vm._s(_vm.$t("title.small"))),
                          ]),
                          _c("el-radio", { attrs: { label: "normal" } }, [
                            _vm._v(_vm._s(_vm.$t("title.normal"))),
                          ]),
                          _c("el-radio", { attrs: { label: "big" } }, [
                            _vm._v(_vm._s(_vm.$t("title.big"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.spacing"),
                        prop: "spacing",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 20 },
                        on: { change: _vm.handleItemUpdated },
                        model: {
                          value: _vm.settings.spacing,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "spacing", $$v)
                          },
                          expression: "settings.spacing",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.fontColor"),
                        prop: "fontColor",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        on: { change: _vm.handleItemUpdated },
                        model: {
                          value: _vm.settings.fontColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "fontColor", $$v)
                          },
                          expression: "settings.fontColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.outline"),
                        prop: "outline",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 10 },
                        on: { change: _vm.handleItemUpdated },
                        model: {
                          value: _vm.settings.outline,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "outline", $$v)
                          },
                          expression: "settings.outline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.outlineColour"),
                        prop: "outlineColour",
                      },
                    },
                    [
                      _c("el-color-picker", {
                        on: { change: _vm.handleItemUpdated },
                        model: {
                          value: _vm.settings.outlineColour,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "outlineColour", $$v)
                          },
                          expression: "settings.outlineColour",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.alignment"),
                        prop: "alignment",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.alignmentUpdated },
                          model: {
                            value: _vm.settings.alignment,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "alignment", $$v)
                            },
                            expression: "settings.alignment",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "TopLeft" } }, [
                            _vm._v(_vm._s(_vm.$t("title.left"))),
                          ]),
                          _c("el-radio", { attrs: { label: "TopCenter" } }, [
                            _vm._v(_vm._s(_vm.$t("title.center"))),
                          ]),
                          _c("el-radio", { attrs: { label: "TopRight" } }, [
                            _vm._v(_vm._s(_vm.$t("title.right"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.opacity"),
                        prop: "fontColorOpacity",
                      },
                    },
                    [
                      _c("el-slider", {
                        attrs: { max: 1, min: 0, step: 0.01, "show-input": "" },
                        on: { change: _vm.handleItemUpdated },
                        model: {
                          value: _vm.settings.fontColorOpacity,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "fontColorOpacity", $$v)
                          },
                          expression: "settings.fontColorOpacity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.fontFaces"),
                        prop: "fontFaces",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.changeFontFace },
                          model: {
                            value: _vm.settings.fontFaces,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "fontFaces", $$v)
                            },
                            expression: "settings.fontFaces",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "Italic" } }, [
                            _vm._v(_vm._s(_vm.$t("title.italic"))),
                          ]),
                          _c("el-checkbox", { attrs: { label: "Bold" } }, [
                            _vm._v(_vm._s(_vm.$t("title.blod"))),
                          ]),
                          _c("el-checkbox", { attrs: { label: "Underline" } }, [
                            _vm._v(_vm._s(_vm.$t("title.underline"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("title.effectColorStyle"),
                        prop: "effectColorStyle",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row-center f-between" },
                        [
                          _c("el-switch", {
                            staticStyle: { margin: "10px" },
                            on: { change: _vm.switchEffectUpdated },
                            model: {
                              value: _vm.settings.openEffectColorStyle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.settings,
                                  "openEffectColorStyle",
                                  $$v
                                )
                              },
                              expression: "settings.openEffectColorStyle",
                            },
                          }),
                          _vm.settings.openEffectColorStyle
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.isExpansion = !_vm.isExpansion
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isExpansion
                                        ? _vm.$t("collapse")
                                        : _vm.$t("expansion")
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.settings.openEffectColorStyle
                        ? _c(
                            "div",
                            { staticClass: "image-container" },
                            _vm._l(_vm.styleList, function (item, index) {
                              return _c("img", {
                                key: index,
                                class: {
                                  selected:
                                    _vm.settings.effectColorStyle ===
                                    item.effectColorStyle,
                                },
                                attrs: { src: item.imgUrl },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectEffectItem(item)
                                  },
                                },
                              })
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }