import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang/index'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/wssTest',
    component: () => import('@/views/wssTest.vue'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true
  },
  {
    path: '/forgotPassword',
    component: () => import('@/views/forgotPassword'),
    hidden: true
  },
  {
    path: '/oauth/tiktok/callback',
    component: () => import('@/views/oauth/tiktok/callback'),
    hidden: true
  },
  {
    path: '/callback',
    component: () => import('@/views/account/callback'),
    hidden: true
  },
  // {
  //   path: '/videoEditor',
  //   component: () => import('@/views/aivideo/videoEditor'),
  //   hidden: true
  // },
  // {
  //   path: '/account',
  //   component: () => import('@/views/account/index'),
  //   hidden: true
  // },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },
  {
    path: '/privacy',
    component: () => window.location.href = '/privacy.html',
    hidden: true
  },
  {
    path: '/service',
    component: () => window.location.href = '/service.html',
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/index'),
        name: 'Index',
        meta: { title: i18n.t('menus.workbench'), icon: 'nav_gongzuotai', affix: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/videoEditor/:id',
        component: () => import('@/views/aivideo/videoEditor'),
        name: 'VideoEditor',
        meta: { title: i18n.t('tab.taskDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/newCutting',
        component: () => import('@/views/aiCutting/aiCrosscutting'),
        name: 'AiCrosscutting',
        meta: { title: i18n.t('tab.newTask'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/newCutting/:id',
        component: () => import('@/views/aiCutting/aiCrosscutting'),
        name: 'AiCrosscutting',
        meta: { title: i18n.t('tab.newTask'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/issueVideo/:id',
        component: () => import('@/views/issue/issueVideo'),
        name: 'IssueVideo',
        meta: { title: i18n.t('tab.publishTask'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/stripping/:id',
        component: () => import('@/views/aivideo/stripping'),
        name: 'Stripping',
        meta: { title: i18n.t('tab.strippingDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/videoManager/make_video/:id',
        component: () => import('@/views/aivideo/make_video'),
        name: 'Make_video',
        meta: { title: i18n.t('tab.aiDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/editManuscripts/:id',
        component: () => import('@/views/aiCutting/editManuscripts'),
        name: 'EditManuscripts',
        meta: { title: i18n.t('tab.editManuscript'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/programmeDetail/:id',
        component: () => import('@/views/operation/operationStrategyDetail'),
        name: 'OperationStrategyDetail',
        meta: { title: i18n.t('tab.planDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/goodsDetail/:id',
        component: () => import('@/views/mall/goodsDetail'),
        name: 'GoodsDetail',
        meta: { title: i18n.t('tab.productDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/tiktokDetail/:id',
        component: () => import('@/views/mall/tiktokDetail'),
        name: 'TiktokDetail',
        meta: { title: i18n.t('tab.tiktokAccountDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/addGoods/:id',
        component: () => import('@/views/mall/goodsAdd'),
        name: 'AddGoods',
        meta: { title: i18n.t('tab.productDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/materialDetail/:id',
        component: () => import('@/views/aivideo/materialDetail'),
        name: 'MaterialDetail',
        meta: { title: i18n.t('tab.materialDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/worksDetail/:id',
        component: () => import('@/views/aivideo/generatedVideoDetail'),
        name: 'WorksDetail',
        meta: { title: i18n.t('tab.worksDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/addModel',
        component: () => import('@/views/model/modelAdd'),
        name: 'AddModel',
        meta: { title: i18n.t('tab.createModel'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/addModel/:id',
        component: () => import('@/views/model/modelAdd'),
        name: 'AddModel',
        meta: { title: i18n.t('tab.modelDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/addSale',
        component: () => import('@/views/sale/saleAdd'),
        name: 'AddSale',
        meta: { title: i18n.t('tab.createSale'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/addSale/:id',
        component: () => import('@/views/sale/saleAdd'),
        name: 'AddSale',
        meta: { title: i18n.t('tab.saleDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/template/editor/:id',
        component: () => import('@/views/template/templateEditor'),
        name: 'TemplateEditor',
        meta: { title: "编辑模板", keepAlive: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/orderDetail/:id',
        component: () => import('@/views/mall/orderDetail'),
        name: 'OrderDetail',
        meta: { title: i18n.t('tab.orderDetail'), keepAlive: true }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: () => import('@/views/system/user/profile/index'),
        name: 'Profile',
        meta: { title: i18n.t('menus.personalCenter'), icon: 'user' }
      }
    ]
  },
  // {
  //   path: '',
  //   component: Layout,
  //   hidden: false,
  //   redirect: '',
  //   children: [
  //     {
  //       path: 'profile',
  //       component: () => import('@/views/messageCenter/index.vue'),
  //       name: 'Profile',
  //       meta: { title: '消息中心', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/ai',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'soundCloning',  // 声音克隆
  //       component: () => import('@/views/ai/aiSlicing.vue'),
  //       meta: { title: 'AI切片' }
  //     }
  // {
  //   path: 'soundCloning',  // 声音克隆
  //   component: () => import('@/views/ai/soundCloning.vue'),
  //   beforeEnter: (to, from, next) => {
  //     Vue.prototype.$modal.msgWarning('暂未开放，敬请期待');
  //     next(false); // 阻止导航
  //   }
  // },
  // {
  //   path: 'faceSwap',  // AI换脸
  //   component: () => import('@/views/ai/faceSwap.vue'),
  //   beforeEnter: (to, from, next) => {
  //     Vue.prototype.$modal.msgWarning('暂未开放，敬请期待');
  //     next(false); // 阻止导航
  //   }
  // },
  // {
  //   path: 'unsubtitle',  // 去字幕
  //   component: () => import('@/views/ai/unsubtitle.vue'),
  //   beforeEnter: (to, from, next) => {
  //     Vue.prototype.$modal.msgWarning('暂未开放，敬请期待');
  //     next(false); // 阻止导航
  //   }
  // },
  // {
  //   path: 'speechTranslation',  // 语音翻译
  //   component: () => import('@/views/ai/speechTranslation.vue'),
  //   beforeEnter: (to, from, next) => {
  //     Vue.prototype.$modal.msgWarning('暂未开放，敬请期待');
  //     next(false); // 阻止导航
  //   }
  // },
  // {
  //   path: 'digitalHuman',  // 数字人
  //   component: () => import('@/views/ai/digitalHuman.vue'),
  //   beforeEnter: (to, from, next) => {
  //     Vue.prototype.$modal.msgWarning('暂未开放，敬请期待');
  //     next(false); // 阻止导航
  //   }
  // },
  // ]
  // }
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:user:edit'],
    children: [
      {
        path: 'role/:userId(\\d+)',
        component: () => import('@/views/system/user/authRole'),
        name: 'AuthRole',
        meta: { title: i18n.t('menus.assignRoles'), activeMenu: '/system/user' }
      }
    ]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:role:edit'],
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: () => import('@/views/system/role/authUser'),
        name: 'AuthUser',
        meta: { title: i18n.t('menus.assignUser'), activeMenu: '/system/role' }
      }
    ]
  },
  {
    path: '/system/dict-data',
    component: Layout,
    hidden: true,
    permissions: ['system:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/system/dict/data'),
        name: 'Data',
        meta: { title: i18n.t('menus.dictionaryData'), activeMenu: '/system/dict' }
      }
    ]
  },
  {
    path: '/monitor/job-log',
    component: Layout,
    hidden: true,
    permissions: ['monitor:job:list'],
    children: [
      {
        path: 'index/:jobId(\\d+)',
        component: () => import('@/views/monitor/job/log'),
        name: 'JobLog',
        meta: { title: i18n.t('menus.dispatchingLog'), activeMenu: '/monitor/job' }
      }
    ]
  },
  {
    path: '/tool/gen-edit',
    component: Layout,
    hidden: true,
    permissions: ['tool:gen:edit'],
    children: [
      {
        path: 'index/:tableId(\\d+)',
        component: () => import('@/views/tool/gen/editTable'),
        name: 'GenEdit',
        meta: { title: i18n.t('menus.modifyConfig'), activeMenu: '/tool/gen' }
      }
    ]
  }
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
