<template>
  <div class="video-player-message">
    <p>{{ message.text }}</p>
    <video
      :src="message.videoUrl"
      controls
      class="video-player"
    ></video>
    <div class="flex-row-center f-between">
      <el-button class="gradientBtn" type="primary" v-if="!message.completed" @click="$emit('submit')">{{$t('ai.sure')}}</el-button>
      <el-button class="againBtn" v-if="!message.completed" @click="$emit('again')">{{$t('ai.videoAgain')}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.video-player-message {
  padding: 10px;
  background-color: #E7EEF5;
  border-radius: 8px;
  width: 50%;
}
.video-player {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 8px;
  margin-top: 10px;
}

.againBtn {
  width: 80px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCDCDC;
  display: flex;
  justify-content: center;
}
</style>
