var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.setup"))),
      ]),
      _vm.settings.content && _vm.settings.content.length > 0
        ? _c(
            "div",
            _vm._l(_vm.settings.content, function (temp, index) {
              return _c(
                "div",
                { key: index, staticClass: "flex-row-center w-100" },
                [
                  _c("div", { staticClass: "language-tag" }, [
                    _vm._v(_vm._s(_vm.getLanguageName(temp.value))),
                  ]),
                  _c("el-input", {
                    staticClass: "w-100",
                    attrs: { type: "textarea", maxlength: "200" },
                    on: {
                      blur: function ($event) {
                        return _vm.onInputBlur(temp, 1)
                      },
                    },
                    model: {
                      value: temp.text,
                      callback: function ($$v) {
                        _vm.$set(temp, "text", $$v)
                      },
                      expression: "temp.text",
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c("el-input", {
            staticClass: "w-100",
            attrs: { type: "textarea", maxlength: "200" },
            on: {
              blur: function ($event) {
                return _vm.onInputBlur(_vm.tempContent, 2)
              },
            },
            model: {
              value: _vm.tempContent,
              callback: function ($$v) {
                _vm.tempContent = $$v
              },
              expression: "tempContent",
            },
          }),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.font"))),
      ]),
      _c(
        "el-select",
        {
          attrs: { placeholder: _vm.$t("title.tipFont") },
          on: { change: _vm.handleItemUpdated },
          model: {
            value: _vm.settings.font,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "font", $$v)
            },
            expression: "settings.font",
          },
        },
        _vm._l(_vm.dict.type.font_typeface, function (dict) {
          return _c("el-option", {
            key: dict.value,
            attrs: { label: dict.label, value: dict.value },
          })
        }),
        1
      ),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.fontSize"))),
      ]),
      _c(
        "el-radio-group",
        {
          on: { input: _vm.fontSizeChange },
          model: {
            value: _vm.settings.fontSizeType,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "fontSizeType", $$v)
            },
            expression: "settings.fontSizeType",
          },
        },
        [
          _c("el-radio", { attrs: { label: "small" } }, [
            _vm._v(_vm._s(_vm.$t("title.small"))),
          ]),
          _c("el-radio", { attrs: { label: "normal" } }, [
            _vm._v(_vm._s(_vm.$t("title.normal"))),
          ]),
          _c("el-radio", { attrs: { label: "big" } }, [
            _vm._v(_vm._s(_vm.$t("title.big"))),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.letterSpacing"))),
      ]),
      _c("el-input-number", {
        attrs: { min: 0, max: 20 },
        on: { change: _vm.handleItemUpdated },
        model: {
          value: _vm.settings.spacing,
          callback: function ($$v) {
            _vm.$set(_vm.settings, "spacing", $$v)
          },
          expression: "settings.spacing",
        },
      }),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.fontColor"))),
      ]),
      _c("el-color-picker", {
        on: { change: _vm.handleItemUpdated },
        model: {
          value: _vm.settings.fontColor,
          callback: function ($$v) {
            _vm.$set(_vm.settings, "fontColor", $$v)
          },
          expression: "settings.fontColor",
        },
      }),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.strokeWidth"))),
      ]),
      _c("el-input-number", {
        attrs: { min: 0, max: 10 },
        on: { change: _vm.handleItemUpdated },
        model: {
          value: _vm.settings.outline,
          callback: function ($$v) {
            _vm.$set(_vm.settings, "outline", $$v)
          },
          expression: "settings.outline",
        },
      }),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.strokeColor"))),
      ]),
      _c("el-color-picker", {
        on: { change: _vm.handleItemUpdated },
        model: {
          value: _vm.settings.outlineColour,
          callback: function ($$v) {
            _vm.$set(_vm.settings, "outlineColour", $$v)
          },
          expression: "settings.outlineColour",
        },
      }),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.position"))),
      ]),
      _c(
        "el-radio-group",
        {
          on: { input: _vm.alignmentUpdated },
          model: {
            value: _vm.settings.alignment,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "alignment", $$v)
            },
            expression: "settings.alignment",
          },
        },
        [
          _c("el-radio", { attrs: { label: "TopLeft" } }, [
            _vm._v(_vm._s(_vm.$t("title.left"))),
          ]),
          _c("el-radio", { attrs: { label: "TopCenter" } }, [
            _vm._v(_vm._s(_vm.$t("title.center"))),
          ]),
          _c("el-radio", { attrs: { label: "TopRight" } }, [
            _vm._v(_vm._s(_vm.$t("title.right"))),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.opacity"))),
      ]),
      _c("el-slider", {
        attrs: { max: 1, min: 0, step: 0.01, "show-input": "" },
        on: { change: _vm.handleItemUpdated },
        model: {
          value: _vm.settings.fontColorOpacity,
          callback: function ($$v) {
            _vm.$set(_vm.settings, "fontColorOpacity", $$v)
          },
          expression: "settings.fontColorOpacity",
        },
      }),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.style"))),
      ]),
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.changeFontFace },
          model: {
            value: _vm.settings.fontFaces,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "fontFaces", $$v)
            },
            expression: "settings.fontFaces",
          },
        },
        [
          _c("el-checkbox", { attrs: { label: "Italic" } }, [
            _vm._v(_vm._s(_vm.$t("title.italic"))),
          ]),
          _c("el-checkbox", { attrs: { label: "Bold" } }, [
            _vm._v(_vm._s(_vm.$t("title.blod"))),
          ]),
          _c("el-checkbox", { attrs: { label: "Underline" } }, [
            _vm._v(_vm._s(_vm.$t("title.underline"))),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "subtitle-title flex-row-center" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.decorativeText")) + " "),
        _c(
          "div",
          { staticClass: "flex-row-center f-grow f-between" },
          [
            _c("el-switch", {
              staticStyle: { margin: "10px" },
              on: { change: _vm.switchEffectUpdated },
              model: {
                value: _vm.settings.openEffectColorStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "openEffectColorStyle", $$v)
                },
                expression: "settings.openEffectColorStyle",
              },
            }),
            _vm.settings.openEffectColorStyle
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.isExpansion = !_vm.isExpansion
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.isExpansion
                          ? _vm.$t("collapse")
                          : _vm.$t("expansion")
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.settings.openEffectColorStyle
        ? _c(
            "div",
            { staticClass: "image-container" },
            _vm._l(_vm.styleList, function (item, index) {
              return _c("img", {
                key: index,
                class: {
                  selected:
                    _vm.settings.effectColorStyle === item.effectColorStyle,
                },
                attrs: { src: item.imgUrl },
                on: {
                  click: function ($event) {
                    return _vm.selectEffectItem(item)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }