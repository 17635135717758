var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog",
      attrs: {
        width: "50%",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleBeforeClose,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "uploadForm",
          staticClass: "uploadForm",
          style: { maxHeight: _vm.dialogMaxHeight + "px" },
          attrs: {
            model: _vm.form,
            "label-width": "100px",
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _vm.needMaterialTypeId
            ? _c("el-form-item", { attrs: { prop: "materialTypeId" } }, [
                _c(
                  "div",
                  { staticClass: "flex-row-center f-between" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "label",
                        staticStyle: {
                          "font-weight": "bold",
                          color: "#606266",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("material.position")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "el-icon-plus" },
                        on: { click: _vm.openAddFile },
                      },
                      [_vm._v(_vm._s(_vm.$t("file.createFolder")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flexTreeselect" },
                  [
                    _c("treeselect", {
                      attrs: {
                        options: _vm.treeFileList,
                        "show-count": false,
                        normalizer: _vm.normalizer,
                        clearable: false,
                        "load-options": _vm.loadOptions,
                        placeholder: _vm.$t("file.tipSelectFolder"),
                        disabled: _vm.isSystem,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "value-label",
                            fn: function (ref) {
                              var node = ref.node
                              return [_vm._v(" " + _vm._s(node.raw.name) + " ")]
                            },
                          },
                        ],
                        null,
                        false,
                        1894111289
                      ),
                      model: {
                        value: _vm.form.materialTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "materialTypeId", $$v)
                        },
                        expression: "form.materialTypeId",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.title == _vm.$t("material.upload")
            ? _c(
                "el-form-item",
                { attrs: { prop: "fileList" } },
                [
                  _c("div", { staticClass: "flex-row-center" }, [
                    _c("div", { staticClass: "label form-label" }, [
                      _c("span", { staticClass: "required-star" }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("material.materialFile")) + " "
                      ),
                    ]),
                  ]),
                  _c("videoUploadMultifile", {
                    attrs: { limit: 10, fileType: _vm.getFileType() },
                    model: {
                      value: _vm.form.fileList,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fileList", $$v)
                      },
                      expression: "form.fileList",
                    },
                  }),
                  _c("div", { staticClass: "upload-tip flex-row-center" }, [
                    _c("i", {
                      staticClass: "el-icon-warning mr10",
                      staticStyle: { color: "#ED7B2F", "font-size": "18px" },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("material.tipRemoveSubtitle")) + " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.title == _vm.$t("material.edit")
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("file.name"), prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("tip.input"),
                      maxlength: "50",
                      "show-word-limit": "",
                      disabled: _vm.isSystem,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("file.description"), prop: "description" },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("tip.input"),
                  maxlength: "200",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("label.label") } },
            [
              _c(
                "el-select",
                {
                  ref: "searchSelect",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "allow-create": "",
                    filterable: "",
                    multiple: "",
                    "default-first-option": "",
                    placeholder: _vm.$t("label.tipSelect"),
                  },
                  on: { input: _vm.handleInput },
                  model: {
                    value: _vm.form.labels,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "labels", $$v)
                    },
                    expression: "form.labels",
                  },
                },
                _vm._l(_vm.labelList, function (dict) {
                  return _c("el-option", {
                    key: dict.id,
                    attrs: { label: dict.label, value: dict.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.title == _vm.$t("material.upload") &&
          (_vm.containsVideo || _vm.activeTab === "video")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.isStripped"),
                    prop: "isSplitVideoParts",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isSplitVideoParts,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isSplitVideoParts", $$v)
                        },
                        expression: "form.isSplitVideoParts",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(_vm._s(_vm.$t("yes"))),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(_vm._s(_vm.$t("no"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.title == _vm.$t("material.upload") &&
          (_vm.containsVideo || _vm.activeTab === "video")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.removeSubtitle"),
                    prop: "isEraseVideoSubtitles",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.isEraseVideoSubtitles,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isEraseVideoSubtitles", $$v)
                      },
                      expression: "form.isEraseVideoSubtitles",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.title == _vm.$t("material.upload") &&
          (_vm.containsVideo || _vm.activeTab === "video")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.aiFaceSwap"),
                    prop: "isMergeVideoFace",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.isMergeVideoFace,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isMergeVideoFace", $$v)
                      },
                      expression: "form.isMergeVideoFace",
                    },
                  }),
                  _vm.form.isMergeVideoFace == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "mergeVideoFaceImageUrl" } },
                        [
                          !_vm.form.mergeVideoFaceImageUrl
                            ? _c(
                                "div",
                                {
                                  staticClass: "aspect-ratio-box flex-column",
                                  on: { click: _vm.chooseMaterial },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    staticStyle: { "font-size": "26px" },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "16px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("material.addMaterial")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "material-container ml80",
                                  on: {
                                    mouseover: function ($event) {
                                      _vm.form.hover = true
                                    },
                                    mouseleave: function ($event) {
                                      _vm.form.hover = false
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "material-thumbnail",
                                    attrs: {
                                      src: _vm.form.mergeVideoFaceImageUrl,
                                      alt: "material",
                                    },
                                  }),
                                  _vm.form.hover
                                    ? _c("div", { staticClass: "overlay" }, [
                                        _c(
                                          "div",
                                          { on: { click: _vm.removeMaterial } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("basicInfo.remove"))
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.form.isEraseVideoSubtitles == 1 || _vm.form.isMergeVideoFace == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.retainOriginalVideo"),
                    prop: "isReserve",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 0, "inactive-value": 1 },
                    model: {
                      value: _vm.form.isReserve,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isReserve", $$v)
                      },
                      expression: "form.isReserve",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "cancelBtn", on: { click: _vm.handleCancel } },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn",
              attrs: { loading: _vm.buttonLoading },
              on: { click: _vm.submitUpload },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "custom-drawer",
          staticStyle: { "z-index": "9999" },
          attrs: {
            "append-to-body": "",
            size: "65%",
            title: _vm.$t("mixing.materia"),
            visible: _vm.drawer,
            "with-header": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("Material", {
            ref: "materialLibrary",
            attrs: { name: "Material", skipInit: true },
            on: {
              selectionConfirmed: _vm.selectionConfirmed,
              cancel: function ($event) {
                _vm.drawer = false
              },
            },
          }),
        ],
        1
      ),
      _vm.folderDialogVisible
        ? _c("addFolderDialog", {
            attrs: {
              visible: _vm.folderDialogVisible,
              title: _vm.$t("file.createFolder"),
              labelList: _vm.labelList,
              treeFileList: _vm.treeFileList,
              isSystem: _vm.isSystem,
              materialTypeId: _vm.materialTypeId,
            },
            on: {
              "update:visible": function ($event) {
                _vm.folderDialogVisible = $event
              },
              submitAddFile: _vm.submitAddFile,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }